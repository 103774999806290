/*========================================================*/
/*** MINI CART ***/
/*========================================================*/
#cart {
  .minicart-body {
    display: block;
    position: fixed;
    z-index: 999999;
    padding: 30px clamp(10px, 2.083vw, 40px);
    background-color: $white;
    border: none;
    max-width: 470px;
    width: 100%;
    transition: right 0.6s ease;
    right: -100%;
    top: 0;
    bottom: 0;
    height: 100vh;
    border-radius: 0;
    @media (max-width: $tablet-sm) {
      padding: 30px 10px;
    }
    &.open {
      right: 0;
      @media (max-width: $tablet-sm) {
        max-width: 100%;
      }
    }
    .close-mini-cart {
      justify-content: space-between;
      align-items: center;
      padding-bottom: 50px;
      .header-2 {
        margin-bottom: 0;
        color: $primary_color;
        font-size: 6rem;
        letter-spacing: 0.01em;
        @media (max-width: $laptop) {
          font-size: 3.6rem;
        }
      }
      .close-mini-cart-btn {
        .close-btn {
          display: block;
          width: 24px;
          height: 24px;
          svg {
            display: block;
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    .all-products-w-button {
      .scrollable-products {
        overflow-y: auto;
        max-height: calc(var(--screenHeader) - 425px);
        @media (max-width: $tablet-sm) {
          max-height: calc(var(--screenHeader) - 350px);
        }
        li {
          padding: 30px 0;
          border-bottom: 1px solid $primary_color;
          .mini-cart-products {
            justify-content: space-between;
            align-items: center;
            .product-img {
              flex-basis: 35%;
              @media (max-width: $tablet-sm) {
                flex-basis: 25%;
              }
              @media (max-width: $mobile-sm) {
                flex-basis: 35%;
              }
              a {
                display: inline-block;
                .img-thumbnail {
                  border: none;
                  border-radius: 0;
                  padding: 0;
                }
              }
            }
            .prd-item {
              flex-basis: 55%;
              margin-left: 10px;
              font-family: $secondary_font;
              letter-spacing: 0.01em;
              @media (max-width: $tablet-sm) {
                flex-basis: 65%;
              }
              @media (max-width: $mobile-sm) {
                flex-basis: 55%;
              }
              .prd-quantity {
                font-size: 1.6rem;
                margin-bottom: 5px;
              }
              a.prd-name {
                display: block;
                font-size: 2.2rem;
                font-family: $secondary_font;
                text-transform: capitalize;
                letter-spacing: 0.01em;
                @media (max-width: $tablet-sm) {
                  font-size: 1.6rem;
                }
              }
              .option-item {
                display: block;
                font-size: 1.6rem;
                @media (max-width: $tablet-sm) {
                  font-size: 1.2rem;
                }
              }
              .prd-price {
                font-size: 2.2rem;
                margin-bottom: 0;
                @media (max-width: $tablet-sm) {
                  font-size: 1.6rem;
                }
              }
            }
            .product-remove {
              flex-basis: 10%;
              display: flex;
              justify-content:end;
                button {
                  padding: 15px;
                  .delete-icon {
                    display: block;
                    width: 12px;
                    height: 12px;
                    svg {
                      display: block;
                      width: 12px;
                      height: 12px;
                    }
                  }
                }
            }
          }
        }
      }
    }
    .mobile-bottom-cart {
      background-color: $white;
      //@media (max-width: $tablet-sm) {
      //  position: absolute;
      //  width: 100%;
      //  background-color: $white;
      //  padding: 0 10px;
      //  bottom: 80px;
      //  left:0;
      //}
      .mini-cart-total {
        .col-total {
          justify-content: space-between;
          padding: 30px 0;
          border-bottom: 1px solid $primary_color;
          text-transform: uppercase;
          letter-spacing: 0.01em;
          font-size: 2.4rem;
          @media (max-width: $tablet-sm) {
            font-size: 2rem;
          }
          &:nth-child(2), &:nth-child(3) {
            display: none;
          }
        }
      }
      .mini-cart-checkout {
        margin-top: 60px;
        @media (max-width: $tablet-sm) {
          margin-top: 30px;
        }
        a {
          letter-spacing: 0.22em;
          color: var(--white);
          background-color: var(--primary-color);
          border: 1px solid var(--primary-color);
          border-radius: var(--button-border-radius);
          font-family: var(--primary-font), sans-serif;
          font-size: var(--btn-font);
          -webkit-transition: ease 0.3s;
          -moz-transition: ease 0.3s;
          -o-transition: ease 0.3s;
          transition: ease 0.3s;
          @media (max-width: $mobile-sm) {
            padding: 7px 15px;
          }
          &:hover, &:focus, &:active, &:focus-within, &:focus-visible  {
            background-color: var(--secondary-color);
            border: 1px solid var(--secondary-color);
            border-radius: var(--button-border-radius);
            -webkit-transition: ease 0.3s;
            -moz-transition: ease 0.3s;
            -o-transition: ease 0.3s;
            transition: ease 0.3s;
          }
        }
      }
    }
  }
}
.minicart-wrapper {
  height: calc(var(--screenHeader) - 60px);
  overflow-y: auto;
}