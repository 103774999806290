.default-section {
    padding: 50px $side_padding clamp(50px, 6.250vw, 120px);
    &.special-edition {
        background-color: $light_blue;
        @media (min-width: 992px) {
            .products-grid {
                .swiper-wrapper {
                    justify-content: space-between;
                    display: flex;
                    flex-wrap: wrap;
                    .swiper-slide {
                        flex-basis: calc(33.3333% - 2.344vw);
                        margin-bottom: 70px;
                    }
                }
            }
        }
    }
    .header-1 , .header-2 {
        text-align: center;
        color: $primary_color;
    }
    .products-grid {
        padding-top: 30px;
        display: flex;
        justify-content: space-between;
        .swiper-slide {
            @media (max-width: $tablet) {
                margin-bottom: 90px;
            }
            .product-item {
                flex-basis: 33.333%;
                padding-bottom: 3px;
                @media (max-width: $tablet) {
                    flex-basis: 50%;
                }
                @media (max-width: $mobile-sm) {
                    flex-basis: 100%;
                    .product-thumb {
                        .caption {
                            flex-direction: row-reverse;
                            justify-content: space-between;
                            align-items: center;
                        }
                    }

                }
            }
        }

        .swiper-pagination {
            .swiper-pagination-bullet {
                width: 12px;
                height: 12px;
                background-color: transparent;
                box-shadow: none;
                border: 1px solid $secondary-color;
                &.swiper-pagination-bullet-active {
                    background-color: $secondary-color;
                }
            }
        }
    }
}
.default-banner {
    padding: 6.1458333333vw $side_padding;
    @media (max-width: $mobile) {
        padding: 0;
    }
    .default-banner-wrapper {
        @media (max-width: $mobile) {
            padding-top: 45px;
            padding-bottom: 125px;
            .img-hover {
                margin-top: 40px;
                margin-bottom: 40px;
            }
        }
        .left-col {
            flex-basis: 50%;
            @media (max-width: $mobile) {
                .banner-cta {
                    text-align: center;
                }
            }
        }
        .right-col {
            flex-basis: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 0 10px;
            text-align: center;
        }
        @media (max-width: $mobile) {
            flex-direction: column-reverse;
        }
        .img-hover {
            display: inline-block;
            position:relative;
            &::after {
                content: "";
                background-color: transparent;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: background-color 0.3s ease-in-out;
            }
            &:hover {
               &::after {
                   background-color: rgba(34, 34, 34, 0.25);
               }
            }
        }
    }
    &.reversed {
        .default-banner-wrapper {
            flex-direction: row-reverse;
            @media (max-width: $mobile) {
                flex-direction: column-reverse;
            }
        }
    }
}
@media (min-width: 661px) {
    .homeBanners {
        .swiper-wrapper {
            display: flex;
            flex-wrap: wrap;
        }
    }
}

.homepage-about {
    background-color: $light_brown;
    padding: 85px 0 0;
    @media (max-width: $tablet-sm) {
        padding: 40px 0 120px;
    }
    .homepage-about-wrapper {
        .upper {
            justify-content: space-between;
            align-items: end;
            .left {
                max-width: 26.042vw;
                @media (max-width: $tablet-sm) {
                    display: none;
                }
            }
            .right {
                max-width: 39.844vw;
                padding-bottom: 3.417vw;
                @media (max-width: $tablet-sm) {
                    max-width: 100%;
                    padding:0 10px 20px;
                }
            }
        }
        .about-short-description {
            text-align: center;
            width: 100%;
            max-width: 890px;
            padding: 0 10px;
            margin: 0 auto;
            position: relative;
            top: -15.625vw;
            @media (max-width: $tablet-lg) {
                max-width: 700px;
            }
            @media (max-width: $tablet-sm) {
                top: unset;
            }
            img {
                width: 100%;
                max-width: clamp(105px, 10.677vw, 205px);
                margin: 0 auto 75px;
                @media (max-width: $tablet-sm) {
                    margin-bottom: 5px;
                }
                @media (max-width: $mobile-sm) {
                    max-width: 85px;
                }
            }
            .header-1, .header-2 {
                width: 100%;
                max-width: 565px;
                margin-left: auto;
                margin-right: auto;
            }
            .header-2 {
               padding-top: 15px;
            }
            p {
                margin: 30px 0 45px;
            }
        }
    }
}

.customer-reviews {
    position: relative;
    top:  -6.250vw;
    @media (max-width: $mobile) {
        top: -60px;
    }
    .customer-reviews-banner {
        position: relative;
        width: 100%;
        margin: 0 auto;
        padding: 0 30px;
        @media (max-width: $mobile) {
            padding: 0 10px;
        }
        .customer-reviews-text {
            display: block;
            width: 100%;
            max-width: clamp(340px, 55.208vw, 1060px);
            svg {
                display: block;
                width: 100%;
                max-width: clamp(340px, 55.208vw, 1060px);
            }
        }
        img {
            position: relative;
            width: 100%;
            max-width: clamp(255px, 41.458vw, 796px);
            margin: 0 auto 25px;
        }
        .customer-reviews-text {
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            padding: 0 10px;
            svg {
                display: block;
                width: 100%;
            }
        }
    }
    .customer-reviews-content {
        text-align: center;
        width: 100%;
        max-width: 890px;
        padding: 0 10px;
        margin: 0 auto;
        @media (max-width: $tablet-lg) {
            max-width: 700px;
        }
        @media (max-width: $mobile) {
            padding: 0 10px 0;
        }
        .header-1 {
            margin-bottom: 30px;
        }
        .slide-item {
            .header-2 {
                text-transform: capitalize;
                margin-top: 10px;
            }
        }
        .reviews-footer {
            padding: 30px 30px 0;
            justify-content: center;
            .reviews-button {
                display: inline-block;
                width: 77px;
                height: 30.45px;
                svg {
                    display: inline-block;
                    width: 77px;
                    height: 30.45px;
                }
                @media (max-width: $tablet-sm) {
                    width: 40px;
                    height: auto;
                    svg {
                        width: 40px;
                        height: auto;
                    }
                }

                &.swiper-button-disabled {
                    opacity: 0.3;
                }
            }
            .swiper-pagination {
                position: relative;
                bottom: unset;
                width: 100%;
                max-width: 190px;
                @media (max-width: $tablet-sm) {
                    max-width: 100px;
                }
            }
        }
    }
}

body {
    &.lang_el {
        .customer-reviews-text {
            &.greek_icon {
                display: block;
            }
            &.en_icon {
                display: none;
            }
        }
    }
    &.lang_en {
        .customer-reviews-text {
            &.greek_icon {
                display: none;
            }
            &.en_icon {
                display: block;
            }
        }
    }
}
