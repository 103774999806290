.megamenu-style-dev {
  margin-bottom: 0!important;
  .navbar-default {
    background-color: transparent;
    border-color: transparent;
  }
  .megamenu-wrapper {
    background-color: transparent;
    position: initial!important;
    border-radius: 0!important;
    @media (max-width: $laptop-sm) {
      background: $white;
      border: none;
      width: 100%;
      padding: 20px 40px;
      height: 100%;
      max-height: unset;
      @media (max-width: $tablet-sm) {
        padding: 20px 10px;
      }
    }
    ul.megamenu {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      justify-content: center;
      @media (max-width: $laptop-sm) {
        flex-wrap: wrap;
      }
      li {
        @media (max-width: $laptop-sm) {
          flex-basis: 100%;
        }
        &:not(:last-child) {
          margin-right: 20px;
          @media (max-width: $laptop-sm) {
            margin-right: 0;
          }
        }
        a {
          font-family: var(--primary-font), sans-serif;
          font-size: 2.6rem;
          letter-spacing: 0.22em;
          text-transform: uppercase;
          color: var(--secondary-color);
          transition: color 0.3s ease;
          background-color: transparent;
          border: none;
          padding: 0;
          @media (max-width: $laptop-lg) {
            font-size: 2.2rem;
          }
          @media (max-width: $laptop-sm) {
            font-size: 3rem;
            padding: 15px 0;
          }
        }
        .sub-menu {
          @media (min-width: 1281px) {
            visibility: hidden;
            opacity: 0;
            height: -100%;
            -webkit-transition: height 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
            -moz-transition: height 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
            -o-transition: height 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
            transition: height 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
          }
          .content {
            padding: 65px $side_padding;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            @media (max-width: $laptop-sm) {
              padding: 0;
            }
            .categories {
              .menu {
                ul {
                  li {
                    a {
                      display: inline-block;
                      font-family: $secondary_font;
                      font-size: 2.2rem;
                      font-weight: 400;
                      letter-spacing: 0.01em;
                      color: $secondary_color;
                      text-transform: capitalize;
                      padding: 0;
                      border: none;
                      margin-bottom: 40px;
                      position: relative;
                      -webkit-transition: transform .3s ease;
                      -moz-transition: transform .3s ease;
                      -o-transition: transform .3s ease;
                      transition: transform .3s ease;
                      @media (max-width: $laptop-sm) {
                        font-size: 2rem;
                        margin-bottom: 15px;
                      }
                      &::before {
                        content: "";
                        position: absolute;
                        bottom: -1px;
                        left: 0;
                        width: 50%;
                        height: 1px;
                        background-color: #7d6100;
                        transition: transform .6s cubic-bezier(.785,.135,.15,.86);
                        transform: scale(1) translateZ(0);
                        opacity: 0;
                      }
                      &::after {
                        display: inline-block!important;
                        content: "";
                        position: absolute;
                        bottom: -1px;
                        right: 0;
                        width: 50%;
                        height: 1px;
                        background-color: #7d6100;
                        transition: transform .6s cubic-bezier(.785,.135,.15,.86);
                        transform: scale(1) translateZ(0);
                        opacity: 0;
                      }
                      &:hover {
                        &::before {
                          transform: scaleX(0) translateZ(0);
                          transform-origin: left;
                          background-color: #7d6100;
                          transition: transform .6s cubic-bezier(.785,.135,.15,.86),background-color .6s cubic-bezier(.455,.03,.515,.955);
                          will-change: background-color;
                          opacity: 1;
                        }
                        &::after {
                          transform: scaleX(0) translateZ(0);
                          transform-origin: right;
                          background-color: #7d6100;
                          transition: transform .6s cubic-bezier(.785,.135,.15,.86),background-color .6s cubic-bezier(.455,.03,.515,.955);
                          will-change: background-color;
                          opacity: 1;
                        }
                      }
                    }
                  }
                }
              }
            }
            .menu-banner {
              position: relative;
              width: 100%;
              max-width: 400px;
              @media (max-width: $laptop-sm) {
                display: none;
              }
              img {
                position: relative;
              }
              .menu-banner-link {
                position: absolute;
                width: 95%;
                bottom: 10px;
                left: 50%;
                -webkit-transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                transform: translateX(-50%);
                font-family: $secondary_font;
                font-size: 1.6rem;
                font-weight: 400;
                text-align: center;
                letter-spacing: 0.01em;
                padding: 20px;
                text-transform: capitalize;
                background-color: rgba(255, 255, 255, 0.8);
                color: $primary_color;
                transition: color .3s;
                &:hover {
                  transition: color .3s;
                  background-color: rgba(255, 255, 255, 0.9);
                }
              }
            }
          }
        }

        &.with-sub-menu {
          a {
            font-family: $primary_font;
            font-size: 2.6rem;
            letter-spacing: 0.22em;
            text-transform: uppercase;
            color: $secondary_color;
            padding: 0;
            position: relative;
            &::before {
              background: transparent;
            }
            @media (max-width: $laptop-lg) {
              font-size: 2.2rem;
            }
            @media (max-width: $laptop-sm) {
              font-size: 3rem;
              padding: 15px 0;
            }

            &::after {
              display: none;
            }
            .caret {
              display: none;
              @media (max-width: $laptop-sm) {
                display: inline-block;
                content: '' !important;
                width: 16px;
                height: 9px;
                background-image: url('../../../icons/arrow-down.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: 16px 9px;
                border: 0;
                position: absolute;
                top: 50%;
                right: 15px;
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                transform: translateY(-50%);
                -webkit-transition: .3s ease;
                -moz-transition: .3s ease;
                -o-transition: .3s ease;
                transition: .3s ease;
              }
            }
          }
          &.hover {
            .sub-menu {
              left: 0;
              top: calc(var(--headerHeight) - 51px);
              padding-top: 50px;
              @media (min-height: 1281px) {
                height: 100%;
                -webkit-transition: height 0.3s ease, opacity 0.4s ease 0.4s, visibility 0.4s ease 0.4s;
                -moz-transition: height 0.3s ease, opacity 0.4s ease 0.4s, visibility 0.4s ease 0.4s;
                -o-transition: height 0.3s ease, opacity 0.4s ease 0.4s, visibility 0.4s ease 0.4s;
                transition: height 0.3s ease, opacity 0.4s ease 0.4s, visibility 0.4s ease 0.4s;
              }
            }
          }
          &.sub-menu-is-open {
            @media (max-width: $laptop) {
              a {
                .caret {
                  -moz-transform: rotate(-180deg);
                  -o-transform: rotate(-180deg);
                  transform: rotate(-180deg);
                }
              }
            }
            .sub-menu {
              opacity: 1;
              visibility: visible;
              top: 0;
              -webkit-transition: bottom 0.3s ease, opacity 0.4s ease 0.4s, visibility 0.4s ease 0.4s;
              -moz-transition: bottom 0.3s ease, opacity 0.4s ease 0.4s, visibility 0.4s ease 0.4s;
              -o-transition: bottom 0.3s ease, opacity 0.4s ease 0.4s, visibility 0.4s ease 0.4s;
              transition: bottom 0.3s ease, opacity 0.4s ease 0.4s, visibility 0.4s ease 0.4s;
            }
          }
        }
        &:hover {
          background-color: transparent;
          a {
            background-color: transparent;
            color: $primary_color;
          }
        }
      }
    }
  }
}

@media (max-width: $laptop-sm) {
  .megamenu-style-dev {
    position: fixed;
    top: 150px;
    top: var(--headerHeight);
    //z-index: 999;
    z-index: 999999;
    left: -100%;
    width: 40%;
    height: calc(100vh - var(--headerHeight));
    visibility: hidden;
    opacity: 0;
    -webkit-transition: left 0.3s ease, opacity 0.3s ease, visibility  0.3s ease;
    -moz-transition: left 0.3s ease, opacity 0.3s ease, visibility  0.3s ease;
    -o-transition: left 0.3s ease, opacity 0.3s ease, visibility  0.3s ease;
    transition: left 0.3s ease, opacity 0.3s ease, visibility  0.3s ease;
    @media (max-width: $tablet-lg) {
      width: 50%;
    }
    @media (max-width: $tablet-sm) {
      width: 100%;
    }
    &.active {
      left: 0;
      visibility: visible;
      opacity: 1;
      -webkit-transition: left 0.3s ease, opacity 0.3s ease, visibility  0.3s ease;
      -moz-transition: left 0.3s ease, opacity 0.3s ease, visibility  0.3s ease;
      -o-transition: left 0.3s ease, opacity 0.3s ease, visibility  0.3s ease;
      transition: left 0.3s ease, opacity 0.3s ease, visibility  0.3s ease;
    }
    .megamenu-pattern {
      width: 100%;
      height: calc(100vh - var(--headerHeight) - 40px);
      overflow-y: auto!important;
      background-color: $white;
      overflow-x: hidden;
      @media (max-width: $tablet-sm) {
        width: 100%;
      }
    }
    .navbar-default {
      .navbar-toggle {
        display: none;
        background-color: transparent;
        border: none;
        border-radius: 0;
        width: 30px;
        padding: 0;
        height: 34px;
        padding-top: 5px;
        span {
          position: relative;
          display: block;
          width: 100%;
          height: 2px;
          margin-bottom: 5px;
          background-color: $black;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;
        }
        &.active {
          span {
            &:nth-child(2) {
              opacity: 0;
              -webkit-transition: all 0.3s ease;
              -moz-transition: all 0.3s ease;
              -o-transition: all 0.3s ease;
              transition: all 0.3s ease;
            }
            &:nth-child(1) {
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg);
              -webkit-transition: all 0.3s ease;
              -moz-transition: all 0.3s ease;
              -o-transition: all 0.3s ease;
              transition: all 0.3s ease;
              top: 10px;
            }
            &:nth-child(3) {
              -webkit-transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              transform: rotate(-45deg);
              -webkit-transition: all 0.3s ease;
              -moz-transition: all 0.3s ease;
              -o-transition: all 0.3s ease;
              transition: all 0.3s ease;
              bottom: 4px;
            }
          }
        }
      }
    }
  }
  .megamenu-wrapper {
    display: block;
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    height: calc(100vh - var(--headerHeight));
    max-height: unset;
    background-color: #fff;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }

  /*submenu*/
  .megamenu-wrapper ul.megamenu > li > .sub-menu {
    padding-top: 0;
  }
  .megamenu-wrapper.so-megamenu-active ul.megamenu .title-submenu,
  .megamenu-wrapper.so-vertical-active ul.megamenu .title-submenu {
    display: inline-block;
    max-width: unset;
    margin-top: 0;
    margin-bottom: 5px;
  }

  .megamenu-wrapper ul.megamenu .subcategory {
    margin-top: 10px;
  }

  .megamenu-wrapper {
    .sub-menu {
      padding-bottom: 20px;
      .content {
        .static-menu {
          .menu {
            ul {
              li {
                padding-top: 5px;
              }
            }
          }
        }
      }
    }
  }

  .megamenu-wrapper ul.megamenu li .sub-menu .content .static-menu .menu ul {
    margin-top: 0;
  }
  .container-megamenu.horizontal ul.megamenu > li,
  .container-megamenu.vertical ul.megamenu > li {
    border-bottom: 1px solid $secondary_color;
  }
}

.mobile-account-items-wrapper {
  display: none;
  @media (max-width: $laptop-sm) {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--secondary-color);
    .mobile-account-item {
      a {
        font-size: 2.4rem;
      }
    }
    .language-switcher {
      width: 100%;
      .btn-group {
        .dropdown-menu {
          button {
            font-size: 2.4rem;
            padding: 5px 15px;
            background: rgba( 255, 255, 255, 0.25 );
            box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
            backdrop-filter: blur( 12px );
            -webkit-backdrop-filter: blur( 12px );
            border-radius: 10px;
            border: 1px solid rgba( 255, 255, 255, 0.18 );
          }
        }
      }
      button {
        font-size: 2.4rem;
      }
    }
  }
}
.mobile-menu-footer {
  display: none;
  @media (max-width: $laptop-sm) {
    display: flex;
    padding-top: 38px;
    align-items: center;
    .header-2 {
      font-size: 2.4rem;
      color: $secondary_color;
      margin-bottom: 17px;
    }
    .menu-social {
      align-items: center;
      a:first-child {
        margin-right: 20px;
      }
      .social-icon {
        display: inline-block;
        svg {
          display: inline-block;
        }
      }
    }
  }
}

.body-cover, .body-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: $black;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
}

body {
  &.menu-is-open {
    overflow: hidden;
    .body-cover {
      top: var(--headerHeight);
      z-index: 99;
      opacity: 0.7;
      visibility: visible;
      transition: opacity 0.3s ease;
    }
    @media (max-width: $tablet-sm) {
      #product {
        .button-container {
          opacity: 0;
          visibility: hidden;
          -webkit-transition: all .3s ease;
          -moz-transition: all .3s ease;
          -o-transition: all .3s ease;
          transition: all .3s ease;
        }
      }
    }
  }
  &.cart-is-open {
    overflow: hidden;
    .body-cover {
      z-index: 99;
      opacity: 0.7;
      visibility: visible;
      transition: opacity 0.3s ease;
    }
    @media (max-width: $tablet-sm) {
      #product {
        .button-container {
          opacity: 0;
          visibility: hidden;
          -webkit-transition: all .3s ease;
          -moz-transition: all .3s ease;
          -o-transition: all .3s ease;
          transition: all .3s ease;
        }
      }
    }
  }
}

.megamenu-style-dev {
  .megamenu-wrapper {
    ul.megamenu {
      li.with-sub-menu.click.active-show {
        a {
          .caret {
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
            -webkit-transition: .3s ease;
            -moz-transition: .3s ease;
            -o-transition: .3s ease;
            transition: .3s ease;
            width: 16px;
            height: 9px;
          }
        }
      }
    }
  }
}
