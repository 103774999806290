.about-container {
    --side-padding: 120px;
    @media (max-width: $laptop-m) {
        --side-padding: 80px;
    }
    @media (max-width: $laptop-xs) {
        --side-padding: 60px;
    }
    @media (max-width: $tablet-sm) {
        --side-padding: 10px;
    }
    background-color: $light_brown;
    padding-top: 90px;
    @media (max-width: $tablet-sm) {
        padding-top: 40px;
    }
    .title-container {
        margin: 0 auto 60px;
        @media (max-width: $tablet-sm) {
            margin: 0 auto 20px;
        }
        h1,
        h2 {
            margin: 0;
        }
    }
    .about-hero {
        padding: 0 $side_padding;
        margin: 0 auto 65px;
        @media (max-width: $tablet-sm) {
            padding: 0;
            margin: 0 auto 30px;
        }
        img {
            margin: 0 auto;
            @media (max-width: $tablet-sm) {
                width: 100%;
            }
        }
    }
    .secret-recipe {
        align-items: center;
        margin: 0 auto 55px;
        img {
            margin: 0 auto 45px;
            max-width: clamp(400px, 42.1875vw, 810px);
            width: 100%;
        }
        @media (max-width: $laptop-sm) {
            .header-1 {
                font-size: 4rem;
            }
        }
        @media (max-width: $tablet-sm) {
            padding: 0 30px;
            .header-1 {
                font-size: 3.6rem;
            }
        }
    }
    .showcase-container {
        .top-row {
            justify-content: space-between;
            margin: 0 0 15px;
            .left,
            .right {
                max-width: clamp(500px, 34.6354vw, 665px);
                width: 100%;
                flex: 1;
                @media (max-width: 1540px) {
                    max-width: 450px;
                }
                @media (max-width: $laptop-sm) {
                    max-width: 380px;
                }
                @media (max-width: $tablet-lg) {
                    max-width: 330px;
                }
            }
            .left {
                position: relative;
                top: 140px;
                z-index: 1;
            }
            .middle {
                display: flex;
                align-items: center;
                justify-content: center;
                @media (max-width: $laptop-m) {
                    max-width: 220px;
                }
            }
        }
        .bottom-row {
            display: flex;
            align-items: flex-end;
            padding: 40px clamp(180px, 14.3229vw, 275px) 90px clamp(200px, 18.2292vw, 350px);
            width: 100%;
            margin: 0 auto;
            @media (max-width: $tablet-lg) {
                padding: 40px 100px 90px;
            }
            .left {
                margin: 0 150px 0 0;
                padding-bottom: 135px;
                @media (max-width: $laptop-sm) {
                    margin: 0 100px 0 0;
                    padding-bottom: 80px;
                }
            }
        }
        @media (max-width: $tablet) {
            .top-row {
                .middle {
                    padding: 0 15px;
                }
            }
        }
        @media (max-width: $tablet-sm) {
            .bottom-row {
                display: none;
            }
            .top-row {
                margin: 0;
                .left {
                    max-width: 100%;
                    width: 100%;
                    position: relative;
                    top: unset;
                    img {
                        width: 100%;
                    }
                }
                .right,
                .middle {
                    display: none;
                }
            }
        }
    }
    .timeline-container {
        background-color: $light_blue;
        padding: clamp(100px, 13.0208vw, 250px) clamp(100px, 12.5vw, 240px) clamp(100px, 11.1979vw, 215px);
        @media (max-width: 1540px) {
            padding: 150px 120px;
        }
        @media (max-width: $laptop) {
            padding: 120px 80px;
        }
        @media (max-width: $laptop-sm) {
            padding: 80px 80px 120px;
        }
        @media (max-width: $laptop-xs) {
            padding: 80px 40px 120px;
        }
        @media (max-width: $tablet-sm) {
            padding: 30px 10px 100px;
        }
        .timeline-wrapper {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: 0 auto 155px;
            @media (max-width: $laptop-xs) {
                margin: 0 auto 100px;
            }
            @media (max-width: $tablet-lg) {
                margin: 0 auto;
                flex-direction: column;
            }
        }
        .timeline-item {
            flex-basis: 45%;
            margin-bottom: 80px;
            padding-left: 85px;
            padding-top: 45px;
            position: relative;
            @media (max-width: $laptop-xs) {
                flex-basis: unset;
                width: 100%;
                margin-bottom: 35px;
                padding-left: 0;
                padding-top: 0;
            }
            .date {
                position: absolute;
                left: 0;
                top: 0;
                transform: rotate(180deg);
                writing-mode: vertical-lr;
                text-orientation: mixed;
                .header-1 {
                    margin: 0;
                }
                @media (max-width: $laptop-xs) {
                    position: relative;
                    transform: unset;
                    writing-mode: unset;
                    text-orientation: unset;
                    .header-1 {
                        margin: 0 0 10px;
                        text-align: center;
                    }
                }
            }
            .content {
                max-width: 510px;
                width: 100%;
                @media (max-width: $laptop-sm) {
                    max-width: 450px;
                }
                p:last-child {
                    margin-bottom: 0px;
                }
                p:not(:last-child) {
                    margin-bottom: 25px;
                }
                @media (max-width: $laptop-xs) {
                    max-width: unset;
                }
            }
            &.year-1983 {
                margin-top: 90px;
                @media (max-width: $laptop-xs) {
                    margin-top: 0;
                }
            }
            &.decor {
                padding-top: 0;
                padding-left: 0;
                margin-bottom: 0;
                position: relative;
                left: -100px;
                @media (max-width: $laptop-sm) {
                    left: 0;
                }
                @media (max-width: $laptop-xs) {
                    margin-bottom: 35px;
                }
                .chef-container {
                    width: 100%;
                    @media (max-width: $laptop-xs) {
                        max-width: 600px;
                        margin: 0 auto;
                    }
                    @media (max-width: $tablet-lg) {
                        max-width: 400px;
                    }
                    svg {
                        overflow: visible;
                    }
                    #tv,
                    #cake1,
                    #cake2 {
                        transform-origin: center;
                    }
                    #tv {
                        animation: float-up 6s ease-in-out infinite;
                    }
                    #cake1 {
                        animation: float-right 4.5s ease-in-out infinite;
                    }
                    #cake2 {
                        animation: float-left 3s ease-in-out infinite;
                    }
                }
            }
            &.year-2016 {
                flex-basis: 100%;
            }
            &.year-2022 {
                margin-top: -180px;
                @media (max-width: $laptop-sm) {
                    margin-top: -240px;
                }
                @media (max-width: $laptop-xs) {
                    margin-top: 0;
                }
                @media (max-width: $tablet-sm) {
                    margin-bottom: 0;
                }
            }
        }
        .crete-logo {
            position: relative;
            display: flex;
            justify-content: center;
            .spinning-text {
                max-width: 264px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            @media (max-width: $tablet-lg) {
                display: none;
            }
        }
    }

    .footer-banner {
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 175px $side_padding 135px;
        @media (max-width: 1540px) {
            padding: clamp(100px, 9.74vw, 150px) clamp(130px, 12.987vw, 200px);
        }
        @media (max-width: $mobile) {
            padding: 60px $side_padding;
        }
        .banner {
            position: relative;
        }
        .banner-moto {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
        }
    }
}
@keyframes float-up {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes float-right {
    0% {
        transform: translateX(0px) rotate(0deg);
    }
    50% {
        transform: translateX(3px) rotate(5deg);
    }
    100% {
        transform: translateX(0px) rotate(0deg);
    }
}
@keyframes float-left {
    0% {
        transform: translateX(0px) rotate(0deg);
    }
    50% {
        transform: translateX(-5px) rotate(-3deg);
    }
    100% {
        transform: translateX(0px) rotate(0deg);
    }
}
