:root {
    // palette
    --black: #1b1b1b;
    --white: #ffffff;
    --gray: #747474;
    --light-gray: #eaeaea;
    --dark-blue: #365563;
    --light-blue: #e2e7e9;
    --gold: #7d6100;
    --light-brown: #f1ece8;

    // colors
    --primary-color: var(--gold);
    --secondary-color: var(--dark-blue);
    --input-border-color: var(--dark-blue);
    --input-border-color-focus: var(--dark-blue);
    --input-placeholder-color: var(--dark-blue);
    --footer-background-color: var(--gold);
    --promo-bar-background-color: var(--gold);

    // fonts
    --primary-font: 'AkzidenzGroteskPro';
    --secondary-font: 'ApercuPro';

    --header-1: 6rem;
    --header-2: 3rem;
    --text: 2.4rem;
    --btn-font: 3.0rem;
    --input-font: 1.6rem;
    --modal-title: clamp(2.4rem, 1.8229vw, 3.5rem);

    // layout
    --input-border-radius: 40px;
    --button-border-radius: 40px;
    --side-padding: 6.250vw;
    --side-padding-header: 30px;
    --side-padding-footer: 30px;
    --side-padding-checkout: 20px;
}

/*========================================================*/
/* Colors */
/*========================================================*/

$primary_color: var(--primary-color);
$secondary_color: var(--secondary-color);

$placeholder_color: var(--input-placeholder-color);
$border_color: var(--input-border-color);
$border_color_focus: var(--input-border-color-focus);
$btn_font_color: var(--white);
$btn_background_color: var(--primary-color);
$footer_background_color: var(--footer-background-color);
$promo_bar_background_color: var(--promo-bar-background-color);

$black: var(--black);
$white: var(--white);
$gray: var(--gray);
$light_gray: var(--light-gray);
$dark_blue: var(--dark-blue);
$light_blue: var(--light-blue);
$gold: var(--gold);
$light_brown: var(--light-brown);

/*========================================================*/
/* Fonts */
/*========================================================*/

$primary_font: var(--primary-font)+', sans-serif';
$secondary_font: var(--secondary-font)+', sans-serif';

$header_1_fs: var(--header-1);
$header_2_fs: var(--header-2);
$text_fs: var(--text);
$btn_fs: var(--btn-font);
$input_fs: var(--input-font);
$modal_title: var(--modal-title);

/*========================================================*/
/* Layout */
/*========================================================*/

$border_radius_input: var(--input-border-radius);
$border_radius_button: var(--button-border-radius);
$side_padding: var(--side-padding);
$side_padding_header: var(--side-padding-header);
$side_padding_footer: var(--side-padding-footer);
$side_padding_checkout: var(--side-padding-checkout);

/*========================================================*/
/* Breakpoints */
/*========================================================*/

$laptop-lg: 1600px;
$laptop: 1440px;
$laptop-m: 1366px;
$laptop-sm: 1280px;
$laptop-xs: 1140px;
$tablet-lg: 1024px;
$tablet: 991px;
$tablet-sm: 767px;
$mobile: 660px;
$mobile-m: 580px;
$mobile-sm: 440px;
$mobile-xs: 375px;

/*========================================================*/
/* Responsive Variables */
/*========================================================*/
:root {
    @media (max-width: $laptop) {
        --side-padding: 50px;
    }
    @media (max-width: $laptop-sm) {
        --side-padding: 40px;
    }
    @media (max-width: $laptop-xs) {
        --side-padding: 40px;
        --header-1: 3.8rem;
        --header-2: 2.6rem;
        --text: 2rem;
    }
    @media (max-width: $tablet-lg) {
        --side-padding-footer: 15px;
    }
    @media (max-width: $tablet-sm) {
        --side-padding: 10px;
        --text: 1.8rem;
    }
    @media (max-width: $mobile) {
        --header-1: 3.6rem;
        --header-2: 2.4rem;
        --text: 1.6rem;
        --btn-font: 2.4rem;
        --input-font: 1.6rem;
    }
    @media (max-width: $mobile-xs) {
        --header-1: 3.4rem;
        --header-2: 2.2rem;
    }
    @media (max-width: 350px) {
        --header-1: 3rem;
        --header-2: 2rem;
    }
}
