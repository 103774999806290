.stores-container {
    --side-padding: 120px;
    @media (max-width: $laptop-m) {
        --side-padding: 80px;
    }
    @media (max-width: $laptop-xs) {
        --side-padding: 60px;
    }
    @media (max-width: $tablet-sm) {
        --side-padding: 10px;
    }
    background-color: $light_brown;
    padding-top: 90px;
    @media (max-width: $tablet-sm) {
        padding-top: 40px;
    }
    .title-container {
        margin: 0 auto 60px;
        @media (max-width: $tablet-sm) {
            margin: 0 auto 20px;
        }
        h2 {
            margin: 0;
        }
    }
    .stores-hero {
        padding: 0 $side_padding;
        margin: 0 auto clamp(80px, 8.0729vw, 155px);
        @media (max-width: $laptop) {
            margin: 0 auto 80px;
        }
        @media (max-width: $tablet-sm) {
            padding: 0;
            margin: 0 auto 30px;
        }
        img {
            margin: 0 auto;
            @media (max-width: $tablet-sm) {
                width: 100%;
            }
        }
    }
    .our-goals {
        padding: 0 clamp(50px, 10.4167vw, 200px) clamp(40px, 8.3333vw, 160px);
        @media (max-width: 1600px) {
            padding: 0 100px clamp(40px, 8.3333vw, 160px);
        }
        @media (max-width: $tablet) {
            padding: 0 $side_padding 40px;
        }
        .moto-sup-title {
            max-width: 137px;
            width: 100%;
            margin: 0 auto 30px;
            @media (max-width: $mobile) {
                margin: 0 auto 15px;
            }
        }
        .moto {
            max-width: 1350px;
            width: 100%;
            margin: 0 auto 45px;
            @media (max-width: $laptop) {
                max-width: 1000px;
            }
            @media (max-width: $tablet-sm) {
                margin: 0 auto 15px;
            }
            @media (max-width: $mobile-sm) {
                .header-1 {
                    padding: 0 20px;
                    br {
                        display: none;
                    }
                }
            }
        }
        .description {
            max-width: 910px;
            width: 100%;
            margin: 0 auto;
        }
        .header-1 {
            margin: 0 0 25px;
            @media (max-width: $tablet-sm) {
                margin: 0 auto 15px;
            }
        }
    }
    .store-items-container {
        background-color: $light_blue;
        padding: clamp(120px, 15.1042vw, 290px) $side_padding clamp(120px, 12.2396vw, 235px);
        @media (max-width: $tablet) {
            padding: 100px $side_padding 90px;
        }
        .store-items-wrapper {
            justify-content: space-between;
            @media (max-width: $tablet) {
                flex-direction: column;
            }
        }
        .store-item-wrapper {
            &:nth-child(even) {
                padding-top: clamp(120px, 14.0625vw, 270px);
                @media (max-width: $tablet) {
                    padding-top: unset;
                    justify-content: flex-end;
                }
            }
            &:first-child {
                margin-right: 30px;
                @media (max-width: $tablet) {
                    margin-right: unset;
                    margin: 0 0 65px;
                    justify-content: flex-start;
                }
            }
            @media (max-width: $tablet) {
                display: flex;
            }
            @media (max-width: $tablet-sm) {
                display: block;
            }
        }
        .store-item {
            max-width: clamp(460px, 37.2396vw, 715px);
            width: 100%;
            position: relative;
            @media (max-width: $tablet) {
                max-width: 550px;
            }
            @media (max-width: $tablet-sm) {
                margin: 0 auto;
            }
            .title {
                max-width: clamp(400px, 30.5208vw, 586px);
                width: 100%;
                position: absolute;
                top: -50px;
                left: 50%;
                transform: translateX(-50%);
                @media (max-width: $tablet) {
                    top: -30px;
                    max-width: 80%;
                }
            }
            .store-item-title {
                color: $primary_color;
                letter-spacing: 0.9em;
            }
            .description {
                margin: 0 0 40px;
                @media (max-width: 1540px) {
                    margin: 0 0 30px;
                }
                @media (max-width: $mobile-sm) {
                    margin: 0 0 20px;
                }
            }
            .address {
                margin: 0 0 50px;
                @media (max-width: 1540px) {
                    margin: 0 0 35px;
                }
                @media (max-width: $mobile-sm) {
                    margin: 0 0 15px;
                }
                p {
                    margin: 0;
                }
            }
            .bottom-section {
                background-color: $dark_blue;
                color: $light_blue;
                padding: 80px 80px 65px;
                text-align: center;
                @media (max-width: 1540px) {
                    padding: 60px 40px 45px;
                }
                @media (max-width: $tablet) {
                    height: var(--calc-height);
                    padding: 20px 40px 45px;
                }
            }
            .view-on-map {
                display: flex;
                flex-direction: column;
                align-items: center;
                max-width: 165px;
                width: 100%;
                margin: 0 auto;
                .header-2 {
                    margin: 0;
                }
                @media (max-width: $laptop-m) {
                    flex-direction: row;
                    .header-2 {
                        font-size: 2.4rem;
                    }
                }
                @media (max-width: $mobile-sm) {
                    max-width: 120px;
                    flex-direction: row;
                    br {
                        display: none;
                    }
                    .header-2 {
                        font-size: 1.6rem;
                        margin: 0;
                    }
                }
                .map-icon {
                    margin: 0 auto 17px;
                    @media (max-width: $laptop-m) {
                        margin: 0 10px;
                    }
                    @media (max-width: $mobile-sm) {
                        width: 12px;
                        margin: 0 5px;
                    }
                }
            }
        }
    }
    .stores-footer {
        picture,
        img {
            width: 100%;
        }
    }
}

.bordered {
    .top-section,
    .bottom-section {
        border-radius: 400px;
        overflow: hidden;
    }
    .top-section {
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;
    }
    .bottom-section {
        border-top-left-radius: unset;
        border-top-right-radius: unset;
        position: relative;
        &::after {
            border-radius: 400px;
            border-top-left-radius: unset;
            border-top-right-radius: unset;
            content: '';
            width: calc(100% - 46px);
            height: calc(100% - 46px);
            border: 2px solid $primary_color;
            position: absolute;
            top: 26px;
            left: 23px;
            background-color: transparent;
            pointer-events: none;
            border-top: unset;
            margin: 0 auto;
        }
    }
}
