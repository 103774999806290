@charset "UTF-8";
/* 
* One Line font set
* $font-family, $font-size, $font-weight, $font-style, $line-height, $letter-spacing
* usage:
* @include font($primary_font, 24, 500, normal, 30, 2.56); px-based($font-size, $line-height, $letter-spacing)
* To ignore order use declaration of any attribute
* @include font($font-family:val, $font-size:val, $font-weight:val, $font-style:val, $line-height:val, $letter-spacing:val);
*/
:root {
  --black: #1b1b1b;
  --white: #ffffff;
  --gray: #747474;
  --light-gray: #eaeaea;
  --dark-blue: #365563;
  --light-blue: #e2e7e9;
  --gold: #7d6100;
  --light-brown: #f1ece8;
  --primary-color: var(--gold);
  --secondary-color: var(--dark-blue);
  --input-border-color: var(--dark-blue);
  --input-border-color-focus: var(--dark-blue);
  --input-placeholder-color: var(--dark-blue);
  --footer-background-color: var(--gold);
  --promo-bar-background-color: var(--gold);
  --primary-font: "AkzidenzGroteskPro";
  --secondary-font: "ApercuPro";
  --header-1: 6rem;
  --header-2: 3rem;
  --text: 2.4rem;
  --btn-font: 3.0rem;
  --input-font: 1.6rem;
  --modal-title: clamp(2.4rem, 1.8229vw, 3.5rem);
  --input-border-radius: 40px;
  --button-border-radius: 40px;
  --side-padding: 6.250vw;
  --side-padding-header: 30px;
  --side-padding-footer: 30px;
  --side-padding-checkout: 20px;
}

/*========================================================*/
/* Colors */
/*========================================================*/
/*========================================================*/
/* Fonts */
/*========================================================*/
/*========================================================*/
/* Layout */
/*========================================================*/
/*========================================================*/
/* Breakpoints */
/*========================================================*/
/*========================================================*/
/* Responsive Variables */
/*========================================================*/
@media (max-width: 1440px) {
  :root {
    --side-padding: 50px;
  }
}
@media (max-width: 1280px) {
  :root {
    --side-padding: 40px;
  }
}
@media (max-width: 1140px) {
  :root {
    --side-padding: 40px;
    --header-1: 3.8rem;
    --header-2: 2.6rem;
    --text: 2rem;
  }
}
@media (max-width: 1024px) {
  :root {
    --side-padding-footer: 15px;
  }
}
@media (max-width: 767px) {
  :root {
    --side-padding: 10px;
    --text: 1.8rem;
  }
}
@media (max-width: 660px) {
  :root {
    --header-1: 3.6rem;
    --header-2: 2.4rem;
    --text: 1.6rem;
    --btn-font: 2.4rem;
    --input-font: 1.6rem;
  }
}
@media (max-width: 375px) {
  :root {
    --header-1: 3.4rem;
    --header-2: 2.2rem;
  }
}
@media (max-width: 350px) {
  :root {
    --header-1: 3rem;
    --header-2: 2rem;
  }
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.light {
  font-weight: 300;
}

.medium {
  font-weight: 500;
}

.bold {
  font-weight: 600;
}

.extra-bold {
  font-weight: 900;
}

.primary {
  color: var(--primary-color);
}

.black {
  color: var(--black);
}

.white {
  color: var(--white);
}

.gray {
  color: var(--gray);
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.header-1 {
  font-family: var(--primary-font), sans-serif;
  font-size: var(--header-1);
  font-weight: 500;
  letter-spacing: 0.22em;
  line-height: 1;
  margin: 0 0 15px;
  color: inherit;
  text-transform: uppercase;
}

.header-2 {
  font-family: var(--primary-font), sans-serif;
  font-size: var(--header-2);
  font-weight: 500;
  letter-spacing: 0.22em;
  line-height: 1;
  margin: 0 0 5px;
  color: inherit;
  text-transform: uppercase;
}

p,
.text {
  font-family: var(--secondary-font), sans-serif;
  font-size: var(--text);
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.01em;
  margin: 0 0 10px;
}

input[type=text],
input[type=password],
input[type=tel],
input[type=email],
textarea,
select,
label.control-label,
input[type=text].form-control,
input[type=password].form-control,
input[type=tel].form-control,
input[type=email].form-control,
textarea.form-control,
select.form-control,
.radio label,
.checkbox label,
label.control-label {
  font-size: var(--input-font);
  font-weight: 400;
  color: var(--primary-color);
}

input[type=text],
input[type=password],
input[type=tel],
input[type=email],
textarea,
input[type=text].form-control,
input[type=password].form-control,
input[type=tel].form-control,
input[type=email].form-control,
textarea.form-control,
select.form-control {
  border: 1px solid var(--input-border-color);
  padding: 17px 22px;
  height: unset;
  line-height: 1.5;
  background-color: transparent;
  box-shadow: none;
  border-radius: var(--input-border-radius);
}
input[type=text]::placeholder,
input[type=password]::placeholder,
input[type=tel]::placeholder,
input[type=email]::placeholder,
textarea::placeholder,
input[type=text].form-control::placeholder,
input[type=password].form-control::placeholder,
input[type=tel].form-control::placeholder,
input[type=email].form-control::placeholder,
textarea.form-control::placeholder,
select.form-control::placeholder {
  color: var(--input-placeholder-color);
  font-weight: 300;
}
input[type=text]:focus,
input[type=password]:focus,
input[type=tel]:focus,
input[type=email]:focus,
textarea:focus,
input[type=text].form-control:focus,
input[type=password].form-control:focus,
input[type=tel].form-control:focus,
input[type=email].form-control:focus,
textarea.form-control:focus,
select.form-control:focus {
  border: 1px solid var(--input-border-color-focus);
  -webkit-box-shadow: none;
  box-shadow: none;
}
input[type=text]:-webkit-autofill, input[type=text]:-webkit-autofill:hover, input[type=text]:-webkit-autofill:focus,
input[type=password]:-webkit-autofill,
input[type=password]:-webkit-autofill:hover,
input[type=password]:-webkit-autofill:focus,
input[type=tel]:-webkit-autofill,
input[type=tel]:-webkit-autofill:hover,
input[type=tel]:-webkit-autofill:focus,
input[type=email]:-webkit-autofill,
input[type=email]:-webkit-autofill:hover,
input[type=email]:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
input[type=text].form-control:-webkit-autofill,
input[type=text].form-control:-webkit-autofill:hover,
input[type=text].form-control:-webkit-autofill:focus,
input[type=password].form-control:-webkit-autofill,
input[type=password].form-control:-webkit-autofill:hover,
input[type=password].form-control:-webkit-autofill:focus,
input[type=tel].form-control:-webkit-autofill,
input[type=tel].form-control:-webkit-autofill:hover,
input[type=tel].form-control:-webkit-autofill:focus,
input[type=email].form-control:-webkit-autofill,
input[type=email].form-control:-webkit-autofill:hover,
input[type=email].form-control:-webkit-autofill:focus,
textarea.form-control:-webkit-autofill,
textarea.form-control:-webkit-autofill:hover,
textarea.form-control:-webkit-autofill:focus,
select.form-control:-webkit-autofill,
select.form-control:-webkit-autofill:hover,
select.form-control:-webkit-autofill:focus {
  border: 1px solid var(--primary-color);
  -webkit-text-fill-color: var(--primary-color);
}

select.form-control {
  background-color: transparent;
  color: var(--secondary-color);
  -webkit-appearance: none;
  cursor: pointer;
  background-image: url("../../../icons/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: 95% 50%;
  background-color: transparent;
  padding: 17px 40px 17px 22px;
}

.form-group.required .control-label:after {
  content: " *";
  color: red;
}

/* CHECKBOX STRACTURE */
/* CHECKBOX */
input[type=checkbox] {
  display: inline-grid !important;
  place-content: center;
  cursor: pointer;
  width: 18px;
  height: 18px;
  border: 1px solid var(--gold);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  margin: 0;
  margin-right: 9px;
  border-radius: 2px;
  top: -1px;
  position: relative;
}
input[type=checkbox]:disabled {
  cursor: not-allowed;
}
input[type=checkbox]:before {
  content: "";
  width: 8px;
  height: 8px;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--gold);
}
input[type=checkbox]:checked:before {
  transform: scale(1);
}

/* RADIO STRACTURE */
/* RADIO */
input[type=radio]:not(#guest):not(#register) {
  position: relative !important;
  display: inline-grid !important;
  place-content: center;
  cursor: pointer;
  width: 18px;
  height: 18px;
  border: 1px solid var(--gold);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  margin: 0;
  margin-right: 9px;
  border-radius: 50%;
}
input[type=radio]:not(#guest):not(#register):before {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--dark-blue);
}
input[type=radio]:not(#guest):not(#register):checked:before {
  transform: scale(1);
}

.radio-input {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.radio-input label {
  margin-bottom: 0;
}

input:focus::-webkit-input-placeholder {
  color: transparent !important;
}

input:focus:-moz-placeholder {
  color: transparent !important;
}

input:focus::-moz-placeholder {
  color: transparent !important;
}

input:focus:-ms-input-placeholder {
  color: transparent !important;
}

.modal-open {
  overflow: hidden;
}

.modal-title {
  font-family: var(--primary-font), sans-serif;
  font-size: var(--modal-title);
  font-weight: 600;
}

.modal-body {
  padding: clamp(15px, 2.0833vw, 40px);
}
.modal-body h2,
.modal-body h2,
.modal-body h3,
.modal-body h4,
.modal-body h5,
.modal-body h6 {
  font-family: var(--primary-font), sans-serif;
  font-size: var(--header-1);
  font-weight: 500;
}

.modal-header {
  position: relative;
}
.modal-header .close {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: 0;
  transform: translateY(-50%);
}

.modal-content {
  border-radius: 0;
  border: none;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
}

/*========================================================*/
/* Reset */
/*========================================================*/
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none !important;
}

::selection {
  background: var(--primary-color);
  color: var(--light-blue);
}

html {
  font-size: 62.5%;
  line-height: 1.5;
  scroll-behavior: smooth;
}

body {
  font-family: var(--secondary-font), sans-serif;
  font-size: var(--text);
  color: var(--dark-blue);
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}
@media (max-width: 991px) {
  body.cart-is-open {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: unset;
  color: var(--black);
}

a,
a:hover,
a:focus {
  text-decoration: none;
  color: inherit;
}

b,
strong {
  font-weight: 600;
}

ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style-type: none;
}

img,
picture {
  display: block;
  max-width: 100%;
  border: none;
}

svg {
  display: inline-block;
}

.desktop-view {
  display: block;
}
@media (max-width: 660px) {
  .desktop-view {
    display: none;
  }
}

.mobile-view {
  display: none;
}
@media (max-width: 660px) {
  .mobile-view {
    display: block;
  }
}

/*========================================================*/
/* Layout*/
/*========================================================*/
.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.overflow-hidden {
  overflow: hidden;
}

.float-right {
  float: right;
}

.side-padding {
  padding-right: var(--side-padding);
  padding-left: var(--side-padding);
}

.m0 {
  margin: 0;
}

.mt0 {
  margin-top: 0;
}

.mb0 {
  margin-bottom: 0;
}

.p0 {
  padding: 0;
}

.mobile-account-items {
  display: none;
}

.fade-in {
  opacity: 0;
}

.fade-in-headers {
  opacity: 0;
  position: relative;
  top: 20px;
}

.fade-children > * {
  opacity: 0;
}

.typing-text {
  opacity: 0;
  animation: fadeIn 0.01s 0.3s forwards;
}
.typing-text .letter {
  opacity: 0;
}

/*========================================================*/
/*** Default Elements ***/
/*========================================================*/
.btn-default {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 10px 15px;
  font-size: var(--btn-font);
  font-weight: 500;
  letter-spacing: 0.05em;
  color: var(--white);
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: var(--button-border-radius);
  background-image: none;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1.8;
  transition: ease 0.3s;
  min-height: unset;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: var(--primary-font), sans-serif;
}
.btn-default:hover {
  color: var(--white);
  background-color: var(--secondary-color);
  border-color: var(--primary-color);
}
.btn-default.reverse {
  color: var(--white);
  background-color: var(--primary-color);
}
.btn-default.reverse:hover {
  background-color: transparent;
  color: var(--primary-color);
}
.btn-default.active {
  color: var(--white);
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}
.btn-default.active:hover {
  background-color: transparent;
  color: var(--primary-color);
}
.btn-default.medium-button {
  max-width: 250px;
}

.alert {
  margin: 15px;
  font-size: 1.6rem;
}

.alert-success {
  color: var(--white);
  background-color: var(--black);
  border-color: var(--black);
  font-size: var(--text);
  border-radius: 0;
  text-align: center;
}

.alert {
  margin: 15px;
}

.btn-primary,
.btn-info {
  color: var(--white);
  background-color: var(--black);
  border-color: var(--black);
}
.btn-primary:hover,
.btn-info:hover {
  color: var(--white);
  background-color: var(--black);
  border-color: var(--black);
}

.default-cta {
  display: inline-block;
  color: var(--secondary-color);
  position: relative;
  font-weight: 400;
  font-size: var(--text);
  text-align: center;
  letter-spacing: 0.01em;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  line-height: 30px;
}
@media (max-width: 767px) {
  .default-cta {
    font-size: 2rem;
  }
  .default-cta.small-btn {
    font-size: 1.6rem;
  }
}
.default-cta:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: var(--secondary-color);
}
.default-cta:hover {
  color: var(--primary-color);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.default-cta:hover:after {
  background-color: var(--primary-color);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.modal-open {
  max-width: 100%;
  overflow-y: hidden !important;
  overflow-x: auto !important;
  padding: 0 !important;
}

.modal {
  background-color: rgba(54, 85, 99, 0.75) !important;
}
.modal .modal-title {
  color: var(--dark-blue);
}
.modal .modal-content {
  background-color: var(--light-brown);
}

._underline {
  position: relative;
}
._underline:after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--dark-blue);
}

body {
  padding-top: var(--headerHeight);
}

#main-header {
  background-color: var(--white);
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
#main-header .header-wrapper {
  justify-content: space-between;
  align-items: center;
  padding: clamp(30px, 37px, 1.927vw) var(--side-padding);
}
@media (max-width: 1600px) {
  #main-header .header-wrapper {
    padding: clamp(30px, 37px, 1.927vw) 15px;
  }
}
#main-header.hidden-top {
  top: calc(-1 * (var(--headerHeight)));
  -webkit-transition: top 0.7s ease;
  -moz-transition: top 0.7s ease;
  -o-transition: top 0.7s ease;
  transition: top 0.7s ease;
}
#main-header.sticky-menu {
  top: 0;
  position: fixed;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  width: 100%;
  left: 0;
  border-bottom: 1px solid var(--primary-color);
}
#main-header .header-col {
  flex-basis: 20%;
  align-items: center;
}
#main-header .header-col:first-child, #main-header .header-col:last-child {
  flex-basis: 40%;
}
@media (max-width: 991px) {
  #main-header .header-col:first-child, #main-header .header-col:last-child {
    flex-basis: 25%;
  }
}
@media (max-width: 991px) {
  #main-header .header-col {
    flex-basis: 50%;
  }
}
#main-header .header-col.logo {
  justify-content: center;
}
#main-header .header-col.right-col {
  display: flex;
  justify-content: flex-end;
}

.menu-is-open .hidden-top {
  top: 0 !important;
}
@media (max-width: 1280px) {
  .menu-is-open .header-item.laptop-visible {
    z-index: 99;
  }
}

.header-item:not(:last-child) {
  margin-right: 27px;
}
@media (max-width: 1366px) {
  .header-item:not(:last-child) {
    margin-right: 20px;
  }
}
.header-item.laptop-visible {
  display: none;
}
@media (max-width: 1280px) {
  .header-item.header-cart {
    margin-right: 0;
  }
  .header-item.laptop-hidden {
    display: none;
  }
  .header-item.laptop-visible {
    display: block;
    position: relative;
    z-index: 9999;
  }
  .header-item.laptop-visible .burger-menu {
    display: flex;
    width: 43px;
    flex-direction: column;
  }
  .header-item.laptop-visible .burger-menu .icon-bar {
    display: block;
    content: "";
    height: 2px;
    width: 100%;
    background-color: var(--secondary-color);
    -webkit-transition: transform 0.3s ease;
    -moz-transition: transform 0.3s ease;
    -o-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
  }
  .header-item.laptop-visible .burger-menu .icon-bar:not(:last-child) {
    margin-bottom: 10px;
  }
  .header-item.laptop-visible .burger-menu .icon-bar.top, .header-item.laptop-visible .burger-menu .icon-bar.bottom {
    max-width: 26px;
    margin-left: auto;
    margin-right: auto;
  }
  .header-item.laptop-visible .burger-menu.active .icon-bar.top {
    max-width: 100%;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    margin: 0;
    position: relative;
    top: 5px;
  }
  .header-item.laptop-visible .burger-menu.active .icon-bar.middle {
    opacity: 0;
  }
  .header-item.laptop-visible .burger-menu.active .icon-bar.bottom {
    max-width: 100%;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    margin: 0;
    position: relative;
    top: -9px;
  }
}
.header-item a, .header-item button {
  font-family: var(--primary-font), sans-serif;
  font-size: 2.6rem;
  letter-spacing: 0.22em;
  text-transform: uppercase;
  color: var(--secondary-color);
  transition: color 0.3s ease;
  background-color: transparent;
  border: none;
}
@media (max-width: 1600px) {
  .header-item a, .header-item button {
    font-size: 2.2rem;
  }
}
@media (max-width: 1024px) {
  .header-item a, .header-item button {
    font-size: 2rem;
  }
}
@media (max-width: 440px) {
  .header-item a, .header-item button {
    font-size: 1.6rem;
  }
}
.header-item a:hover, .header-item button:hover {
  color: var(--primary-color);
  transition: color 0.3s ease;
}

.logo-icon {
  display: block;
  width: 100%;
  max-width: 206px;
}
.logo-icon svg {
  display: block;
  width: 100%;
  max-width: 206px;
}
@media (max-width: 1600px) {
  .logo-icon {
    max-width: 180px;
  }
  .logo-icon svg {
    max-width: 180px;
  }
}
@media (max-width: 1024px) {
  .logo-icon {
    max-width: 160px;
  }
  .logo-icon svg {
    max-width: 160px;
  }
}
@media (max-width: 767px) {
  .logo-icon {
    max-width: 140px;
  }
  .logo-icon svg {
    max-width: 140px;
  }
}
@media (max-width: 350px) {
  .logo-icon {
    max-width: 120px;
  }
  .logo-icon svg {
    max-width: 120px;
  }
}

.language-switcher .btn-group {
  position: relative;
}
.language-switcher .btn-group button {
  background-color: transparent;
  border: none;
  text-transform: uppercase;
  padding-right: 30px;
}
.language-switcher .btn-group button .fa {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.language-switcher .btn-group button .fa::before {
  display: flex;
  content: "";
  width: 16px;
  height: 9px;
  background-image: url("../../../icons/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 9px;
}
.language-switcher .btn-group .dropdown-menu {
  min-width: 35px;
  left: unset;
  right: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
  border-radius: 0;
  background-color: transparent;
}
@media (max-width: 1280px) {
  .language-switcher .btn-group .dropdown-menu {
    background-color: var(--white);
    right: unset;
  }
}
.language-switcher .btn-group .dropdown-menu button {
  color: var(--primary-color);
  font-size: 3rem;
  letter-spacing: 0.22em;
}
.language-switcher .btn-group .dropdown-menu button:hover {
  text-decoration: none;
}
.language-switcher .btn-group.open .dropdown-toggle {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

footer .footer-wrap {
  display: flex;
  flex-direction: column;
  background-color: var(--footer-background-color);
  color: var(--white);
}
footer .footer-wrap .footer-logo {
  display: flex;
  justify-content: center;
  margin-top: 4.7916666667vw;
  margin-bottom: 7.1354166667vw;
}
@media screen and (max-width: 991px) {
  footer .footer-wrap .footer-logo {
    margin-top: 66px;
    margin-bottom: 47px;
  }
}
footer .footer-wrap .footer-logo .footer-logo-icon {
  display: block;
  width: 246px;
}
footer .footer-wrap .footer-logo .footer-logo-icon svg {
  display: block;
  max-width: 246px;
}
@media screen and (max-width: 991px) {
  footer .footer-wrap .footer-logo .footer-logo-icon {
    width: 160px;
  }
  footer .footer-wrap .footer-logo .footer-logo-icon svg {
    width: 160px;
  }
}
footer .footer-wrap .footer-column-wrap {
  display: flex;
  padding: 0 var(--side-padding);
  padding-bottom: 55px;
}
@media screen and (max-width: 991px) {
  footer .footer-wrap .footer-column-wrap {
    flex-direction: column;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
  }
}
footer .footer-wrap .footer-column-wrap .footer-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 33.333%;
}
@media screen and (max-width: 991px) {
  footer .footer-wrap .footer-column-wrap .footer-column {
    width: 100%;
    order: 2;
    align-items: center;
    justify-content: center;
  }
}
footer .footer-wrap .footer-column-wrap .footer-column--mob {
  order: 4;
}
@media screen and (min-width: 992px) {
  footer .footer-wrap .footer-column-wrap .footer-column--mob {
    display: none;
  }
}
footer .footer-wrap .footer-column-wrap .footer-column:nth-child(2) {
  align-items: center;
}
@media screen and (max-width: 991px) {
  footer .footer-wrap .footer-column-wrap .footer-column:nth-child(2) {
    order: 1;
  }
}
footer .footer-wrap .footer-column-wrap .footer-column:nth-child(3) {
  align-items: flex-end;
}
@media screen and (max-width: 991px) {
  footer .footer-wrap .footer-column-wrap .footer-column:nth-child(3) {
    order: 3;
    align-items: center;
  }
}
@media screen and (max-width: 991px) {
  footer .footer-wrap .footer-column-wrap .footer-column .column-block {
    display: flex;
    flex-direction: column;
  }
  footer .footer-wrap .footer-column-wrap .footer-column .column-block--dsk {
    display: none;
  }
}
footer .footer-wrap .footer-column-wrap .footer-column .column-block.mobile-toggle.active .open-footer-data {
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}
footer .footer-wrap .footer-column-wrap .footer-column .column-block .column-head {
  display: flex;
  /* font-family */
  font-family: var(--primary-font), sans-serif;
  /* font-size */
  font-size: 30px;
  font-size: 3rem;
  /* font-weight */
  font-weight: 500;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 37px;
  line-height: 3.7rem;
  /* letter-spacing */
  letter-spacing: 0.22em;
  color: var(--white);
  margin-bottom: 28px;
}
@media (max-width: 991px) {
  footer .footer-wrap .footer-column-wrap .footer-column .column-block .column-head {
    align-items: center;
    justify-content: center;
  }
}
footer .footer-wrap .footer-column-wrap .footer-column .column-block .column-head .open-footer-data {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 9px;
}
footer .footer-wrap .footer-column-wrap .footer-column .column-block .column-head .open-footer-data svg {
  display: flex;
  width: 16px;
  height: 9px;
}
footer .footer-wrap .footer-column-wrap .footer-column .column-block .column-head .open-footer-data svg .st0 {
  fill: #fff;
}
@media screen and (max-width: 991px) {
  footer .footer-wrap .footer-column-wrap .footer-column .column-block .column-head {
    /* font-family */
    /* font-size */
    font-size: 24px;
    font-size: 2.4rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    line-height: 30px;
    line-height: 3rem;
    /* letter-spacing */
    text-align: center;
    margin: 15px 0;
  }
}
footer .footer-wrap .footer-column-wrap .footer-column .column-block .column-data {
  display: flex;
  flex-direction: column;
}
footer .footer-wrap .footer-column-wrap .footer-column .column-block .column-data > *:not(:last-child) {
  margin-bottom: 17px;
}
@media (max-width: 1280px) {
  footer .footer-wrap .footer-column-wrap .footer-column .column-block .column-data > *:not(:last-child) {
    margin-bottom: 7px;
  }
}
footer .footer-wrap .footer-column-wrap .footer-column .column-block .column-data a {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 24px;
  font-size: 2.4rem;
  /* font-weight */
  font-weight: 400;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 30px;
  line-height: 3rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
  color: var(--white);
}
@media screen and (max-width: 1280px) {
  footer .footer-wrap .footer-column-wrap .footer-column .column-block .column-data a {
    /* font-family */
    /* font-size */
    font-size: 20px;
    font-size: 2rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    /* letter-spacing */
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 991px) {
  footer .footer-wrap .footer-column-wrap .footer-column .column-block .column-data a {
    text-align: center;
  }
}
footer .footer-wrap .footer-column-wrap .footer-column .column-block .column-data .social {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 78px;
}
@media (max-width: 991px) {
  footer .footer-wrap .footer-column-wrap .footer-column .column-block .column-data .social {
    margin-bottom: 30px;
  }
}
footer .footer-wrap .footer-column-wrap .footer-column .column-block .column-data .social a:first-child {
  margin-right: 30px;
}
footer .footer-wrap .footer-column-wrap .footer-column .column-block .column-data .payment-types {
  display: flex;
  flex-direction: column;
  min-width: 211px;
}
@media screen and (max-width: 991px) {
  footer .footer-wrap .footer-column-wrap .footer-column .column-block .column-data .payment-types {
    display: inline-flex;
    flex-direction: row;
    margin-bottom: 55px;
  }
}
footer .footer-wrap .footer-column-wrap .footer-column .column-block .column-data .payment-types span:not(:last-child) {
  margin-bottom: 30px;
}
@media screen and (max-width: 991px) {
  footer .footer-wrap .footer-column-wrap .footer-column .column-block .column-data .payment-types span {
    margin-bottom: 0 !important;
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
footer .footer-wrap .footer-column-wrap .footer-column .column-block .moto {
  /* font-family */
  font-family: var(--primary-font), sans-serif;
  /* font-size */
  font-size: 150px;
  font-size: 15rem;
  /* font-weight */
  font-weight: 500;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 186px;
  line-height: 18.6rem;
  /* letter-spacing */
  letter-spacing: 0.22em;
  text-transform: uppercase;
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 52px;
}
@media screen and (max-width: 1536px) {
  footer .footer-wrap .footer-column-wrap .footer-column .column-block .moto {
    /* font-family */
    /* font-size */
    font-size: 120px;
    font-size: 12rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    line-height: 144px;
    line-height: 14.4rem;
    /* letter-spacing */
  }
}
@media screen and (max-width: 1280px) {
  footer .footer-wrap .footer-column-wrap .footer-column .column-block .moto {
    /* font-family */
    /* font-size */
    font-size: 100px;
    font-size: 10rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    line-height: 122px;
    line-height: 12.2rem;
    /* letter-spacing */
  }
}
@media screen and (max-width: 991px) {
  footer .footer-wrap .footer-column-wrap .footer-column .column-block .moto {
    /* font-family */
    /* font-size */
    font-size: 80px;
    font-size: 8rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    line-height: 99px;
    line-height: 9.9rem;
    /* letter-spacing */
  }
}
@media screen and (min-width: 992px) {
  footer .footer-wrap .footer-column-wrap .footer-column .column-block .moto span:last-child {
    margin-top: -30px;
  }
}
footer .footer-wrap .footer-column-wrap .footer-column .column-block.footer-newsletter-wrap {
  width: 100%;
}
footer .footer-wrap .footer-column-wrap .footer-column .column-block.footer-newsletter-wrap form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
  margin-bottom: 87px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 991px) {
  footer .footer-wrap .footer-column-wrap .footer-column .column-block.footer-newsletter-wrap form {
    margin-bottom: 22px;
  }
}
footer .footer-wrap .footer-column-wrap .footer-column .column-block.footer-newsletter-wrap form input {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 24px;
  font-size: 2.4rem;
  /* font-weight */
  font-weight: 400;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 30px;
  line-height: 3rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
  padding: 14px 5px;
  margin-bottom: 40px;
  background-color: transparent;
  text-align: center;
  color: var(--white);
  border-color: var(--white) !important;
}
@media screen and (max-width: 991px) {
  footer .footer-wrap .footer-column-wrap .footer-column .column-block.footer-newsletter-wrap form input {
    /* font-family */
    /* font-size */
    font-size: 20px;
    font-size: 2rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    /* letter-spacing */
    margin-bottom: 20px;
  }
}
footer .footer-wrap .footer-column-wrap .footer-column .column-block.footer-newsletter-wrap form input::placeholder {
  color: var(--white);
  opacity: 1;
}
footer .footer-wrap .footer-column-wrap .footer-column .column-block.footer-newsletter-wrap form input:-ms-input-placeholder {
  color: var(--white);
}
footer .footer-wrap .footer-column-wrap .footer-column .column-block.footer-newsletter-wrap form input::-ms-input-placeholder {
  color: var(--white);
}
footer .footer-wrap .footer-column-wrap .footer-column .column-block.footer-newsletter-wrap form button {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 24px;
  font-size: 2.4rem;
  /* font-weight */
  font-weight: 400;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 30px;
  line-height: 3rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
  border: none;
  background-color: var(--white);
  color: var(--gold);
  border-radius: 40px;
  padding: 14px 5px;
}
@media screen and (max-width: 991px) {
  footer .footer-wrap .footer-column-wrap .footer-column .column-block.footer-newsletter-wrap form button {
    /* font-family */
    /* font-size */
    font-size: 20px;
    font-size: 2rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    /* letter-spacing */
  }
}
footer .footer-wrap .footer-column-wrap .footer-column .column-block.footer-newsletter-wrap form #subscribe_result .alert-success {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 16px;
  font-size: 1.6rem;
  /* font-weight */
  font-weight: 400;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 30px;
  line-height: 3rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  border-radius: 40px;
  padding: 14px 5px;
  background: var(--white);
  color: var(--white);
  background-color: var(--black);
}
footer .footer-wrap .footer-column-wrap .footer-column .column-block.footer-newsletter-wrap form #subscribe_result .alert-danger {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 16px;
  font-size: 1.6rem;
  /* font-weight */
  font-weight: 400;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 30px;
  line-height: 3rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  border-radius: 40px;
  padding: 14px 5px;
  background: var(--white);
  color: var(--white);
  background-color: #a94442;
}
footer .footer-wrap .footer-column-wrap .footer-column .column-block.footer-newsletter-wrap form #subscribe_result .alert-danger {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 16px;
  font-size: 1.6rem;
  /* font-weight */
  font-weight: 400;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 30px;
  line-height: 3rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  border-radius: 40px;
  padding: 14px 5px;
  background: var(--white);
  color: var(--white);
  background-color: #a94442;
}
footer .footer-wrap .footer-column-wrap .footer-column .column-block .copyright {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 20px;
  font-size: 2rem;
  /* font-weight */
  font-weight: 400;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 20px;
  line-height: 2rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: var(--white);
}
@media (max-width: 1280px) {
  footer .footer-wrap .footer-column-wrap .footer-column .column-block .copyright {
    font-size: 1.6rem;
  }
}
footer .footer-wrap .footer-column-wrap .footer-column .column-block .copyright .credits {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 991px) {
  footer .footer-wrap .footer-column-wrap .footer-column .column-block .copyright {
    /* font-family */
    /* font-size */
    font-size: 16px;
    font-size: 1.6rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    line-height: 22px;
    line-height: 2.2rem;
    /* letter-spacing */
  }
}
footer .footer-wrap .footer-column-wrap .footer-column .column-block .copyright > span:first-child {
  margin-bottom: 14px;
}
footer .footer-wrap .footer-column-wrap .footer-column .column-block .copyright svg {
  max-height: 20px;
  min-width: 61px;
  display: inline-flex;
  fill: var(--white);
}
@media screen and (max-width: 991px) {
  footer .footer-wrap .footer-column-wrap .footer-column .column-block .copyright svg {
    max-height: 18px;
    min-width: 49px;
  }
}
footer .footer-sub-image {
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
  background-color: var(--white);
  height: 43.75vw;
  background-image: var(--footer-graphic);
  background-size: 34.4791666667vw;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
@media screen and (max-width: 991px) {
  footer .footer-sub-image {
    min-height: 400px;
    background-size: 340px;
  }
}
@media screen and (max-width: 991px) and (min-height: 550px) {
  footer .footer-sub-image {
    min-height: calc(100vh - 170px);
  }
}
@media screen and (max-width: 360px) {
  footer .footer-sub-image {
    background-size: 94.4444444444vw;
  }
}

@media (min-width: 992px) {
  .open-footer-data {
    display: none !important;
  }
}
.company_name {
  display: inline-block;
  max-height: 18px;
}
.company_name .eight-logo-icon {
  display: inline-block;
  width: 49px;
  height: 18px;
}
.company_name .eight-logo-icon svg {
  display: inline-block;
  width: 49px;
  height: 18px;
}

/*========================================================*/
/*** MINI CART ***/
/*========================================================*/
#cart .minicart-body {
  display: block;
  position: fixed;
  z-index: 999999;
  padding: 30px clamp(10px, 2.083vw, 40px);
  background-color: var(--white);
  border: none;
  max-width: 470px;
  width: 100%;
  transition: right 0.6s ease;
  right: -100%;
  top: 0;
  bottom: 0;
  height: 100vh;
  border-radius: 0;
}
@media (max-width: 767px) {
  #cart .minicart-body {
    padding: 30px 10px;
  }
}
#cart .minicart-body.open {
  right: 0;
}
@media (max-width: 767px) {
  #cart .minicart-body.open {
    max-width: 100%;
  }
}
#cart .minicart-body .close-mini-cart {
  justify-content: space-between;
  align-items: center;
  padding-bottom: 50px;
}
#cart .minicart-body .close-mini-cart .header-2 {
  margin-bottom: 0;
  color: var(--primary-color);
  font-size: 6rem;
  letter-spacing: 0.01em;
}
@media (max-width: 1440px) {
  #cart .minicart-body .close-mini-cart .header-2 {
    font-size: 3.6rem;
  }
}
#cart .minicart-body .close-mini-cart .close-mini-cart-btn .close-btn {
  display: block;
  width: 24px;
  height: 24px;
}
#cart .minicart-body .close-mini-cart .close-mini-cart-btn .close-btn svg {
  display: block;
  width: 24px;
  height: 24px;
}
#cart .minicart-body .all-products-w-button .scrollable-products {
  overflow-y: auto;
  max-height: calc(var(--screenHeader) - 425px);
}
@media (max-width: 767px) {
  #cart .minicart-body .all-products-w-button .scrollable-products {
    max-height: calc(var(--screenHeader) - 350px);
  }
}
#cart .minicart-body .all-products-w-button .scrollable-products li {
  padding: 30px 0;
  border-bottom: 1px solid var(--primary-color);
}
#cart .minicart-body .all-products-w-button .scrollable-products li .mini-cart-products {
  justify-content: space-between;
  align-items: center;
}
#cart .minicart-body .all-products-w-button .scrollable-products li .mini-cart-products .product-img {
  flex-basis: 35%;
}
@media (max-width: 767px) {
  #cart .minicart-body .all-products-w-button .scrollable-products li .mini-cart-products .product-img {
    flex-basis: 25%;
  }
}
@media (max-width: 440px) {
  #cart .minicart-body .all-products-w-button .scrollable-products li .mini-cart-products .product-img {
    flex-basis: 35%;
  }
}
#cart .minicart-body .all-products-w-button .scrollable-products li .mini-cart-products .product-img a {
  display: inline-block;
}
#cart .minicart-body .all-products-w-button .scrollable-products li .mini-cart-products .product-img a .img-thumbnail {
  border: none;
  border-radius: 0;
  padding: 0;
}
#cart .minicart-body .all-products-w-button .scrollable-products li .mini-cart-products .prd-item {
  flex-basis: 55%;
  margin-left: 10px;
  font-family: var(--secondary-font), sans-serif;
  letter-spacing: 0.01em;
}
@media (max-width: 767px) {
  #cart .minicart-body .all-products-w-button .scrollable-products li .mini-cart-products .prd-item {
    flex-basis: 65%;
  }
}
@media (max-width: 440px) {
  #cart .minicart-body .all-products-w-button .scrollable-products li .mini-cart-products .prd-item {
    flex-basis: 55%;
  }
}
#cart .minicart-body .all-products-w-button .scrollable-products li .mini-cart-products .prd-item .prd-quantity {
  font-size: 1.6rem;
  margin-bottom: 5px;
}
#cart .minicart-body .all-products-w-button .scrollable-products li .mini-cart-products .prd-item a.prd-name {
  display: block;
  font-size: 2.2rem;
  font-family: var(--secondary-font), sans-serif;
  text-transform: capitalize;
  letter-spacing: 0.01em;
}
@media (max-width: 767px) {
  #cart .minicart-body .all-products-w-button .scrollable-products li .mini-cart-products .prd-item a.prd-name {
    font-size: 1.6rem;
  }
}
#cart .minicart-body .all-products-w-button .scrollable-products li .mini-cart-products .prd-item .option-item {
  display: block;
  font-size: 1.6rem;
}
@media (max-width: 767px) {
  #cart .minicart-body .all-products-w-button .scrollable-products li .mini-cart-products .prd-item .option-item {
    font-size: 1.2rem;
  }
}
#cart .minicart-body .all-products-w-button .scrollable-products li .mini-cart-products .prd-item .prd-price {
  font-size: 2.2rem;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  #cart .minicart-body .all-products-w-button .scrollable-products li .mini-cart-products .prd-item .prd-price {
    font-size: 1.6rem;
  }
}
#cart .minicart-body .all-products-w-button .scrollable-products li .mini-cart-products .product-remove {
  flex-basis: 10%;
  display: flex;
  justify-content: end;
}
#cart .minicart-body .all-products-w-button .scrollable-products li .mini-cart-products .product-remove button {
  padding: 15px;
}
#cart .minicart-body .all-products-w-button .scrollable-products li .mini-cart-products .product-remove button .delete-icon {
  display: block;
  width: 12px;
  height: 12px;
}
#cart .minicart-body .all-products-w-button .scrollable-products li .mini-cart-products .product-remove button .delete-icon svg {
  display: block;
  width: 12px;
  height: 12px;
}
#cart .minicart-body .mobile-bottom-cart {
  background-color: var(--white);
}
#cart .minicart-body .mobile-bottom-cart .mini-cart-total .col-total {
  justify-content: space-between;
  padding: 30px 0;
  border-bottom: 1px solid var(--primary-color);
  text-transform: uppercase;
  letter-spacing: 0.01em;
  font-size: 2.4rem;
}
@media (max-width: 767px) {
  #cart .minicart-body .mobile-bottom-cart .mini-cart-total .col-total {
    font-size: 2rem;
  }
}
#cart .minicart-body .mobile-bottom-cart .mini-cart-total .col-total:nth-child(2), #cart .minicart-body .mobile-bottom-cart .mini-cart-total .col-total:nth-child(3) {
  display: none;
}
#cart .minicart-body .mobile-bottom-cart .mini-cart-checkout {
  margin-top: 60px;
}
@media (max-width: 767px) {
  #cart .minicart-body .mobile-bottom-cart .mini-cart-checkout {
    margin-top: 30px;
  }
}
#cart .minicart-body .mobile-bottom-cart .mini-cart-checkout a {
  letter-spacing: 0.22em;
  color: var(--white);
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: var(--button-border-radius);
  font-family: var(--primary-font), sans-serif;
  font-size: var(--btn-font);
  -webkit-transition: ease 0.3s;
  -moz-transition: ease 0.3s;
  -o-transition: ease 0.3s;
  transition: ease 0.3s;
}
@media (max-width: 440px) {
  #cart .minicart-body .mobile-bottom-cart .mini-cart-checkout a {
    padding: 7px 15px;
  }
}
#cart .minicart-body .mobile-bottom-cart .mini-cart-checkout a:hover, #cart .minicart-body .mobile-bottom-cart .mini-cart-checkout a:focus, #cart .minicart-body .mobile-bottom-cart .mini-cart-checkout a:active, #cart .minicart-body .mobile-bottom-cart .mini-cart-checkout a:focus-within, #cart .minicart-body .mobile-bottom-cart .mini-cart-checkout a:focus-visible {
  background-color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  border-radius: var(--button-border-radius);
  -webkit-transition: ease 0.3s;
  -moz-transition: ease 0.3s;
  -o-transition: ease 0.3s;
  transition: ease 0.3s;
}

.minicart-wrapper {
  height: calc(var(--screenHeader) - 60px);
  overflow-y: auto;
}

.products-block {
  display: inline-flex;
  flex-wrap: wrap;
  min-width: 100%;
}
@media screen and (min-width: 992px) {
  .products-block__category {
    background-image: var(--graphic);
    background-repeat: no-repeat;
    background-position: 50% 40px;
    background-size: 75.9895833333vw;
    min-height: calc(40.9895833333vw + 40px);
  }
}

.product-col {
  width: calc(33.333% - 3.2291666667vw);
  margin-left: 4.84375vw;
  margin-bottom: max(40px, 3.6458333333vw);
}
@media screen and (max-width: 991px) {
  .product-col {
    margin-left: 20px;
    width: calc(50% - 10px);
  }
  .product-col:nth-child(2n+1) {
    margin-left: 0;
  }
}
@media screen and (max-width: 767px) {
  .product-col {
    margin-left: 20px;
    width: calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
  .product-col:last-child {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 992px) {
  .product-col:nth-child(3n+1) {
    margin-left: 0;
  }
}
@media screen and (max-width: 767px) {
  .product-col:nth-child(5), .product-col:nth-child(6), .product-col:nth-child(11), .product-col:nth-child(12) {
    min-width: 100%;
    margin-left: 0;
  }
  .product-col:nth-child(5) .caption, .product-col:nth-child(6) .caption, .product-col:nth-child(11) .caption, .product-col:nth-child(12) .caption {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .product-col:nth-child(5) .price,
  .product-col:nth-child(5) .header-3, .product-col:nth-child(6) .price,
  .product-col:nth-child(6) .header-3, .product-col:nth-child(11) .price,
  .product-col:nth-child(11) .header-3, .product-col:nth-child(12) .price,
  .product-col:nth-child(12) .header-3 {
    order: unset !important;
  }
}

.product-thumb:hover .caption * {
  color: var(--gold) !important;
}
.product-thumb:hover .image:after {
  background-color: rgba(34, 34, 34, 0.25);
}
.product-thumb .sale_badge {
  display: none;
}
.product-thumb .image {
  position: relative;
}
.product-thumb .image:after {
  content: "";
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: background-color 0.3s ease-in-out;
  pointer-events: none;
}
.product-thumb .image img {
  min-width: 100%;
}
.product-thumb .image .add-to-wishlist {
  display: none !important;
}
.product-thumb .caption {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 3px;
}
@media screen and (max-width: 991px) {
  .product-thumb .caption {
    padding-top: 11px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: unset;
  }
}
.product-thumb .caption .header-3 {
  margin-bottom: 0;
  line-height: 20px;
}
@media screen and (max-width: 991px) {
  .product-thumb .caption .header-3 {
    order: 2;
    line-height: 14px;
  }
}
.product-thumb .caption .header-3 a {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 22px;
  font-size: 2.2rem;
  /* font-weight */
  font-weight: 400;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 22px;
  line-height: 2.2rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
  display: inline-flex;
  text-align: left;
  padding-right: 15px;
  color: var(--dark-blue);
}
@media screen and (max-width: 991px) {
  .product-thumb .caption .header-3 a {
    /* font-family */
    /* font-size */
    font-size: 16px;
    font-size: 1.6rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    /* letter-spacing */
  }
}
.product-thumb .caption .product-description {
  display: none;
}
.product-thumb .caption .price {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 22px;
  font-size: 2.2rem;
  /* font-weight */
  font-weight: 400;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 22px;
  line-height: 2.2rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
  color: var(--dark-blue);
}
@media screen and (max-width: 991px) {
  .product-thumb .caption .price {
    /* font-family */
    /* font-size */
    font-size: 16px;
    font-size: 1.6rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    /* letter-spacing */
    order: 1;
  }
}
.product-thumb .caption .price > div {
  display: inline-flex;
}
.product-thumb .caption .price > div * {
  color: var(--dark-blue);
}
.product-thumb .caption .price .price-new {
  color: var(--dark-blue);
  order: 2;
  font-weight: initial;
}
.product-thumb .caption .price .price-old {
  order: 1;
  text-decoration: line-through;
  font-size: 75%;
}
.product-thumb .caption .price .discount-percentage {
  display: none;
}
.product-thumb .caption .price > * * {
  color: var(--dark-blue);
}
.counter-pagination {
  justify-content: center;
  align-items: center;
  padding-top: max(37px, 7.8125vw - 70px);
  padding-right: 0;
  padding-bottom: max(71px, 10.4166666667vw);
  padding-left: 0;
}
.counter-pagination .pagination._front {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
}
.counter-pagination .pagination._front li:hover,
.counter-pagination .pagination._front a:hover {
  color: var(--gold);
  background-color: unset;
  border-color: unset;
}
.counter-pagination .pagination._front li {
  display: none;
}
.counter-pagination .pagination._front li span,
.counter-pagination .pagination._front li a {
  padding: 0;
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 24px;
  font-size: 2.4rem;
  /* font-weight */
  font-weight: 400;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 30px;
  line-height: 3rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
  text-align: center;
  color: var(--dark-blue);
}
@media screen and (max-width: 991px) {
  .counter-pagination .pagination._front li span,
  .counter-pagination .pagination._front li a {
    /* font-family */
    /* font-size */
    font-size: 16px;
    font-size: 1.6rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    /* letter-spacing */
  }
}
.counter-pagination .pagination._front li span {
  padding: 0;
}
.counter-pagination .pagination._front li span:hover {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 24px;
  font-size: 2.4rem;
  /* font-weight */
  font-weight: 400;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 30px;
  line-height: 3rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
  color: var(--dark-blue);
  background-color: transparent;
  border-color: transparent;
}
@media screen and (max-width: 991px) {
  .counter-pagination .pagination._front li span:hover {
    /* font-family */
    /* font-size */
    font-size: 16px;
    font-size: 1.6rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    /* letter-spacing */
  }
}
.counter-pagination .pagination._front li.active:not(:last-child) span:after {
  padding: 0 5px;
  content: "/";
  display: inline-flex;
  color: var(--dark-blue) !important;
}
.counter-pagination .pagination._front li.active, .counter-pagination .pagination._front li._total, .counter-pagination .pagination._front li._prev-container, .counter-pagination .pagination._front li._next-container {
  display: inline-flex;
}
.counter-pagination .pagination._front li.active + ._dummy {
  display: inline-flex;
}
.counter-pagination .pagination._front li._prev-container {
  padding-right: 3.6458333333vw;
}
.counter-pagination .pagination._front li._prev-container a {
  content: url("../../../icons/pagination-arrow-left.svg");
}
@media screen and (max-width: 991px) {
  .counter-pagination .pagination._front li._prev-container a {
    max-width: 41px;
  }
}
.counter-pagination .pagination._front li._next-container {
  padding-left: 3.6458333333vw;
}
.counter-pagination .pagination._front li._next-container a {
  content: url("../../../icons/pagination-arrow-right.svg");
}
@media screen and (max-width: 991px) {
  .counter-pagination .pagination._front li._next-container a {
    max-width: 41px;
  }
}

.default-section {
  padding: 50px var(--side-padding) clamp(50px, 6.25vw, 120px);
}
.default-section.special-edition {
  background-color: var(--light-blue);
}
@media (min-width: 992px) {
  .default-section.special-edition .products-grid .swiper-wrapper {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
  }
  .default-section.special-edition .products-grid .swiper-wrapper .swiper-slide {
    flex-basis: calc(33.3333% - 2.344vw);
    margin-bottom: 70px;
  }
}
.default-section .header-1, .default-section .header-2 {
  text-align: center;
  color: var(--primary-color);
}
.default-section .products-grid {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .default-section .products-grid .swiper-slide {
    margin-bottom: 90px;
  }
}
.default-section .products-grid .swiper-slide .product-item {
  flex-basis: 33.333%;
  padding-bottom: 3px;
}
@media (max-width: 991px) {
  .default-section .products-grid .swiper-slide .product-item {
    flex-basis: 50%;
  }
}
@media (max-width: 440px) {
  .default-section .products-grid .swiper-slide .product-item {
    flex-basis: 100%;
  }
  .default-section .products-grid .swiper-slide .product-item .product-thumb .caption {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
}
.default-section .products-grid .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: transparent;
  box-shadow: none;
  border: 1px solid var(--secondary-color);
}
.default-section .products-grid .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--secondary-color);
}

.default-banner {
  padding: 6.1458333333vw var(--side-padding);
}
@media (max-width: 660px) {
  .default-banner {
    padding: 0;
  }
}
@media (max-width: 660px) {
  .default-banner .default-banner-wrapper {
    padding-top: 45px;
    padding-bottom: 125px;
  }
  .default-banner .default-banner-wrapper .img-hover {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
.default-banner .default-banner-wrapper .left-col {
  flex-basis: 50%;
}
@media (max-width: 660px) {
  .default-banner .default-banner-wrapper .left-col .banner-cta {
    text-align: center;
  }
}
.default-banner .default-banner-wrapper .right-col {
  flex-basis: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 10px;
  text-align: center;
}
@media (max-width: 660px) {
  .default-banner .default-banner-wrapper {
    flex-direction: column-reverse;
  }
}
.default-banner .default-banner-wrapper .img-hover {
  display: inline-block;
  position: relative;
}
.default-banner .default-banner-wrapper .img-hover::after {
  content: "";
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: background-color 0.3s ease-in-out;
}
.default-banner .default-banner-wrapper .img-hover:hover::after {
  background-color: rgba(34, 34, 34, 0.25);
}
.default-banner.reversed .default-banner-wrapper {
  flex-direction: row-reverse;
}
@media (max-width: 660px) {
  .default-banner.reversed .default-banner-wrapper {
    flex-direction: column-reverse;
  }
}

@media (min-width: 661px) {
  .homeBanners .swiper-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
}
.homepage-about {
  background-color: var(--light-brown);
  padding: 85px 0 0;
}
@media (max-width: 767px) {
  .homepage-about {
    padding: 40px 0 120px;
  }
}
.homepage-about .homepage-about-wrapper .upper {
  justify-content: space-between;
  align-items: end;
}
.homepage-about .homepage-about-wrapper .upper .left {
  max-width: 26.042vw;
}
@media (max-width: 767px) {
  .homepage-about .homepage-about-wrapper .upper .left {
    display: none;
  }
}
.homepage-about .homepage-about-wrapper .upper .right {
  max-width: 39.844vw;
  padding-bottom: 3.417vw;
}
@media (max-width: 767px) {
  .homepage-about .homepage-about-wrapper .upper .right {
    max-width: 100%;
    padding: 0 10px 20px;
  }
}
.homepage-about .homepage-about-wrapper .about-short-description {
  text-align: center;
  width: 100%;
  max-width: 890px;
  padding: 0 10px;
  margin: 0 auto;
  position: relative;
  top: -15.625vw;
}
@media (max-width: 1024px) {
  .homepage-about .homepage-about-wrapper .about-short-description {
    max-width: 700px;
  }
}
@media (max-width: 767px) {
  .homepage-about .homepage-about-wrapper .about-short-description {
    top: unset;
  }
}
.homepage-about .homepage-about-wrapper .about-short-description img {
  width: 100%;
  max-width: clamp(105px, 10.677vw, 205px);
  margin: 0 auto 75px;
}
@media (max-width: 767px) {
  .homepage-about .homepage-about-wrapper .about-short-description img {
    margin-bottom: 5px;
  }
}
@media (max-width: 440px) {
  .homepage-about .homepage-about-wrapper .about-short-description img {
    max-width: 85px;
  }
}
.homepage-about .homepage-about-wrapper .about-short-description .header-1, .homepage-about .homepage-about-wrapper .about-short-description .header-2 {
  width: 100%;
  max-width: 565px;
  margin-left: auto;
  margin-right: auto;
}
.homepage-about .homepage-about-wrapper .about-short-description .header-2 {
  padding-top: 15px;
}
.homepage-about .homepage-about-wrapper .about-short-description p {
  margin: 30px 0 45px;
}

.customer-reviews {
  position: relative;
  top: -6.25vw;
}
@media (max-width: 660px) {
  .customer-reviews {
    top: -60px;
  }
}
.customer-reviews .customer-reviews-banner {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
}
@media (max-width: 660px) {
  .customer-reviews .customer-reviews-banner {
    padding: 0 10px;
  }
}
.customer-reviews .customer-reviews-banner .customer-reviews-text {
  display: block;
  width: 100%;
  max-width: clamp(340px, 55.208vw, 1060px);
}
.customer-reviews .customer-reviews-banner .customer-reviews-text svg {
  display: block;
  width: 100%;
  max-width: clamp(340px, 55.208vw, 1060px);
}
.customer-reviews .customer-reviews-banner img {
  position: relative;
  width: 100%;
  max-width: clamp(255px, 41.458vw, 796px);
  margin: 0 auto 25px;
}
.customer-reviews .customer-reviews-banner .customer-reviews-text {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 0 10px;
}
.customer-reviews .customer-reviews-banner .customer-reviews-text svg {
  display: block;
  width: 100%;
}
.customer-reviews .customer-reviews-content {
  text-align: center;
  width: 100%;
  max-width: 890px;
  padding: 0 10px;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .customer-reviews .customer-reviews-content {
    max-width: 700px;
  }
}
@media (max-width: 660px) {
  .customer-reviews .customer-reviews-content {
    padding: 0 10px 0;
  }
}
.customer-reviews .customer-reviews-content .header-1 {
  margin-bottom: 30px;
}
.customer-reviews .customer-reviews-content .slide-item .header-2 {
  text-transform: capitalize;
  margin-top: 10px;
}
.customer-reviews .customer-reviews-content .reviews-footer {
  padding: 30px 30px 0;
  justify-content: center;
}
.customer-reviews .customer-reviews-content .reviews-footer .reviews-button {
  display: inline-block;
  width: 77px;
  height: 30.45px;
}
.customer-reviews .customer-reviews-content .reviews-footer .reviews-button svg {
  display: inline-block;
  width: 77px;
  height: 30.45px;
}
@media (max-width: 767px) {
  .customer-reviews .customer-reviews-content .reviews-footer .reviews-button {
    width: 40px;
    height: auto;
  }
  .customer-reviews .customer-reviews-content .reviews-footer .reviews-button svg {
    width: 40px;
    height: auto;
  }
}
.customer-reviews .customer-reviews-content .reviews-footer .reviews-button.swiper-button-disabled {
  opacity: 0.3;
}
.customer-reviews .customer-reviews-content .reviews-footer .swiper-pagination {
  position: relative;
  bottom: unset;
  width: 100%;
  max-width: 190px;
}
@media (max-width: 767px) {
  .customer-reviews .customer-reviews-content .reviews-footer .swiper-pagination {
    max-width: 100px;
  }
}

body.lang_el .customer-reviews-text.greek_icon {
  display: block;
}
body.lang_el .customer-reviews-text.en_icon {
  display: none;
}
body.lang_en .customer-reviews-text.greek_icon {
  display: none;
}
body.lang_en .customer-reviews-text.en_icon {
  display: block;
}

/*========================================================*/
/*** LOGIN ***/
/*========================================================*/
#account-login .breadcrumb li {
  display: none;
}
@media (max-width: 991px) {
  #account-login {
    width: 100%;
  }
}
#account-login .well {
  padding: 20px;
  background-color: transparent;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
#account-login .well.new-customer-form:before {
  content: "";
  width: 1px;
  height: 100%;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
}
@media (max-width: 767px) {
  #account-login .well.new-customer-form:before {
    content: none;
  }
}
@media (max-width: 767px) {
  #account-login .well {
    padding: 10px;
  }
}
#account-login .register-btn {
  max-width: 200px;
  margin: 25px auto 0;
}
#account-login .forgotten-pass {
  margin-top: 5px;
}

/*========================================================*/
/*** SOCIAL LOGIN ***/
/*========================================================*/
#d_social_login {
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 0 auto clamp(30px, 2.6042vw, 50px) auto;
}
#d_social_login .d-social-login__button {
  display: block;
  width: 100%;
  color: var(--white);
  text-decoration: none;
}
#d_social_login .d-social-login__button .d-social-login__button__icon {
  max-width: 100%;
  text-align: left;
  width: 100%;
  display: block;
  position: relative;
  padding-left: 45px;
}
#d_social_login .d-social-login__button .d-social-login__button__icon img {
  max-width: 30px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
}
#d_social_login .d-social-login__button.d-social-login__button--facebook .d-social-login__button__icon img {
  max-width: 10px;
}
#d_social_login .d-social-login__button.d-social-login__button--google span.d-social-login__button__icon img {
  max-width: 25px;
}

/*========================================================*/
/*** REST ACCOUNT PAGES ***/
/*========================================================*/
.common-account {
  padding: clamp(30px, 5.2083vw, 100px) 15px;
  float: none;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  min-height: 85vh;
}
.common-account.large-container {
  max-width: 900px;
}
.common-account legend {
  font-size: var(--text);
  font-weight: 500;
  border-bottom: none;
  margin: 0 0 15px;
  text-transform: uppercase;
}
.common-account .terms {
  margin: 0 0 15px;
}
.common-account ul > li > a {
  line-height: 2.5;
  text-decoration: none;
}
.common-account .btn-default {
  text-decoration: none !important;
}
.common-account .col-sm-2.control-label {
  display: none;
}
.common-account .default-address,
.common-account .is-opened {
  display: block !important;
  text-align: left;
  width: 100%;
}
.common-account .buttons {
  margin-top: 15px;
}
.common-account .col-sm-10 {
  display: block;
  width: 100%;
}
.common-account .table-bordered > tbody > tr > td,
.common-account .table-bordered > tbody > tr > th,
.common-account .table-bordered > tfoot > tr > td,
.common-account .table-bordered > tfoot > tr > th,
.common-account .table-bordered > thead > tr > td,
.common-account .table-bordered > thead > tr > th {
  border: none;
  border-bottom: 1px solid var(--input-border-color);
  padding: 20px 15px;
}
.common-account td.text-left a {
  color: #000;
  text-decoration: none;
}
.common-account td.text-right > a {
  text-decoration: none;
}
.common-account .add-to-cart-icon svg,
.common-account .add-to-cart-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.common-account .remove-item-icon svg .st0 {
  fill: none;
  stroke: #000000;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
.common-account .remove-item-icon svg,
.common-account .remove-item-icon {
  display: inline-block;
  width: 15px;
  height: 15px;
}
.common-account thead > tr > td {
  font-size: 1.4rem;
  letter-spacing: 0.025em;
  font-weight: 600;
  color: #000;
}
.common-account tbody > tr > td {
  font-size: 1.4rem;
  font-weight: 400;
  color: #000;
}
.common-account .table-hover > tbody > tr {
  opacity: 0.7;
  background-color: transparent;
}
.common-account .table-hover > tbody > tr:hover {
  opacity: 1;
}
.common-account td.text-right > a.edit-address-btn,
.common-account td.text-right > a.remove-address-btn,
.common-account td.text-right > a.btn-view-item {
  font-size: 1.1rem;
  line-height: initial;
  font-weight: 600;
  letter-spacing: 0.025em;
  color: #000;
  border: none;
  border-radius: 0;
  background-image: none;
  text-transform: uppercase;
  text-decoration: underline;
}
.common-account.account-voucher .col-sm-2.control-label {
  display: block;
  width: 100%;
  text-align: left;
}

.container.common-account.small-container {
  max-width: 500px;
}

.input-group.date button.btn.btn-default {
  padding: 9px 20px;
}

label.col-sm-3.control-label.newsletter-text,
label.col-sm-4.control-label.newsletter-text {
  text-align: left;
}

@media screen and (min-width: 992px) {
  .height-balancer {
    min-height: 200px;
  }
}

body.page_contact,
body.page_account {
  background-color: var(--light-brown);
}

.account-title, ._account h1,
._account h2, .account-block h2 {
  /* font-family */
  font-family: var(--primary-font), sans-serif;
  /* font-size */
  font-size: 34px;
  font-size: 3.4rem;
  /* font-weight */
  font-weight: 500;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 42px;
  line-height: 4.2rem;
  /* letter-spacing */
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin-bottom: 37px;
  color: var(--gold);
  color: var(--gold);
}
@media screen and (max-width: 991px) {
  .account-title, ._account h1,
  ._account h2, .account-block h2 {
    /* font-family */
    /* font-size */
    font-size: 30px;
    font-size: 3rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    line-height: 37px;
    line-height: 3.7rem;
    /* letter-spacing */
    margin-bottom: 19px;
  }
}

.account-page-heading {
  padding-top: 6.4583333333vw;
  padding-bottom: 4.4791666667vw;
  color: var(--gold);
}
.account-page-heading > span {
  /* font-family */
  font-family: var(--primary-font), sans-serif;
  /* font-size */
  font-size: 30px;
  font-size: 3rem;
  /* font-weight */
  font-weight: 500;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 37px;
  line-height: 3.7rem;
  /* letter-spacing */
  letter-spacing: 0.22em;
  color: inherit;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 991px) {
  .account-page-heading > span {
    /* font-family */
    /* font-size */
    font-size: 24px;
    font-size: 2.4rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    line-height: 30px;
    line-height: 3rem;
    /* letter-spacing */
  }
}
.account-page-heading h2 {
  /* font-family */
  font-family: var(--primary-font), sans-serif;
  /* font-size */
  font-size: 60px;
  font-size: 6rem;
  /* font-weight */
  font-weight: 500;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 74px;
  line-height: 7.4rem;
  /* letter-spacing */
  letter-spacing: 0.22em;
  text-align: center;
  color: inherit;
  margin-bottom: 0;
}
@media screen and (max-width: 991px) {
  .account-page-heading h2 {
    /* font-family */
    /* font-size */
    font-size: 36px;
    font-size: 3.6rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    line-height: 45px;
    line-height: 4.5rem;
    /* letter-spacing */
  }
}

.account-paragraph, .account-dual-block ._block p {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 24px;
  font-size: 2.4rem;
  /* font-weight */
  font-weight: 400;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 30px;
  line-height: 3rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
  text-align: left !important;
  color: var(--dark-blue);
  margin-bottom: 40px;
}
@media screen and (max-width: 991px) {
  .account-paragraph, .account-dual-block ._block p {
    /* font-family */
    /* font-size */
    font-size: 16px;
    font-size: 1.6rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    line-height: 22px;
    line-height: 2.2rem;
    /* letter-spacing */
    margin-bottom: 20px;
  }
}

.account-inner-link, .account-block ul li a {
  line-height: 1.5;
  margin-bottom: 5px;
  display: inline-flex;
  border-left: 1px solid transparent;
  transition: all 0.3s ease-in-out;
}
.account-inner-link:hover, .account-block ul li a:hover {
  color: var(--dark-blue);
  border-color: var(--dark-blue);
  padding-left: 10px;
}

.account-btn, ._account .btn-default, ._account input[type=submit] {
  /* font-family */
  font-family: var(--primary-font), sans-serif;
  /* font-size */
  font-size: 24px;
  font-size: 2.4rem;
  /* font-weight */
  font-weight: 400;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 30px;
  line-height: 3rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
  border: none;
  background-color: var(--gold);
  color: var(--white) !important;
  border: 1px solid var(--gold);
  border-radius: 40px;
  padding: 14px 5px;
  line-height: 1.5;
}
@media screen and (max-width: 991px) {
  .account-btn, ._account .btn-default, ._account input[type=submit] {
    /* font-family */
    /* font-size */
    font-size: 20px;
    font-size: 2rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    /* letter-spacing */
  }
}
.account-btn:hover, ._account .btn-default:hover, ._account input[type=submit]:hover {
  background-color: var(--dark-blue);
  border-color: var(--dark-blue);
}

.account-block:not(:last-child) {
  margin-bottom: 40px;
}
.account-block h2 {
  margin-bottom: 15px;
}
._account p a {
  text-decoration: underline;
}
._account .alert {
  margin: unset;
  margin-bottom: 30px;
  background-color: transparent;
  border-radius: 40px;
  border-color: var(--gold);
  text-align: center;
  font-size: 16px;
}
._account .alert.alert-success {
  color: var(--gold);
}
._account .alert.alert-warning {
  color: var(--dark-blue);
  border-color: var(--dark-blue);
}
._account select {
  border-color: var(--gold);
  color: var(--gold);
}
._account .text-danger {
  font-size: 1.4rem;
  text-align: center;
}
._account .has-error .control-label {
  color: #a94442;
}
._account input {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 24px;
  font-size: 2.4rem;
  /* font-weight */
  font-weight: 400;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 30px;
  line-height: 3rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
  background-color: transparent;
}
._account input:-webkit-autofill, ._account input:-webkit-autofill:hover, ._account input:-webkit-autofill:focus {
  box-shadow: unset !important;
}
@media screen and (max-width: 991px) {
  ._account input {
    /* font-family */
    /* font-size */
    font-size: 20px;
    font-size: 2rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    /* letter-spacing */
  }
}
._account .btn-default .fa-calendar {
  line-height: 36px;
}
._account .radio-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 75px;
}
._account .radio-wrap .radio-inline {
  padding: 0;
}
._account .radio-wrap .radio-inline label {
  align-items: center;
  top: 0 !important;
  left: 0 !important;
}
._account .radio-inline input {
  position: relative;
  margin: unset;
  margin-right: 5px;
  margin-top: -2px;
}
._account label:not(.radio-inline) {
  /* font-family */
  font-family: var(--primary-font), sans-serif;
  /* font-size */
  font-size: 24px;
  font-size: 2.4rem;
  /* font-weight */
  font-weight: 500;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 30px;
  line-height: 3rem;
  /* letter-spacing */
  display: inline-flex !important;
  color: var(--gold);
  padding-left: 15px;
  padding-top: 16px;
  padding-bottom: 16px;
  width: auto;
  align-items: center;
}
@media screen and (max-width: 991px) {
  ._account label:not(.radio-inline) {
    padding-top: 8px;
    padding-bottom: 14px;
  }
}
._account label:not(.radio-inline):after {
  content: unset !important;
}
._account label:not(.radio-inline) span {
  color: var(--dark-blue);
}
._account .inline-option,
._account ._newsletter {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
}
._account .inline-option > *,
._account ._newsletter > * {
  text-align: center;
}
._account .inline-option--not-centered,
._account ._newsletter--not-centered {
  align-items: initial;
}
._account .inline-option--not-centered > *,
._account ._newsletter--not-centered > * {
  text-align: left;
}
._account .inline-option label,
._account ._newsletter label {
  left: 0;
  top: 0;
}
._account .inline-option label.radio-inline,
._account ._newsletter label.radio-inline {
  padding: 5px;
}
@media screen and (min-width: 992px) {
  ._account .inline-option label.radio-inline,
  ._account ._newsletter label.radio-inline {
    font-size: 75%;
  }
}
._account .terms {
  font-size: 75%;
}
._account .terms--v-align {
  display: flex;
  align-items: center;
}
._account .form-group {
  margin-bottom: unset !important;
}
._account ._align {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  text-align: center;
}
@media screen and (min-width: 992px) {
  ._account ._align {
    font-size: 75%;
  }
}
._account ._align > span {
  text-align: center;
}
._account ._align .radio-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
}
._account ._align .radio-inline span {
  position: relative;
  line-height: 18px;
}
._account #d_social_login {
  font-size: 12px;
}
._account .order-pag {
  font-size: 14px;
}
._account .buttons--max-600 {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
._account thead tr td {
  color: var(--dark-blue);
}
._account tfoot {
  font-size: 14px;
}
._account tfoot tr:last-of-type {
  text-transform: uppercase;
}

.account-dual-block {
  display: flex;
  justify-content: space-between;
  padding-bottom: 89px;
  padding-top: 89px;
  border-bottom: 1px solid var(--dark-blue);
}
@media screen and (max-width: 991px) {
  .account-dual-block {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 0;
    padding-bottom: 42px;
  }
}
.account-dual-block ._block {
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 512px;
}
@media screen and (max-width: 991px) {
  .account-dual-block ._block {
    width: 100%;
    text-align: center;
  }
  .account-dual-block ._block:first-child {
    margin-top: 25px;
    margin-bottom: 64px;
  }
}
@media screen and (min-width: 992px) {
  .account-dual-block ._block:first-child {
    margin-right: 58px;
  }
  .account-dual-block ._block:last-child {
    margin-left: 58px;
  }
}
.account-dual-block ._block input {
  margin-bottom: 40px;
}
.account-dual-block ._block input:last-of-type {
  margin-bottom: 20px;
}
@media screen and (max-width: 991px) {
  .account-dual-block ._block input {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 992px) {
  .account-dual-block ._block p {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 991px) {
  .account-dual-block ._block p {
    text-align: center !important;
  }
}
.account-dual-block ._block .forgotten-pass {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 16px;
  font-size: 1.6rem;
  /* font-weight */
  font-weight: 400;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 16px;
  line-height: 1.6rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
  color: var(--dark-blue);
  background-color: var(--light-blue);
  padding: 13px 38px;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  text-decoration: underline;
}
.account-dual-block ._block .forgotten-pass:hover {
  background-color: white;
}

#d_social_login {
  display: flex;
  justify-content: center;
  margin: 70px auto;
  max-width: 719px;
}
@media screen and (max-width: 991px) {
  #d_social_login {
    max-width: 479px;
    margin: 42px auto 78px;
  }
}
#d_social_login .d-social-login__button-list {
  width: 100%;
}
#d_social_login .d-social-login__button-list .d-social-login__button {
  display: none;
}
#d_social_login .d-social-login__button-list .d-social-login__button.facebook--login {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 16px;
  font-size: 1.6rem;
  /* font-weight */
  font-weight: 400;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 16px;
  line-height: 1.6rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
  background-color: #527BB9;
  color: var(--white);
  padding: 22px;
  border-radius: 40px;
  display: flex;
  width: 100%;
}
#d_social_login .d-social-login__button-list .d-social-login__button.facebook--login:hover {
  box-shadow: unset !important;
  background-color: #6B96D9;
}
#d_social_login .d-social-login__button-list .d-social-login__button.facebook--login span {
  padding: 0;
}
#d_social_login .d-social-login__button-list .d-social-login__button.facebook--login .d-social-login__button__icon {
  text-align: center;
}
#d_social_login .d-social-login__button-list .d-social-login__button.facebook--login .d-social-login__button__icon img {
  display: none;
}
#d_social_login .d-social-login__button-list .d-social-login__button.facebook--login .d-social-login__button__icon .provider-name {
  white-space: nowrap;
}

body.page_success .common-account #page-title {
  display: none;
}

.checkout-container .container {
  padding-left: 0;
  padding-right: 0;
}
.checkout-container .container .row {
  margin-left: 0;
  margin-right: 0;
}
.checkout-container .container .row > * {
  padding-left: 0;
  padding-right: 0;
}
.checkout-container #main-header,
.checkout-container #search {
  display: none;
}
.checkout-container #d_logo {
  display: none;
  padding: clamp(15px, 2.6042vw, 50px) var(--side-padding-header);
}
.checkout-container #d_logo a {
  display: inline-block;
  margin: 0 auto;
}
.checkout-container a {
  color: var(--secondary-color);
}
.checkout-container .checkout-left {
  padding-right: var(--side-padding-checkout);
}
@media (max-width: 991px) {
  .checkout-container .checkout-left {
    border-right: unset;
    padding-right: 0;
    padding-left: 0;
  }
}
.checkout-container .checkout-right {
  padding-left: var(--side-padding-checkout);
}
@media (max-width: 991px) {
  .checkout-container .checkout-right {
    padding-right: 0;
    padding-left: 0;
  }
}
@media screen and (min-width: 991px) {
  .checkout-container .checkout-left,
  .checkout-container .checkout-right {
    margin: 0 !important;
    padding: 0 !important;
  }
  .checkout-container .checkout-left {
    max-width: 37.4479166667vw;
    width: 100%;
    border-right: unset;
  }
}
@media screen and (min-width: 991px) and (max-width: 1440px) {
  .checkout-container .checkout-left {
    max-width: 50%;
  }
}
@media screen and (min-width: 991px) {
  .checkout-container .checkout-right {
    max-width: 43.2291666667vw;
    width: 100%;
    right: -40px;
  }
}
@media screen and (min-width: 991px) and (max-width: 1440px) {
  .checkout-container .checkout-right {
    right: -20px;
    max-width: calc(50% - 20px);
  }
}
.checkout-container #d_quickcheckout {
  padding-left: 8.6458333333vw;
  padding-right: 8.6458333333vw;
  width: 100%;
}
@media screen and (max-width: 1440px) {
  .checkout-container #d_quickcheckout {
    padding-left: var(--side-padding);
    padding-right: var(--side-padding);
  }
}
@media screen and (max-width: 1370px) {
  .checkout-container #d_quickcheckout {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 660px) {
  .checkout-container #d_quickcheckout {
    padding-left: 0;
    padding-right: 0;
  }
}
.checkout-container .form-horizontal .radio,
.checkout-container .form-horizontal .checkbox,
.checkout-container .form-horizontal .radio-inline,
.checkout-container .form-horizontal .checkbox-inline {
  padding-top: 0;
}
@media (max-width: 991px) {
  .checkout-container .form-horizontal .form-group {
    margin-right: 0;
    margin-left: 0;
  }
}
.checkout-container .well {
  box-shadow: none;
}
@media screen and (max-width: 991px) {
  .checkout-container .well {
    text-align: center;
  }
}
.checkout-container #qc_confirm_order {
  padding: 15px;
  margin-bottom: 50px;
  /* font-family */
  font-family: var(--primary-font), sans-serif;
  /* font-size */
  font-size: 24px;
  font-size: 2.4rem;
  /* font-weight */
  font-weight: 500;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 30px;
  line-height: 3rem;
  /* letter-spacing */
  letter-spacing: 0.16em;
}
@media screen and (max-width: 991px) {
  .checkout-container #qc_confirm_order {
    letter-spacing: 0.22em;
  }
}
.checkout-container #qc_confirm_order:disabled {
  color: var(--input-border-color);
  background-color: var(--white);
  border-color: var(--input-border-color);
  transition: all linear 0.3s;
}
@media (max-width: 991px) {
  .checkout-container #qc_confirm_order {
    padding: 15px;
  }
}
.checkout-container .text-danger {
  margin: 0 !important;
}
.checkout-container button.delete {
  background-color: unset;
  background-image: none;
  background-repeat: no-repeat;
  border: none;
  border-color: unset;
}
.checkout-container .panel {
  border-radius: 0;
  border: none;
  background: none;
  box-shadow: none;
}
@media (max-width: 991px) {
  .checkout-container .panel {
    margin-bottom: 0;
  }
}
.checkout-container .panel .panel-body {
  padding: 15px 0;
}
@media (max-width: 991px) {
  .checkout-container .panel .panel-body {
    padding: 0;
  }
}
.checkout-container .panel .panel-heading {
  background-color: unset;
  border-radius: 0;
  border: none;
  padding: 10px 0;
}
@media (max-width: 991px) {
  .checkout-container .panel .panel-heading {
    padding: 0;
  }
}
.checkout-container #payment_address_heading_heading > i {
  display: none;
}
.checkout-container .btn {
  box-shadow: none !important;
  text-shadow: none !important;
  padding-top: 15px;
  padding-bottom: 15px;
}
.checkout-container #login_view .guest-login-register {
  display: flex;
  flex-wrap: wrap;
}
.checkout-container #cart_view .qc-name a {
  text-decoration: none;
}
.checkout-container #cart_view .qc-coupon .input-group {
  display: inline-flex;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--light-blue);
}
@media screen and (max-width: 991px) {
  .checkout-container #cart_view .qc-coupon .input-group {
    flex-direction: column;
  }
}
.checkout-container #cart_view .qc-coupon .input-group * {
  border-radius: 40px !important;
}
@media screen and (max-width: 991px) {
  .checkout-container #cart_view .qc-coupon .input-group * {
    min-width: 100%;
  }
}
.checkout-container #cart_view .qc-coupon .input-group input {
  width: 100% !important;
  height: unset !important;
  display: inline-flex !important;
  justify-content: center;
  color: var(--secondary-color);
  margin-right: 1.1979166667vw;
}
.checkout-container #cart_view .qc-coupon .input-group span {
  width: auto;
  /* font-family */
  font-family: var(--primary-font), sans-serif;
  /* font-size */
  font-size: 24px;
  font-size: 2.4rem;
  /* font-weight */
  font-weight: 500;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 30px;
  line-height: 3rem;
  /* letter-spacing */
  letter-spacing: 0.16em;
  max-width: 13.3854166667vw !important;
  width: 100% !important;
  height: unset !important;
  display: inline-flex !important;
  justify-content: center;
  color: var(--white);
}
.checkout-container #cart_view .qc-coupon .input-group span button {
  display: flex !important;
  align-items: center;
  justify-content: center;
  max-width: unset !important;
  width: 100% !important;
  padding: unset !important;
  height: 100% !important;
}
.checkout-container #cart_view .qc-coupon .input-group span button:hover {
  background-color: var(--dark-blue);
  border-color: var(--dark-blue);
  color: var(--white) !important;
}
@media screen and (max-width: 991px) {
  .checkout-container #cart_view .qc-coupon .input-group span button {
    margin-top: 16px;
    min-height: 60px;
  }
}
.checkout-container #cart_view .table,
.checkout-container #cart_view .table td {
  border: none;
}
.checkout-container #cart_view .table thead {
  border-bottom: 1px solid var(--input-border-color);
}
.checkout-container #cart_view .qc-coupon {
  margin: 0 0 30px;
}
.checkout-container #cart_view .qc-coupon .col-sm-12 {
  padding: 0;
}
.checkout-container #cart_view .qc-coupon #coupon,
.checkout-container #cart_view .qc-coupon #confirm_coupon {
  height: 40px;
}
.checkout-container #cart_view .qc-coupon #coupon {
  width: 100%;
}
.checkout-container #cart_view .qc-coupon #confirm_coupon {
  display: block;
  max-width: 135px;
  width: 100%;
  padding: 5px 20px;
  height: 40px;
}
.checkout-container #cart_view .qc-totals {
  padding-left: 15px;
  padding-right: 15px;
}
.checkout-container #cart_view .qc-totals .row {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}
.checkout-container #cart_view .qc-totals .row div,
.checkout-container #cart_view .qc-totals .row label {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 16px;
  font-size: 1.6rem;
  /* font-weight */
  font-weight: 400;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 16px;
  line-height: 1.6rem;
  /* letter-spacing */
  color: var(--dark-blue);
  letter-spacing: 0.01em;
}
.checkout-container #cart_view .qc-totals .row label {
  text-align: left !important;
}
.checkout-container #cart_view .qc-totals .row:last-child {
  border-bottom: 1px solid var(--light-blue);
  text-transform: uppercase;
}
.checkout-container #cart_view .qc-totals .row:last-child div,
.checkout-container #cart_view .qc-totals .row:last-child label {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 20px;
  font-size: 2rem;
  /* font-weight */
  font-weight: 500;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 30px;
  line-height: 3rem;
  /* letter-spacing */
  color: var(--gold);
}
@media screen and (max-width: 991px) {
  .checkout-container #cart_view .qc-totals .row:last-child div,
  .checkout-container #cart_view .qc-totals .row:last-child label {
    /* font-family */
    /* font-size */
    /* font-weight */
    font-weight: 400;
    /* font-style */
    /* line-height */
    /* letter-spacing */
    letter-spacing: 0.01em;
  }
}
.checkout-container #cart_view .qc-checkout-product table.table tbody td {
  vertical-align: middle;
}
.checkout-container #payment_address_form,
.checkout-container #shipping_address_form {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.checkout-container #payment_address_form > div,
.checkout-container #shipping_address_form > div {
  flex-basis: calc(50% + 15px);
}
.checkout-container #payment_address_newsletter_input,
.checkout-container #payment_address_customer_group_id_input,
.checkout-container #payment_address_custom_field\.account\.1_input,
.checkout-container #payment_address_shipping_address_input,
.checkout-container #payment_address_agree_input,
.checkout-container #payment_address_heading_heading {
  flex-basis: calc(100% + 30px) !important;
}
.checkout-container #payment_address_customer_group_id_input .col-xs-12:first-child {
  display: none;
}
.checkout-container #confirm_form {
  margin-bottom: clamp(30px, 3.125vw, 60px);
}
.checkout-container #confirm_comment {
  min-height: 140px;
  resize: vertical;
}
.checkout-container #payment_address_newsletter_input label,
.checkout-container #payment_address_shipping_address_input label,
.checkout-container #payment_address_agree_input label,
.checkout-container #payment_address_heading_heading label {
  text-align: left;
}
.checkout-container #payment_address_heading_heading {
  margin-bottom: 30px;
}
@media (max-width: 991px) {
  .checkout-container #payment_address_heading_heading {
    margin-bottom: 10px;
  }
}
@media (max-width: 991px) {
  .checkout-container #payment_address_form > div,
  .checkout-container #shipping_address_form > div {
    flex-basis: calc(100% + 30px) !important;
  }
  .checkout-container #payment_address_form > div > div,
  .checkout-container #shipping_address_form > div > div,
  .checkout-container #confirm_comment_input > div,
  .checkout-container #confirm_agree_input > div {
    padding: 0;
  }
}

.page_checkout.page_cart .info-row .alert {
  border-radius: 40px;
  background: transparent;
  color: var(--gold);
  border-color: var(--gold);
}
.page_checkout.page_cart .info-row .alert button {
  font-size: inherit;
  line-height: 1;
}

body.page_checkout.checkout-container {
  background-color: var(--light-blue);
  display: flex;
  flex-direction: column;
}
body.page_checkout.checkout-container div[id*=error] {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 12px;
  font-size: 1.2rem;
  /* font-weight */
  font-weight: 500;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 12px;
  line-height: 1.2rem;
  /* letter-spacing */
  padding: 5px 25px 0 25px;
}
@media (max-width: 991px) {
  body.page_checkout.checkout-container .radio label {
    /* font-family */
    /* font-size */
    /* font-weight */
    font-weight: 500;
    /* font-style */
    /* line-height */
    /* letter-spacing */
  }
}
body.page_checkout.checkout-container select {
  background-color: transparent;
  color: var(--secondary-color);
  -webkit-appearance: none;
  cursor: pointer;
  background-image: url("../../../icons/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: 90% 50%;
  background-color: transparent;
  padding-left: 30px;
  padding-right: 70px;
}
body.page_checkout.checkout-container #d_quickcheckout {
  margin-bottom: 2.0833333333vw;
  order: 3;
}
body.page_checkout.checkout-container header {
  order: 1;
}
body.page_checkout.checkout-container #search {
  order: 2;
}
body.page_checkout.checkout-container footer {
  margin-top: 14.1666666667vw;
  order: 4;
}

.top-line, #shipping_method,
#payment_method,
#payment_view {
  position: relative;
}
.top-line:before, #shipping_method:before,
#payment_method:before,
#payment_view:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 33.3333333333vw;
  height: 1px;
  background-color: var(--light-blue);
}
@media screen and (max-width: 991px) {
  .top-line:before, #shipping_method:before,
  #payment_method:before,
  #payment_view:before {
    width: calc(100% - 10px);
  }
}

#login_view,
#payment_address,
#shipping_address,
#shipping_method,
#payment_method,
#payment_view {
  background-color: var(--white);
  padding-top: 33px;
  padding-left: 41px;
  padding-right: 41px;
  width: 100%;
  float: left;
}
@media screen and (max-width: 1440px) {
  #login_view,
  #payment_address,
  #shipping_address,
  #shipping_method,
  #payment_method,
  #payment_view {
    padding-left: 15px;
    padding-right: 15px;
  }
}

#payment_address {
  padding-top: 0;
}

#cart_view,
#confirm_view {
  background-color: var(--white);
  padding-top: 33px;
  padding-left: 34px;
  padding-right: 34px;
  width: 100%;
  float: left;
}
@media screen and (max-width: 1440px) {
  #cart_view,
  #confirm_view {
    padding-left: 15px;
    padding-right: 15px;
  }
}

#cart_view,
#login_view {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

#confirm_view,
#payment_view {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

#payment_view .header-2 {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 22px;
  font-size: 2.2rem;
  /* font-weight */
  font-weight: 700;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 22px;
  line-height: 2.2rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
  text-transform: initial;
}
#payment_view > p {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 16px;
  font-size: 1.6rem;
  /* font-weight */
  font-weight: 400;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 16px;
  line-height: 1.6rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
  color: var(--gold);
}
@media screen and (max-width: 991px) {
  #payment_view > p {
    margin-bottom: 30px;
  }
}
#payment_view > .well {
  background-color: var(--light-brown);
  border-color: var(--light-brown);
  border-radius: unset;
}
@media screen and (max-width: 991px) {
  #payment_view > .well {
    background-color: #F1F1F1;
    border-color: #F1F1F1;
  }
}
#payment_view > .well > p:first-child {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 22px;
  font-size: 2.2rem;
  /* font-weight */
  font-weight: 700;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 22px;
  line-height: 2.2rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
}
@media screen and (max-width: 991px) {
  #payment_view > .well > p:first-child {
    /* font-family */
    /* font-size */
    font-size: 14px;
    font-size: 1.4rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    line-height: 17px;
    line-height: 1.7rem;
    /* letter-spacing */
  }
}
#payment_view > .well > p:last-child {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 16px;
  font-size: 1.6rem;
  /* font-weight */
  font-weight: 400;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 16px;
  line-height: 1.6rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
}
@media screen and (max-width: 991px) {
  #payment_view > .well > p:last-child {
    /* font-family */
    /* font-size */
    font-size: 14px;
    font-size: 1.4rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    line-height: 22px;
    line-height: 2.2rem;
    /* letter-spacing */
  }
}

@media screen and (max-width: 991px) {
  #cart_view {
    margin-top: -1px;
    border-top-left-radius: unset;
    border-top-right-radius: unset;
  }
  #payment_view {
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
  }
}
#login_view .login-btn-group {
  margin-bottom: unset !important;
}

.guest-login-register > * {
  flex: 1;
  flex-basis: unset !important;
}
.guest-login-register > *:not(:last-child) {
  margin-right: 19px;
}
@media screen and (max-width: 1370px) {
  .guest-login-register > *:not(:last-child) {
    margin-right: 5px;
  }
}
.guest-login-register > * button,
.guest-login-register > * label {
  /* font-family */
  font-family: var(--primary-font), sans-serif;
  /* font-size */
  font-size: 24px;
  font-size: 2.4rem;
  /* font-weight */
  font-weight: 500;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 30px;
  line-height: 3rem;
  /* letter-spacing */
  letter-spacing: 0.16em;
  background-color: transparent;
  color: var(--dark-blue);
  border-color: var(--dark-blue);
}
@media screen and (max-width: 1370px) {
  .guest-login-register > * button,
  .guest-login-register > * label {
    /* font-family */
    /* font-size */
    font-size: 20px;
    font-size: 2rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    line-height: 22px;
    line-height: 2.2rem;
    /* letter-spacing */
    letter-spacing: 0.01em;
  }
}
.guest-login-register > * button:hover,
.guest-login-register > * label:hover {
  background-color: var(--dark-blue) !important;
  color: var(--white) !important;
  border-color: var(--dark-blue) !important;
}

#payment_address_heading_heading,
.panel-title {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 22px;
  font-size: 2.2rem;
  /* font-weight */
  font-weight: 700;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 22px;
  line-height: 2.2rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
  color: var(--dark-blue);
  text-transform: unset;
}
@media screen and (max-width: 991px) {
  #payment_address_heading_heading,
  .panel-title {
    /* font-family */
    /* font-size */
    font-size: 20px;
    font-size: 2rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    line-height: 30px;
    line-height: 3rem;
    /* letter-spacing */
    padding-bottom: 8px;
  }
}

#payment_address_heading_heading {
  margin-top: 50px;
}

.qc-cart thead tr,
.qc-cart tbody tr {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}
@media screen and (max-width: 991px) {
  .qc-cart thead tr,
  .qc-cart tbody tr {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 991px) {
  .qc-cart thead tr td.qc-quantity,
  .qc-cart tbody tr td.qc-quantity {
    order: 5;
    min-width: 100%;
    margin-left: 90px;
  }
}
.qc-cart thead tr td.qc-name,
.qc-cart tbody tr td.qc-name {
  flex: 1;
}
@media screen and (max-width: 991px) {
  .qc-cart thead tr td.qc-name,
  .qc-cart tbody tr td.qc-name {
    min-width: 60.6060606061vw;
  }
}
@media screen and (max-width: 590px) {
  .qc-cart thead tr td.qc-name,
  .qc-cart tbody tr td.qc-name {
    min-width: 50vw;
  }
}
@media screen and (max-width: 460px) {
  .qc-cart thead tr td.qc-name,
  .qc-cart tbody tr td.qc-name {
    min-width: 25vw;
  }
}
@media screen and (max-width: 350px) {
  .qc-cart thead tr td.qc-name,
  .qc-cart tbody tr td.qc-name {
    min-width: 28.5714285714vw;
  }
}
.qc-cart thead tr td:not(.qc-name):not(.qc-quantity):not(.qc-total),
.qc-cart tbody tr td:not(.qc-name):not(.qc-quantity):not(.qc-total) {
  display: none;
}
.qc-cart thead {
  border-bottom: unset !important;
}
.qc-cart thead tr {
  border-radius: 40px;
  padding: 22px 31px;
  background-color: var(--dark-blue);
  color: var(--white);
}
@media screen and (max-width: 1025px) and (min-width: 992px) {
  .qc-cart thead tr {
    max-width: 43.9024390244vw;
  }
}
.qc-cart thead tr td {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 16px;
  font-size: 1.6rem;
  /* font-weight */
  font-weight: 400;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 0px;
  line-height: 0rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
  text-transform: uppercase;
}
.qc-cart thead tr td.qc-quantity {
  margin-right: 2.6041666667vw;
}
@media screen and (max-width: 991px) {
  .qc-cart thead tr td.qc-quantity {
    display: none;
  }
}
@media screen and (max-width: 1370px) and (min-width: 992px) {
  .qc-cart thead tr td.qc-name {
    white-space: nowrap;
  }
}
.qc-cart tbody tr {
  border-bottom: 1px solid var(--light-blue);
  padding: 30px 0;
}
.qc-cart tbody tr td.qc-image {
  padding: 0;
  display: unset !important;
  max-width: 78px;
}
.qc-cart tbody tr td.qc-image a {
  text-decoration: none !important;
}
.qc-cart tbody tr td.qc-image img {
  display: inline-flex;
  min-width: 80px;
}
.qc-cart tbody tr td.qc-total, .qc-cart tbody tr td.qc-name {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 16px;
  font-size: 1.6rem;
  /* font-weight */
  font-weight: 400;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 16px;
  line-height: 1.6rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
  padding-left: 3.0208333333vw;
}
@media screen and (max-width: 1536px) {
  .qc-cart tbody tr td.qc-total, .qc-cart tbody tr td.qc-name {
    padding-left: 10px;
  }
}
@media screen and (max-width: 991px) {
  .qc-cart tbody tr td.qc-total, .qc-cart tbody tr td.qc-name {
    padding-left: 10px;
  }
}
.qc-cart tbody tr td.qc-name {
  max-width: 450px;
  margin-right: auto;
}
.qc-cart tbody tr td.qc-name, .qc-cart tbody tr td.qc-quantity, .qc-cart tbody tr td.qc-total {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}
.qc-cart tbody tr td.qc-total {
  padding-left: 25px;
  padding-right: 45px;
  text-align: right;
}
@media screen and (min-width: 992px) {
  .qc-cart tbody tr td.qc-total {
    min-width: 130px;
  }
}
@media screen and (max-width: 991px) {
  .qc-cart tbody tr td.qc-total {
    /* font-family */
    font-family: var(--secondary-font), sans-serif;
    /* font-size */
    font-size: 16px;
    font-size: 1.6rem;
    /* font-weight */
    font-weight: 500;
    /* font-style */
    font-style: normal;
    /* line-height */
    line-height: 22px;
    line-height: 2.2rem;
    /* letter-spacing */
    letter-spacing: 0.01em;
    padding-left: 10px;
    padding-right: 40px;
  }
}
.qc-cart tbody tr td.qc-quantity {
  border: 1px solid var(--dark-blue) !important;
  border-radius: 100px;
  width: unset !important;
  max-width: 15%;
  margin-right: 2.34375vw;
  max-width: 68px;
  height: 40px;
  align-self: center;
}
.qc-cart tbody tr td.qc-quantity .input-group {
  position: relative;
  min-width: 50px;
}
.qc-cart tbody tr td.qc-quantity .input-group .input-group-btn button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.qc-cart tbody tr td.qc-quantity .input-group .input-group-btn button:not(.delete) {
  padding: 0;
}
.qc-cart tbody tr td.qc-quantity .input-group .input-group-btn button:not(.delete) svg {
  height: 7px;
  width: 10px;
}
.qc-cart tbody tr td.qc-quantity .input-group .input-group-btn .increase,
.qc-cart tbody tr td.qc-quantity .input-group .input-group-btn .decrease {
  background: transparent;
  border: none;
  color: var(--dark-blue);
}
.qc-cart tbody tr td.qc-quantity .input-group .input-group-btn .delete {
  position: absolute;
}
@media screen and (min-width: 992px) {
  .qc-cart tbody tr td.qc-quantity .input-group .input-group-btn .delete {
    bottom: -35px;
    right: -20px;
  }
}
.qc-cart tbody tr td.qc-quantity .input-group .input-group-btn .delete:active, .qc-cart tbody tr td.qc-quantity .input-group .input-group-btn .delete:focus, .qc-cart tbody tr td.qc-quantity .input-group .input-group-btn .delete:hover {
  background-color: transparent !important;
  border-color: transparent !important;
}
@media screen and (max-width: 991px) {
  .qc-cart tbody tr td.qc-quantity .input-group .input-group-btn .delete {
    top: 0;
    right: -112px;
  }
}
.qc-cart tbody tr td.qc-quantity .input-group input {
  padding: 0;
  border: none;
  min-width: 10px !important;
  color: var(--dark-blue);
}
.qc-cart tbody tr td.qc-quantity .delete {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 16px;
  font-size: 1.6rem;
  /* font-weight */
  font-weight: 400;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 16px;
  line-height: 1.6rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
  color: var(--dark-blue);
}
.qc-cart tbody tr td.qc-quantity .delete svg {
  max-width: 8px;
}

.delete-context:after, .qc-cart tbody tr td.qc-quantity .delete:after {
  content: "Delete";
  padding-left: 5px;
  color: #898989;
}
@media screen and (max-width: 991px) {
  .delete-context:after, .qc-cart tbody tr td.qc-quantity .delete:after {
    color: var(--gold);
  }
}
.delete-context svg line, .qc-cart tbody tr td.qc-quantity .delete svg line {
  stroke: #898989;
}
@media screen and (max-width: 991px) {
  .delete-context svg line, .qc-cart tbody tr td.qc-quantity .delete svg line {
    stroke: var(--gold);
  }
}

body.lang_el .delete-context:after, body.lang_el .qc-cart tbody tr td.qc-quantity .delete:after, .qc-cart tbody tr td.qc-quantity body.lang_el .delete:after {
  content: "Διαγραφή";
}

._page-title {
  /* font-family */
  font-family: var(--primary-font), sans-serif;
  /* font-size */
  font-size: 60px;
  font-size: 6rem;
  /* font-weight */
  font-weight: 500;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 74px;
  line-height: 7.4rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
  color: var(--dark-blue);
}
@media screen and (max-width: 991px) {
  ._page-title {
    /* font-family */
    /* font-size */
    font-size: 24px;
    font-size: 2.4rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    line-height: 30px;
    line-height: 3rem;
    /* letter-spacing */
    letter-spacing: 0.22em;
    text-align: center;
    margin-bottom: 25px;
  }
}

.header-replacement {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 34px;
  padding-bottom: 65px;
}
@media screen and (max-width: 991px) {
  .header-replacement {
    flex-direction: column;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 28px;
  }
}
.header-replacement > * {
  width: 33.333%;
  display: flex;
}
.header-replacement > *._back a {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 20px;
  font-size: 2rem;
  /* font-weight */
  font-weight: 400;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 20px;
  line-height: 2rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
}
.header-replacement > *._help a {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 20px;
  font-size: 2rem;
  /* font-weight */
  font-weight: 400;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 20px;
  line-height: 2rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
}
@media screen and (max-width: 991px) {
  .header-replacement > *._help a {
    /* font-family */
    /* font-size */
    font-size: 16px;
    font-size: 1.6rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    /* letter-spacing */
  }
}
@media screen and (max-width: 991px) {
  .header-replacement > * {
    width: auto;
  }
  .header-replacement > *._back {
    order: 2;
    margin-top: 17px;
  }
  .header-replacement > *._logo {
    order: 1;
  }
  .header-replacement > *._help {
    order: 3;
    margin-top: 24px;
  }
}
.header-replacement > *._logo {
  justify-content: center;
}
.header-replacement > *._help {
  justify-content: flex-end;
}

[class*=col-] {
  min-height: 0 !important;
}

@media screen and (max-width: 991px) {
  #payment_address .panel-title {
    border-bottom: 1px solid var(--light-blue);
    padding-top: 30px;
  }
  #payment_address_zone_id_input {
    padding-bottom: 40px;
  }
  #payment_view,
  #payment_method_form {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  #payment_view {
    padding-left: 10px;
    padding-right: 10px;
  }
}
#account-order a.btn-default {
  display: block;
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
}

/* cart page */
#checkout-cart {
  padding: 15px 0;
  background-color: var(--light-brown);
}
#checkout-cart .mini-cart-total {
  font-size: 1.4rem;
  text-align: right;
}
#checkout-cart .mini-cart-checkout {
  background-color: transparent;
  justify-content: flex-end;
}
#checkout-cart .mini-cart-checkout a {
  border-radius: 40px;
  font-size: 1.3rem;
  line-height: 2.4rem;
  font-weight: 600;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  text-align: center;
  padding: 15px;
  align-self: flex-end;
  background-color: var(--primary-color);
  max-width: 250px;
  color: var(--white);
  border: 1px solid var(--primary-color);
  width: 100%;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (max-width: 660px) {
  #checkout-cart .mini-cart-checkout a {
    max-width: 100%;
  }
}
#checkout-cart .mini-cart-checkout a:hover {
  background-color: transparent;
  color: var(--primary-color);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#checkout-cart .cart-page .mini-cart-total {
  display: flex;
  flex-basis: 100%;
  margin-top: 30px;
  justify-content: flex-end;
}
#checkout-cart .cart-page .mini-cart-total .col-total {
  width: 100%;
  max-width: 250px;
  font-size: 1.3rem;
  line-height: 2.4rem;
  font-weight: 600;
  letter-spacing: 0.022em;
  background-color: #e4e4e4;
  color: var(--primary-color);
  text-align: center;
  text-transform: uppercase;
  padding: 25px;
  margin-bottom: 15px;
  border-radius: 40px;
}
@media (max-width: 660px) {
  #checkout-cart .cart-page .mini-cart-total .col-total {
    max-width: 100%;
  }
}
#checkout-cart .cart-page .mini-cart-total .col-total:last-child {
  display: none;
}
#checkout-cart .cart-page .mini-cart-total .cart-breakdown {
  width: 100%;
  margin-bottom: 10px;
}
#checkout-cart .cart-page ul li {
  border-bottom: 1px solid #ddd;
}
#checkout-cart .cart-page ul li + li {
  margin-top: 10px;
}
#checkout-cart .cart-page ul li .mini-cart-products {
  padding: 20px 0;
  align-items: center;
  justify-content: space-between;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-img {
  flex-basis: 15%;
}
@media (max-width: 991px) {
  #checkout-cart .cart-page ul li .mini-cart-products .product-img {
    flex-basis: 25%;
  }
}
#checkout-cart .cart-page ul li .mini-cart-products .product-img a {
  display: inline-block;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-img a .img-thumbnail {
  border: none;
  background-color: transparent;
}
#checkout-cart .cart-page ul li .mini-cart-products .prd-item {
  flex-basis: 55%;
}
@media (max-width: 660px) {
  #checkout-cart .cart-page ul li .mini-cart-products .prd-item {
    flex-basis: 70%;
  }
}
#checkout-cart .cart-page ul li .mini-cart-products .prd-item a {
  display: inline-block;
  margin-bottom: 0;
}
@media (max-width: 660px) {
  #checkout-cart .cart-page ul li .mini-cart-products .prd-item a {
    font-size: 1.4rem;
  }
}
#checkout-cart .cart-page ul li .mini-cart-products .prd-item .prd-description, #checkout-cart .cart-page ul li .mini-cart-products .prd-item .prd-price {
  margin-bottom: 0;
}
#checkout-cart .cart-page ul li .mini-cart-products .prd-item .product-quantity.mobile-view {
  display: none;
}
@media (max-width: 660px) {
  #checkout-cart .cart-page ul li .mini-cart-products .prd-item .product-quantity.mobile-view {
    display: block;
  }
}
#checkout-cart .cart-page ul li .mini-cart-products .product-remove {
  align-self: start;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-remove .remove-cart-item {
  background: transparent;
  border: none;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-remove .remove-cart-item .delete-icon {
  display: block;
  width: 17px;
  height: 17px;
}
@media (max-width: 991px) {
  #checkout-cart .cart-page ul li .mini-cart-products .product-remove .remove-cart-item .delete-icon {
    width: 12px;
    height: 12px;
  }
}
#checkout-cart .cart-page ul li .mini-cart-products .product-remove .remove-cart-item .delete-icon svg {
  display: block;
  width: 17px;
  height: 17px;
}
@media (max-width: 991px) {
  #checkout-cart .cart-page ul li .mini-cart-products .product-remove .remove-cart-item .delete-icon svg {
    width: 12px;
    height: 12px;
  }
}
#checkout-cart .cart-page ul li .mini-cart-products .product-remove .remove-cart-item .delete-icon svg .st0 {
  fill: none;
  stroke: #8c8c8c;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-quantity {
  flex-basis: 20%;
  margin-right: 15px;
}
@media (max-width: 660px) {
  #checkout-cart .cart-page ul li .mini-cart-products .product-quantity.desktop-view {
    display: none;
  }
}
#checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field {
  display: block;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity {
  display: flex;
  justify-content: space-between;
  flex-basis: 60%;
}
@media (max-width: 991px) {
  #checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity {
    flex-basis: 100%;
  }
}
@media (max-width: 660px) {
  #checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity {
    max-width: 165px;
    margin-top: 20px;
  }
}
#checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity button.btn.btn-primary {
  background: no-repeat;
  color: var(--primary-color);
  border: 0;
  text-shadow: none;
  box-shadow: none;
  padding: 0;
  text-decoration: underline;
  display: inline-block;
  position: relative;
  margin-left: 15px;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity .quantity-wrapper {
  position: relative;
  width: 100%;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity .quantity-wrapper input.form-control {
  border: 0;
  box-shadow: none;
  border-top: 1px solid var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
  text-align: center;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity .quantity-wrapper .quantity-nav {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  justify-content: space-between;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity .quantity-wrapper .quantity-nav .quantity-button {
  cursor: pointer;
  padding: 8px 20px;
}
#checkout-cart .product-grid, #checkout-cart .choosegift, #checkout-cart .giftavailable {
  display: none;
}

.about-container {
  --side-padding: 120px;
  background-color: var(--light-brown);
  padding-top: 90px;
}
@media (max-width: 1366px) {
  .about-container {
    --side-padding: 80px;
  }
}
@media (max-width: 1140px) {
  .about-container {
    --side-padding: 60px;
  }
}
@media (max-width: 767px) {
  .about-container {
    --side-padding: 10px;
  }
}
@media (max-width: 767px) {
  .about-container {
    padding-top: 40px;
  }
}
.about-container .title-container {
  margin: 0 auto 60px;
}
@media (max-width: 767px) {
  .about-container .title-container {
    margin: 0 auto 20px;
  }
}
.about-container .title-container h1,
.about-container .title-container h2 {
  margin: 0;
}
.about-container .about-hero {
  padding: 0 var(--side-padding);
  margin: 0 auto 65px;
}
@media (max-width: 767px) {
  .about-container .about-hero {
    padding: 0;
    margin: 0 auto 30px;
  }
}
.about-container .about-hero img {
  margin: 0 auto;
}
@media (max-width: 767px) {
  .about-container .about-hero img {
    width: 100%;
  }
}
.about-container .secret-recipe {
  align-items: center;
  margin: 0 auto 55px;
}
.about-container .secret-recipe img {
  margin: 0 auto 45px;
  max-width: clamp(400px, 42.1875vw, 810px);
  width: 100%;
}
@media (max-width: 1280px) {
  .about-container .secret-recipe .header-1 {
    font-size: 4rem;
  }
}
@media (max-width: 767px) {
  .about-container .secret-recipe {
    padding: 0 30px;
  }
  .about-container .secret-recipe .header-1 {
    font-size: 3.6rem;
  }
}
.about-container .showcase-container .top-row {
  justify-content: space-between;
  margin: 0 0 15px;
}
.about-container .showcase-container .top-row .left,
.about-container .showcase-container .top-row .right {
  max-width: clamp(500px, 34.6354vw, 665px);
  width: 100%;
  flex: 1;
}
@media (max-width: 1540px) {
  .about-container .showcase-container .top-row .left,
  .about-container .showcase-container .top-row .right {
    max-width: 450px;
  }
}
@media (max-width: 1280px) {
  .about-container .showcase-container .top-row .left,
  .about-container .showcase-container .top-row .right {
    max-width: 380px;
  }
}
@media (max-width: 1024px) {
  .about-container .showcase-container .top-row .left,
  .about-container .showcase-container .top-row .right {
    max-width: 330px;
  }
}
.about-container .showcase-container .top-row .left {
  position: relative;
  top: 140px;
  z-index: 1;
}
.about-container .showcase-container .top-row .middle {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1366px) {
  .about-container .showcase-container .top-row .middle {
    max-width: 220px;
  }
}
.about-container .showcase-container .bottom-row {
  display: flex;
  align-items: flex-end;
  padding: 40px clamp(180px, 14.3229vw, 275px) 90px clamp(200px, 18.2292vw, 350px);
  width: 100%;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .about-container .showcase-container .bottom-row {
    padding: 40px 100px 90px;
  }
}
.about-container .showcase-container .bottom-row .left {
  margin: 0 150px 0 0;
  padding-bottom: 135px;
}
@media (max-width: 1280px) {
  .about-container .showcase-container .bottom-row .left {
    margin: 0 100px 0 0;
    padding-bottom: 80px;
  }
}
@media (max-width: 991px) {
  .about-container .showcase-container .top-row .middle {
    padding: 0 15px;
  }
}
@media (max-width: 767px) {
  .about-container .showcase-container .bottom-row {
    display: none;
  }
  .about-container .showcase-container .top-row {
    margin: 0;
  }
  .about-container .showcase-container .top-row .left {
    max-width: 100%;
    width: 100%;
    position: relative;
    top: unset;
  }
  .about-container .showcase-container .top-row .left img {
    width: 100%;
  }
  .about-container .showcase-container .top-row .right,
  .about-container .showcase-container .top-row .middle {
    display: none;
  }
}
.about-container .timeline-container {
  background-color: var(--light-blue);
  padding: clamp(100px, 13.0208vw, 250px) clamp(100px, 12.5vw, 240px) clamp(100px, 11.1979vw, 215px);
}
@media (max-width: 1540px) {
  .about-container .timeline-container {
    padding: 150px 120px;
  }
}
@media (max-width: 1440px) {
  .about-container .timeline-container {
    padding: 120px 80px;
  }
}
@media (max-width: 1280px) {
  .about-container .timeline-container {
    padding: 80px 80px 120px;
  }
}
@media (max-width: 1140px) {
  .about-container .timeline-container {
    padding: 80px 40px 120px;
  }
}
@media (max-width: 767px) {
  .about-container .timeline-container {
    padding: 30px 10px 100px;
  }
}
.about-container .timeline-container .timeline-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto 155px;
}
@media (max-width: 1140px) {
  .about-container .timeline-container .timeline-wrapper {
    margin: 0 auto 100px;
  }
}
@media (max-width: 1024px) {
  .about-container .timeline-container .timeline-wrapper {
    margin: 0 auto;
    flex-direction: column;
  }
}
.about-container .timeline-container .timeline-item {
  flex-basis: 45%;
  margin-bottom: 80px;
  padding-left: 85px;
  padding-top: 45px;
  position: relative;
}
@media (max-width: 1140px) {
  .about-container .timeline-container .timeline-item {
    flex-basis: unset;
    width: 100%;
    margin-bottom: 35px;
    padding-left: 0;
    padding-top: 0;
  }
}
.about-container .timeline-container .timeline-item .date {
  position: absolute;
  left: 0;
  top: 0;
  transform: rotate(180deg);
  writing-mode: vertical-lr;
  text-orientation: mixed;
}
.about-container .timeline-container .timeline-item .date .header-1 {
  margin: 0;
}
@media (max-width: 1140px) {
  .about-container .timeline-container .timeline-item .date {
    position: relative;
    transform: unset;
    writing-mode: unset;
    text-orientation: unset;
  }
  .about-container .timeline-container .timeline-item .date .header-1 {
    margin: 0 0 10px;
    text-align: center;
  }
}
.about-container .timeline-container .timeline-item .content {
  max-width: 510px;
  width: 100%;
}
@media (max-width: 1280px) {
  .about-container .timeline-container .timeline-item .content {
    max-width: 450px;
  }
}
.about-container .timeline-container .timeline-item .content p:last-child {
  margin-bottom: 0px;
}
.about-container .timeline-container .timeline-item .content p:not(:last-child) {
  margin-bottom: 25px;
}
@media (max-width: 1140px) {
  .about-container .timeline-container .timeline-item .content {
    max-width: unset;
  }
}
.about-container .timeline-container .timeline-item.year-1983 {
  margin-top: 90px;
}
@media (max-width: 1140px) {
  .about-container .timeline-container .timeline-item.year-1983 {
    margin-top: 0;
  }
}
.about-container .timeline-container .timeline-item.decor {
  padding-top: 0;
  padding-left: 0;
  margin-bottom: 0;
  position: relative;
  left: -100px;
}
@media (max-width: 1280px) {
  .about-container .timeline-container .timeline-item.decor {
    left: 0;
  }
}
@media (max-width: 1140px) {
  .about-container .timeline-container .timeline-item.decor {
    margin-bottom: 35px;
  }
}
.about-container .timeline-container .timeline-item.decor .chef-container {
  width: 100%;
}
@media (max-width: 1140px) {
  .about-container .timeline-container .timeline-item.decor .chef-container {
    max-width: 600px;
    margin: 0 auto;
  }
}
@media (max-width: 1024px) {
  .about-container .timeline-container .timeline-item.decor .chef-container {
    max-width: 400px;
  }
}
.about-container .timeline-container .timeline-item.decor .chef-container svg {
  overflow: visible;
}
.about-container .timeline-container .timeline-item.decor .chef-container #tv,
.about-container .timeline-container .timeline-item.decor .chef-container #cake1,
.about-container .timeline-container .timeline-item.decor .chef-container #cake2 {
  transform-origin: center;
}
.about-container .timeline-container .timeline-item.decor .chef-container #tv {
  animation: float-up 6s ease-in-out infinite;
}
.about-container .timeline-container .timeline-item.decor .chef-container #cake1 {
  animation: float-right 4.5s ease-in-out infinite;
}
.about-container .timeline-container .timeline-item.decor .chef-container #cake2 {
  animation: float-left 3s ease-in-out infinite;
}
.about-container .timeline-container .timeline-item.year-2016 {
  flex-basis: 100%;
}
.about-container .timeline-container .timeline-item.year-2022 {
  margin-top: -180px;
}
@media (max-width: 1280px) {
  .about-container .timeline-container .timeline-item.year-2022 {
    margin-top: -240px;
  }
}
@media (max-width: 1140px) {
  .about-container .timeline-container .timeline-item.year-2022 {
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .about-container .timeline-container .timeline-item.year-2022 {
    margin-bottom: 0;
  }
}
.about-container .timeline-container .crete-logo {
  position: relative;
  display: flex;
  justify-content: center;
}
.about-container .timeline-container .crete-logo .spinning-text {
  max-width: 264px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 1024px) {
  .about-container .timeline-container .crete-logo {
    display: none;
  }
}
.about-container .footer-banner {
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 175px var(--side-padding) 135px;
}
@media (max-width: 1540px) {
  .about-container .footer-banner {
    padding: clamp(100px, 9.74vw, 150px) clamp(130px, 12.987vw, 200px);
  }
}
@media (max-width: 660px) {
  .about-container .footer-banner {
    padding: 60px var(--side-padding);
  }
}
.about-container .footer-banner .banner {
  position: relative;
}
.about-container .footer-banner .banner-moto {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

@keyframes float-up {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes float-right {
  0% {
    transform: translateX(0px) rotate(0deg);
  }
  50% {
    transform: translateX(3px) rotate(5deg);
  }
  100% {
    transform: translateX(0px) rotate(0deg);
  }
}
@keyframes float-left {
  0% {
    transform: translateX(0px) rotate(0deg);
  }
  50% {
    transform: translateX(-5px) rotate(-3deg);
  }
  100% {
    transform: translateX(0px) rotate(0deg);
  }
}
/*========================================================*/
/*** CATEGORY ***/
/*========================================================*/
.breadcrumb {
  text-align: center;
  margin: 0;
  padding: 30px 10px 15px 10px;
  border: none;
  background-color: transparent;
}
.breadcrumb li {
  padding: 0;
}
.breadcrumb li a {
  font-family: var(--secondary-font), sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.01em;
  margin: 0 0 10px;
}
.breadcrumb li::after {
  content: "/";
  display: inline-block;
  margin: 0 7px 0 10px;
  font-family: var(--primary-font), sans-serif;
  font-size: 2rem;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: var(--gold);
}
.breadcrumb li:last-child::after {
  display: none;
}
.breadcrumb li + li:before {
  display: none;
}

.flex-row.categories-choices {
  justify-content: space-between;
  align-items: center;
  padding: 5px 0 max(40px, 5.46875vw);
}

.product-col:nth-child(4n+1) {
  clear: both;
}

.products-block .product-thumb {
  cursor: pointer;
  position: relative;
  overflow: initial;
}

.product-thumb {
  border: none;
}

.category-info h2 {
  text-align: center;
  font-size: 2.4rem;
  line-height: 3rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  margin: 0 0 25px;
}

.category-info .category-description {
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
  text-align: center;
  font-size: 1.3rem;
  line-height: 2.3rem;
  font-weight: 400;
  letter-spacing: 0.025em;
}

.category-info p {
  text-align: center;
  font-size: 1.3rem;
  line-height: 2.3rem;
  font-weight: 400;
  letter-spacing: 0.025em;
}

.pagination > li > a,
.pagination > li > span {
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: 400;
  color: #000000;
  background-color: transparent;
  border: none;
  padding: 12px;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  color: #000000;
  background-color: #eaeaea;
  border-color: #eaeaea;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  font-size: 1.4rem;
  line-height: 1.2rem;
  font-weight: 800;
  letter-spacing: 0.025em;
  color: #000;
  background-color: transparent;
  border-color: transparent;
}

.category-pagination span.arrow-left-start {
  display: block;
  content: "";
  background-image: url("../../../icons/arrow-left-first.svg");
  width: 12px;
  height: 12px;
  background-position: center;
}

.category-pagination span.arrow-right-end {
  display: block;
  content: "";
  background-image: url("../../../icons/arrow-right-last.svg");
  width: 12px;
  height: 12px;
  background-position: center;
}

.category-pagination span.arrow-next {
  display: block;
  content: "";
  background-image: url("../../../icons/arrow-next.svg");
  width: 12px;
  height: 12px;
  background-position: center;
}

.category-pagination span.arrow-prev {
  display: block;
  content: "";
  background-image: url("../../../icons/arrow-prev.svg");
  width: 12px;
  height: 12px;
  background-position: center;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.product-thumb .price-old {
  font-weight: 400;
  color: #000000;
  margin-left: 0;
}

.price-tax {
  display: none;
}

.product-thumb .price-new {
  font-weight: 800;
  color: #de0000;
  margin-left: 5px;
}

.product-thumb span.discount-percentage {
  font-weight: 400;
  color: #747474;
  margin-left: 5px;
}

.product-thumb .image a.has-thumb {
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.product-thumb:hover .image a.has-thumb {
  visibility: visible;
  opacity: 1;
}

.sub-categories {
  width: 100%;
  max-width: 1530px;
  display: inline-block;
  margin: 0 -15px;
}

.sub-categories .sub-category-column {
  width: 20%;
  display: inline-block;
  float: left;
  padding: 0 10px;
  position: relative;
  margin-bottom: 10px;
}

h4.sub-category-name {
  display: inline-block;
  position: relative;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 3.6rem;
}

h4.sub-category-name:after {
  display: inline-block;
  content: "";
  width: 0;
  height: 1px;
  background-color: #000;
  position: absolute;
  bottom: 8px;
  left: 0;
}
@media (max-width: 767px) {
  h4.sub-category-name:after {
    display: none;
  }
}

.sub-categories .sub-category-item {
  width: 100%;
  max-width: 255px;
  text-align: center;
}

.sub-categories .sub-category-item:hover h4.sub-category-name:after {
  width: 100%;
  -webkit-transition: width 0.3s ease;
  -moz-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

.sub-categories .sub-category-item:hover img {
  opacity: 0.8;
}

.category-grid {
  width: 100%;
}

@media (max-width: 1140px) {
  .sub-categories .sub-category-column {
    width: 25%;
  }
}
@media (max-width: 767px) {
  /*category*/
  .products-block {
    margin: 0 auto;
  }
  .caption > h4 {
    font-size: 1.4rem;
  }
  .category-grid {
    margin-left: 0;
  }
  .sub-categories {
    margin: 0 auto;
  }
  .sub-categories .sub-category-column:nth-child(4n+1) {
    clear: both;
  }
  h4.sub-category-name {
    font-size: 1.2rem;
  }
  span.category-separator {
    margin: 15px 0;
  }
  /*filters*/
  .filters-btn {
    color: #000;
    font-size: 1.2rem;
    letter-spacing: 0.025em;
    width: calc(50% - 15px);
    border-bottom: 1px solid #000;
    padding-bottom: 9px;
    max-height: 35px;
  }
  .filters-btn:hover,
  .filters-btn:active,
  .filters-btn:focus {
    color: #000;
  }
  .filter-icon,
  .filter-icon svg {
    display: inline-block;
    width: 12px;
    height: 13px;
  }
  .filter-icon svg .st0 {
    fill: #000;
  }
  .filters-btn .text {
    display: inline-block;
    padding-left: 5px;
  }
  .close-filters {
    position: fixed;
    top: 0;
    right: 0;
    height: 22px;
    z-index: 99;
    background-color: #fff;
    width: 100%;
  }
  .close-filters-icon,
  .close-filters-icon svg {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 5px;
    float: right;
  }
  .close-filters .filters-icon svg .st0 {
    fill: #000;
  }
  .category-short-by {
    width: calc(50% - 15px);
    max-width: unset;
  }
  .category-short-by .form-group.input-group {
    max-width: unset;
  }
  body.category-filters-open {
    overflow: hidden;
  }
  .products-filters.leftSidebar {
    position: fixed;
    top: 0;
    left: -100%;
    background: rgba(0, 0, 0, 0);
    width: 100%;
    max-width: unset;
    opacity: 0;
    visibility: hidden;
    z-index: 99;
    -webkit-transition: left 0.4s ease, background-color 0.1s ease, visibility 0s ease 0.4s, opacity 0s ease 0.4s;
    -moz-transition: left 0.4s ease, background-color 0.1s ease, visibility 0s ease 0.4s, opacity 0s ease 0.4s;
    -o-transition: left 0.4s ease, background-color 0.1s ease, visibility 0s ease 0.4s, opacity 0s ease 0.4s;
    transition: left 0.4s ease, background-color 0.1s ease, visibility 0s ease 0.4s, opacity 0s ease 0.4s;
  }
  .products-filters.leftSidebar.active {
    left: 0;
    opacity: 1;
    visibility: visible;
    background: rgba(0, 0, 0, 0.7);
    -webkit-transition: left 0.3s ease, background-color 0.3s ease 0.2s;
    -moz-transition: left 0.3s ease, background-color 0.3s ease 0.2s;
    -o-transition: left 0.3s ease, background-color 0.3s ease 0.2s;
    transition: left 0.3s ease, background-color 0.3s ease 0.2s;
  }
  #column-left {
    max-width: 50%;
    padding: 0 20px;
    height: 100vh;
    background-color: #fff;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .products-filters .bf-panel-wrapper {
    height: calc(100vh - 50px);
    overflow-y: auto;
    padding-top: 10px;
  }
  .products-filters .bf-buttonclear-box {
    position: fixed;
    bottom: 15px;
    left: 15px;
    width: calc(100% - 30px);
    z-index: 9;
  }
  .products-filters .bf-form > .bf-attr-block:first-of-type .bf-attr-header,
  .bf-attr-block .bf-attr-header.bf-clickable {
    font-size: 1.4rem;
    line-height: 2;
  }
  .products-filters .bf-cross,
  .products-filters .bf-close-btn,
  .products-filters .bf-arrow {
    background-image: url(../../../image/brainyfilter/sprite.png) !important;
  }
}
@media (max-width: 660px) {
  #column-left {
    max-width: unset;
  }
  .product-thumb .image {
    padding: 0;
  }
}
#product-category {
  background-color: var(--light-blue);
  padding: 0 6.1458333333vw;
}
@media (max-width: 767px) {
  #product-category {
    padding: 0 10px;
  }
}
#product-category .category-info {
  padding-top: max(43px, 4.7916666667vw);
  padding-bottom: 2.6041666667vw;
  color: var(--gold);
}
#product-category .category-info .category-description p {
  /* font-family */
  font-family: var(--primary-font), sans-serif;
  /* font-size */
  font-size: 30px;
  font-size: 3rem;
  /* font-weight */
  font-weight: 500;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 37px;
  line-height: 3.7rem;
  /* letter-spacing */
  letter-spacing: 0.22em;
  margin-bottom: 0;
}
@media screen and (max-width: 991px) {
  #product-category .category-info .category-description p {
    /* font-family */
    /* font-size */
    font-size: 24px;
    font-size: 2.4rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    line-height: 30px;
    line-height: 3rem;
    /* letter-spacing */
  }
}
#product-category .category-info .category-description br {
  display: none;
}
#product-category .category-info h2 {
  /* font-family */
  font-family: var(--primary-font), sans-serif;
  /* font-size */
  font-size: 60px;
  font-size: 6rem;
  /* font-weight */
  font-weight: 500;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 74px;
  line-height: 7.4rem;
  /* letter-spacing */
  letter-spacing: 0.22em;
  color: inherit;
  margin-bottom: 0;
}
@media screen and (max-width: 991px) {
  #product-category .category-info h2 {
    /* font-family */
    /* font-size */
    font-size: 36px;
    font-size: 3.6rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    line-height: 45px;
    line-height: 4.5rem;
    /* letter-spacing */
  }
}
#product-category .category-wrapper .categories-choices {
  transition: all 0.3s ease-in-out;
}
@media screen and (max-width: 991px) {
  #product-category .category-wrapper .categories-choices._open {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 10000;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    overscroll-behavior-y: contain;
    overflow-y: auto;
    background-color: var(--light-blue);
  }
  #product-category .category-wrapper .categories-choices._open .inline-button-wrap {
    display: inline-flex;
  }
  #product-category .category-wrapper .categories-choices._open #_plus {
    display: none;
  }
  #product-category .category-wrapper .categories-choices:not(._open) #_minus {
    display: none;
  }
}
#product-category .category-wrapper .categories-choices .choice-toggle {
  /* font-family */
  font-family: var(--primary-font), sans-serif;
  /* font-size */
  font-size: 16px;
  font-size: 1.6rem;
  /* font-weight */
  font-weight: 500;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 20px;
  line-height: 2rem;
  /* letter-spacing */
  letter-spacing: 0.22em;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  color: var(--dark-blue);
  padding: 15px 33px;
  background-color: transparent;
  color: var(--dark-blue);
  border: 1px solid var(--dark-blue);
  border-radius: 3px;
  cursor: pointer;
  display: none;
}
#product-category .category-wrapper .categories-choices .choice-toggle span {
  white-space: nowrap;
  margin-right: 20px;
}
#product-category .category-wrapper .categories-choices .inline-button-wrap {
  margin: auto;
  display: inline-flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media screen and (max-width: 991px) {
  #product-category .category-wrapper .categories-choices .inline-button-wrap {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 30px;
    display: none;
  }
}
#product-category .category-wrapper .categories-choices .inline-button-wrap .filterBy {
  /* font-family */
  font-family: var(--primary-font), sans-serif;
  /* font-size */
  font-size: 20px;
  font-size: 2rem;
  /* font-weight */
  font-weight: 500;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 30px;
  line-height: 3rem;
  /* letter-spacing */
  letter-spacing: 0.16em;
  padding: 15px 17px;
  background-color: transparent;
  color: var(--dark-blue);
  border: 1px solid var(--dark-blue);
  border-radius: 3px;
  margin: 5px;
  text-transform: uppercase;
}
@media screen and (max-width: 991px) {
  #product-category .category-wrapper .categories-choices .inline-button-wrap .filterBy {
    /* font-family */
    /* font-size */
    font-size: 24px;
    font-size: 2.4rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    /* letter-spacing */
    letter-spacing: 0.22em;
  }
}
#product-category .category-wrapper .categories-choices .inline-button-wrap .filterBy._active, #product-category .category-wrapper .categories-choices .inline-button-wrap .filterBy:hover {
  color: var(--gold);
  border-color: var(--gold);
}
@media screen and (max-width: 991px) {
  #product-category .category-wrapper .categories-choices .inline-button-wrap .filterBy._active, #product-category .category-wrapper .categories-choices .inline-button-wrap .filterBy:hover {
    color: var(--dark-blue);
  }
}
@media screen and (max-width: 991px) {
  #product-category .category-wrapper .categories-choices .inline-button-wrap .filterBy {
    letter-spacing: 0.22em;
    padding: unset;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    color: var(--dark-blue);
    background: transparent;
    border: none;
  }
  #product-category .category-wrapper .categories-choices .inline-button-wrap .filterBy._active, #product-category .category-wrapper .categories-choices .inline-button-wrap .filterBy:hover {
    position: relative;
  }
  #product-category .category-wrapper .categories-choices .inline-button-wrap .filterBy._active:after, #product-category .category-wrapper .categories-choices .inline-button-wrap .filterBy:hover:after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--dark-blue);
  }
  #product-category .category-wrapper .categories-choices .inline-button-wrap .filterBy:not(:last-child) {
    margin-bottom: 20px;
  }
}
#product-category .sub-footer-wall {
  margin-left: -6.1458333333vw;
  margin-right: -6.1458333333vw;
}
@media (max-width: 767px) {
  #product-category .sub-footer-wall {
    margin-left: -10px;
    margin-right: -10px;
  }
  #product-category .sub-footer-wall img {
    object-fit: cover;
    min-height: 340px;
  }
}

/*========================================================*/
/*** BRANDS ***/
/*========================================================*/
#product-manufacturer h1 {
  font-size: 2.4rem;
  line-height: initial;
  letter-spacing: 0.025em;
  font-weight: 600;
  color: #000;
  margin: 15px 0;
}

ul.brand-anchor {
  padding-bottom: 30px;
}

ul.brand-anchor li {
  max-width: 30px;
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 0 7px 7px 0;
  background-color: #f0f0f0;
}

ul.brand-anchor li > a {
  font-size: 1.4rem;
  line-height: initial;
  font-weight: 400;
  color: #000;
  display: block;
  width: 100%;
  padding: 7px 0;
}

ul.brand-anchor li:hover {
  background-color: #000;
}

ul.brand-anchor li:hover a {
  color: #fff;
}

.brand-letter {
  display: inline-block;
  width: 100%;
  padding: 0 0 20px;
  margin: 20px 0;
  position: relative;
  border-bottom: 1px solid #eee;
}

.letter_only {
  display: inline-block;
  float: left;
  width: 10%;
}

.brands_per_letter {
  display: inline-block;
  float: left;
  width: 90%;
}

#product-manufacturer .brand-name {
  margin: 0 0 20px 0;
  font-weight: 800;
}

.brands_per_letter a {
  color: #000;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}

.stores-container {
  --side-padding: 120px;
  background-color: var(--light-brown);
  padding-top: 90px;
}
@media (max-width: 1366px) {
  .stores-container {
    --side-padding: 80px;
  }
}
@media (max-width: 1140px) {
  .stores-container {
    --side-padding: 60px;
  }
}
@media (max-width: 767px) {
  .stores-container {
    --side-padding: 10px;
  }
}
@media (max-width: 767px) {
  .stores-container {
    padding-top: 40px;
  }
}
.stores-container .title-container {
  margin: 0 auto 60px;
}
@media (max-width: 767px) {
  .stores-container .title-container {
    margin: 0 auto 20px;
  }
}
.stores-container .title-container h2 {
  margin: 0;
}
.stores-container .stores-hero {
  padding: 0 var(--side-padding);
  margin: 0 auto clamp(80px, 8.0729vw, 155px);
}
@media (max-width: 1440px) {
  .stores-container .stores-hero {
    margin: 0 auto 80px;
  }
}
@media (max-width: 767px) {
  .stores-container .stores-hero {
    padding: 0;
    margin: 0 auto 30px;
  }
}
.stores-container .stores-hero img {
  margin: 0 auto;
}
@media (max-width: 767px) {
  .stores-container .stores-hero img {
    width: 100%;
  }
}
.stores-container .our-goals {
  padding: 0 clamp(50px, 10.4167vw, 200px) clamp(40px, 8.3333vw, 160px);
}
@media (max-width: 1600px) {
  .stores-container .our-goals {
    padding: 0 100px clamp(40px, 8.3333vw, 160px);
  }
}
@media (max-width: 991px) {
  .stores-container .our-goals {
    padding: 0 var(--side-padding) 40px;
  }
}
.stores-container .our-goals .moto-sup-title {
  max-width: 137px;
  width: 100%;
  margin: 0 auto 30px;
}
@media (max-width: 660px) {
  .stores-container .our-goals .moto-sup-title {
    margin: 0 auto 15px;
  }
}
.stores-container .our-goals .moto {
  max-width: 1350px;
  width: 100%;
  margin: 0 auto 45px;
}
@media (max-width: 1440px) {
  .stores-container .our-goals .moto {
    max-width: 1000px;
  }
}
@media (max-width: 767px) {
  .stores-container .our-goals .moto {
    margin: 0 auto 15px;
  }
}
@media (max-width: 440px) {
  .stores-container .our-goals .moto .header-1 {
    padding: 0 20px;
  }
  .stores-container .our-goals .moto .header-1 br {
    display: none;
  }
}
.stores-container .our-goals .description {
  max-width: 910px;
  width: 100%;
  margin: 0 auto;
}
.stores-container .our-goals .header-1 {
  margin: 0 0 25px;
}
@media (max-width: 767px) {
  .stores-container .our-goals .header-1 {
    margin: 0 auto 15px;
  }
}
.stores-container .store-items-container {
  background-color: var(--light-blue);
  padding: clamp(120px, 15.1042vw, 290px) var(--side-padding) clamp(120px, 12.2396vw, 235px);
}
@media (max-width: 991px) {
  .stores-container .store-items-container {
    padding: 100px var(--side-padding) 90px;
  }
}
.stores-container .store-items-container .store-items-wrapper {
  justify-content: space-between;
}
@media (max-width: 991px) {
  .stores-container .store-items-container .store-items-wrapper {
    flex-direction: column;
  }
}
.stores-container .store-items-container .store-item-wrapper:nth-child(even) {
  padding-top: clamp(120px, 14.0625vw, 270px);
}
@media (max-width: 991px) {
  .stores-container .store-items-container .store-item-wrapper:nth-child(even) {
    padding-top: unset;
    justify-content: flex-end;
  }
}
.stores-container .store-items-container .store-item-wrapper:first-child {
  margin-right: 30px;
}
@media (max-width: 991px) {
  .stores-container .store-items-container .store-item-wrapper:first-child {
    margin-right: unset;
    margin: 0 0 65px;
    justify-content: flex-start;
  }
}
@media (max-width: 991px) {
  .stores-container .store-items-container .store-item-wrapper {
    display: flex;
  }
}
@media (max-width: 767px) {
  .stores-container .store-items-container .store-item-wrapper {
    display: block;
  }
}
.stores-container .store-items-container .store-item {
  max-width: clamp(460px, 37.2396vw, 715px);
  width: 100%;
  position: relative;
}
@media (max-width: 991px) {
  .stores-container .store-items-container .store-item {
    max-width: 550px;
  }
}
@media (max-width: 767px) {
  .stores-container .store-items-container .store-item {
    margin: 0 auto;
  }
}
.stores-container .store-items-container .store-item .title {
  max-width: clamp(400px, 30.5208vw, 586px);
  width: 100%;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 991px) {
  .stores-container .store-items-container .store-item .title {
    top: -30px;
    max-width: 80%;
  }
}
.stores-container .store-items-container .store-item .store-item-title {
  color: var(--primary-color);
  letter-spacing: 0.9em;
}
.stores-container .store-items-container .store-item .description {
  margin: 0 0 40px;
}
@media (max-width: 1540px) {
  .stores-container .store-items-container .store-item .description {
    margin: 0 0 30px;
  }
}
@media (max-width: 440px) {
  .stores-container .store-items-container .store-item .description {
    margin: 0 0 20px;
  }
}
.stores-container .store-items-container .store-item .address {
  margin: 0 0 50px;
}
@media (max-width: 1540px) {
  .stores-container .store-items-container .store-item .address {
    margin: 0 0 35px;
  }
}
@media (max-width: 440px) {
  .stores-container .store-items-container .store-item .address {
    margin: 0 0 15px;
  }
}
.stores-container .store-items-container .store-item .address p {
  margin: 0;
}
.stores-container .store-items-container .store-item .bottom-section {
  background-color: var(--dark-blue);
  color: var(--light-blue);
  padding: 80px 80px 65px;
  text-align: center;
}
@media (max-width: 1540px) {
  .stores-container .store-items-container .store-item .bottom-section {
    padding: 60px 40px 45px;
  }
}
@media (max-width: 991px) {
  .stores-container .store-items-container .store-item .bottom-section {
    height: var(--calc-height);
    padding: 20px 40px 45px;
  }
}
.stores-container .store-items-container .store-item .view-on-map {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 165px;
  width: 100%;
  margin: 0 auto;
}
.stores-container .store-items-container .store-item .view-on-map .header-2 {
  margin: 0;
}
@media (max-width: 1366px) {
  .stores-container .store-items-container .store-item .view-on-map {
    flex-direction: row;
  }
  .stores-container .store-items-container .store-item .view-on-map .header-2 {
    font-size: 2.4rem;
  }
}
@media (max-width: 440px) {
  .stores-container .store-items-container .store-item .view-on-map {
    max-width: 120px;
    flex-direction: row;
  }
  .stores-container .store-items-container .store-item .view-on-map br {
    display: none;
  }
  .stores-container .store-items-container .store-item .view-on-map .header-2 {
    font-size: 1.6rem;
    margin: 0;
  }
}
.stores-container .store-items-container .store-item .view-on-map .map-icon {
  margin: 0 auto 17px;
}
@media (max-width: 1366px) {
  .stores-container .store-items-container .store-item .view-on-map .map-icon {
    margin: 0 10px;
  }
}
@media (max-width: 440px) {
  .stores-container .store-items-container .store-item .view-on-map .map-icon {
    width: 12px;
    margin: 0 5px;
  }
}
.stores-container .stores-footer picture,
.stores-container .stores-footer img {
  width: 100%;
}

.bordered .top-section,
.bordered .bottom-section {
  border-radius: 400px;
  overflow: hidden;
}
.bordered .top-section {
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
}
.bordered .bottom-section {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  position: relative;
}
.bordered .bottom-section::after {
  border-radius: 400px;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  content: "";
  width: calc(100% - 46px);
  height: calc(100% - 46px);
  border: 2px solid var(--primary-color);
  position: absolute;
  top: 26px;
  left: 23px;
  background-color: transparent;
  pointer-events: none;
  border-top: unset;
  margin: 0 auto;
}

/*========================================================*/
/* Product Page */
/*========================================================*/
.product-container {
  background-color: var(--light-brown);
  padding: clamp(80px, 6.5104vw, 125px) var(--side-padding) 70px;
}

.product-row {
  max-width: 1540px;
  width: 100%;
  margin: 0 auto 70px;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .product-row {
    flex-direction: column;
    margin: 0 auto 35px;
  }
}
.product-row .right-col {
  width: 45%;
  margin: 0 0 0 15px;
}
@media (max-width: 991px) {
  .product-row .right-col {
    width: 100%;
    margin: 0 0 25px;
  }
}
@media (max-width: 767px) {
  .product-row .right-col {
    margin: 0;
  }
}
.product-row .left-col {
  width: 55%;
  margin-right: 15px;
}
@media (max-width: 1540px) {
  .product-row .left-col {
    width: 52%;
  }
}
@media (max-width: 1440px) {
  .product-row .left-col {
    width: 50%;
  }
}
@media (max-width: 991px) {
  .product-row .left-col {
    width: 100%;
    margin: 0 0 25px;
  }
}
.product-row .left-col .product-thumbnails {
  width: 100%;
  max-width: 760px;
}
@media (max-width: 991px) {
  .product-row .left-col .product-thumbnails {
    margin: 0 auto;
  }
  .product-row .left-col .product-thumbnails .thumb {
    margin: 0 auto;
  }
}

/*Product images*/
.product-thumbnails-slider {
  padding-bottom: 40px !important;
}
.product-thumbnails-slider .product-thumbs-pagination {
  bottom: 0 !important;
}
.product-thumbnails-slider .product-thumbs-pagination .swiper-pagination-bullet-active {
  background-color: var(--dark-blue);
}
.product-thumbnails-slider .product-thumbs-pagination .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  margin: 0 7px !important;
}

.product-related-pagination {
  bottom: -50px !important;
}
@media (max-width: 767px) {
  .product-related-pagination {
    display: none;
  }
}
.product-related-pagination .swiper-pagination-bullet-active {
  background-color: var(--dark-blue);
}
.product-related-pagination .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  margin: 0 7px !important;
}

/*Product details*/
.product-header .header-1,
.product-header .header-2 {
  margin: 0;
}
@media (max-width: 991px) {
  .product-header {
    max-width: unset;
    text-align: center;
    margin: 0 auto;
  }
}

.product-description {
  margin: 0 0 35px;
}

.price-list {
  margin: 30px auto;
}
.price-list li {
  display: inline-block;
  padding-right: 15px;
}
@media (max-width: 991px) {
  .price-list {
    text-align: center;
    margin: 25px auto;
  }
}

.product-price {
  font-size: 5.8rem;
  font-weight: 500;
}
.product-price.old {
  font-size: 4.8rem;
  font-weight: 400;
  text-decoration: line-through;
  color: var(--primary-color);
  opacity: 0.5;
}
@media (max-width: 1540px) {
  .product-price {
    font-size: 5rem;
  }
  .product-price.old {
    font-size: 4rem;
  }
}
@media (max-width: 1440px) {
  .product-price {
    font-size: 4.5rem;
  }
  .product-price.old {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .product-price {
    font-size: 2.4rem;
  }
  .product-price.old {
    font-size: 2rem;
  }
}

.product-badges-container {
  border-top: 1px solid var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
  padding: 20px;
  margin: 35px 0;
}
.product-badges-container .product-badges-wrapper {
  justify-content: center;
}
.product-badges-container .product-badge {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.product-badges-container .product-badge:not(:last-child) {
  margin: 0 45px 0 0;
}
@media (max-width: 767px) {
  .product-badges-container .product-badge:not(:last-child) {
    margin: 0 20px 0 0;
  }
}
.product-badges-container .product-badge span {
  margin: 0 auto 3px;
}
.product-badges-container .product-badge p {
  font-size: 2rem;
  font-family: var(--primary-font), sans-serif;
  color: var(--primary-color);
  margin: 0;
  text-align: center;
}

.product-available-colors {
  display: none;
}
.product-available-colors .color {
  display: block;
  margin-bottom: 10px;
  font-size: 1.1rem;
  font-weight: 600;
}
.product-available-colors .flex-row {
  flex-wrap: wrap;
}
.product-available-colors .flex-row li {
  max-width: 60px;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
}
.product-available-colors .flex-row li.selected {
  border: 1px solid #000;
}

.select-options .control-label {
  font-size: 1.2rem;
  font-weight: 600;
}

.quantity-input-field {
  position: relative;
}
.quantity-input-field .number-input {
  align-items: center;
}
.quantity-input-field .number-input p {
  margin: 0 20px 0 0;
}
@media (max-width: 1366px) {
  .quantity-input-field .number-input p {
    margin: 0 0 10px 0;
  }
}
@media (max-width: 1366px) {
  .quantity-input-field .number-input {
    flex-direction: column;
    align-items: flex-start;
  }
}
.quantity-input-field .quantity {
  position: relative;
}
.quantity-input-field .quantity input[type=text].form-control {
  max-width: 120px;
  width: 100%;
  border: 1px solid var(--secondary-color);
  border-radius: var(--input-border-radius);
  margin: 0;
  font-size: 1.6rem;
  color: var(--secondary-color);
  text-align: center;
  font-weight: 400;
  padding: 17px 5px;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}
.quantity-input-field .quantity .quantity-nav {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}

#product .btn-default {
  width: 100%;
  letter-spacing: 0.22em;
}

.quantity-options-container {
  justify-content: space-between;
  align-items: center;
  margin: 35px 0;
}
.quantity-options-container > div:first-child {
  margin: 0 15px 0 0;
}
.quantity-options-container select {
  max-width: 250px;
}
@media (max-width: 1366px) {
  .quantity-options-container {
    justify-content: unset;
  }
  .quantity-options-container > div:first-child {
    margin: 0 30px 0 0;
  }
}
@media (max-width: 1366px) and (max-width: 440px) {
  .quantity-options-container > div:first-child {
    margin: 0 15px 0 0;
  }
}
@media (max-width: 767px) {
  .quantity-options-container {
    margin: 0 0 35px 0;
  }
}

.single-options {
  align-items: center;
}
.single-options p {
  margin: 0 20px 0 0;
}
@media (max-width: 1366px) {
  .single-options p {
    margin: 0 0 10px 0;
  }
}
@media (max-width: 1366px) {
  .single-options {
    flex-direction: column;
    align-items: flex-start;
  }
}

.single-options-wrapper .form-group {
  margin-bottom: 0;
}

.select-options .quantity {
  position: relative;
}
.select-options .quantity-nav {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 25px;
}
.select-options .quantity-nav span {
  display: inline-block;
}
.select-options .quantity-nav span svg {
  display: inline-block;
  width: 14px;
  height: 8px;
}
.select-options .quantity-nav .quantity-button {
  padding: 5px 7px;
  cursor: pointer;
}

.custom-cake-option {
  margin: 0 0 20px;
}
.custom-cake-option label {
  font-size: 2.4rem;
  color: var(--primary-color);
  margin: 0 0 15px;
  font-weight: 500;
  font-family: var(--primary-font), sans-serif;
  letter-spacing: 0.02em;
  width: 100%;
}
@media (max-width: 991px) {
  .custom-cake-option label {
    font-size: 2.4rem;
  }
}
.custom-cake-option select,
.custom-cake-option input {
  max-width: unset;
  width: 100%;
  background-position: 95% 50%;
  font-size: 1.6rem;
}
@media (max-width: 660px) {
  .custom-cake-option select,
  .custom-cake-option input {
    padding: 17px 40px 17px 15px;
  }
}
.custom-cake-option textarea {
  font-size: 1.6rem;
}
.custom-cake-option .date {
  margin: 0 0 20px;
}
.custom-cake-option .date input {
  font-size: 1.6rem;
  border-right: none;
  color: var(--secondary-color);
}
.custom-cake-option .date .btn-default {
  background-color: transparent;
  border-left: none;
  padding: 17px 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
}
.custom-cake-option .date + p {
  font-size: 1.6rem;
  margin: 0;
}
.custom-cake-option.file .btn-default {
  font-size: 1.6rem;
  padding: 17px 22px;
  background-color: transparent;
  border-color: var(--secondary-color);
  color: var(--secondary-color);
  max-height: 60px;
  line-height: 1.2;
}

.free-delivery-msg {
  font-size: 1.2rem;
  display: block;
  margin: 40px 0;
}

/*related products*/
.related-products {
  margin: 0 auto clamp(120px, 11.1979vw, 215px);
  position: relative;
}
@media (max-width: 767px) {
  .related-products {
    margin: 0 auto;
    padding-bottom: 45px;
  }
  .related-products .related-products-slider .swiper-wrapper {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
  }
  .related-products .related-products-slider .swiper-slide {
    flex-basis: calc(50% - 10px);
    margin-bottom: 40px;
  }
}
.related-products > .header-1,
.related-products > .header-2 {
  color: var(--primary-color);
}
.related-products > .header-1 {
  margin: 0 auto clamp(45px, 4.4271vw, 85px);
}

.share-product-container {
  margin: 0 auto clamp(50px, 7.5521vw, 145px);
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}
.share-product-container .share-icon {
  padding: 6px;
  margin-left: 10px;
}

/*add to cart message*/
@keyframes note {
  0% {
    margin-top: 25px;
    opacity: 0;
  }
  10% {
    margin-top: 0;
    opacity: 1;
  }
  90% {
    margin-top: 0;
    opacity: 1;
  }
  100% {
    margin-top: 25px;
    opacity: 0;
  }
}
.popup-alert {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 50px;
  margin-bottom: 0;
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 0;
  z-index: 9999;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  animation: note 6500ms forwards;
}

.popup-alert a,
.popup-alert a:hover {
  color: #000;
  font-weight: 500;
}

.share-fb {
  display: inline-block;
  width: 11px;
  height: 20px;
}
.share-fb svg {
  display: inline-block;
  width: 11px;
  height: 20px;
}

@media (max-width: 1280px) {
  /*checkout page*/
  .checkout-container.container {
    width: 100%;
  }
  .navbar {
    padding: 15px;
  }
  .checkout-logo span.logo-icon svg,
  .checkout-logo span.logo-icon {
    width: 220px;
    height: auto;
  }
}
@media (max-width: 767px) {
  /*product*/
  .product-container {
    padding: 20px 10px;
  }
  .product-thumbnails .thumb {
    margin-bottom: 0;
  }
  .product-thumbnails .thumb:nth-child(even),
  .product-thumbnails .thumb:nth-child(odd) {
    padding: 0;
  }
  .product-thumbnails .slick-slide img {
    margin: 0 auto;
  }
  .product-row .slider-arrows {
    display: block;
    top: 0;
    height: 100%;
    -webkit-transform: unset;
    -moz-transform: unset;
    -o-transform: unset;
    transform: unset;
  }
  .product-row .slider-arrows .arrow {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 70px;
  }
  .product-row .slider-arrows .arrow.arrow-prev {
    left: 0;
  }
  .product-row .slider-arrows .arrow.arrow-next {
    right: 0;
  }
  .product-row .slider-arrows .arrow svg {
    fill: #000;
  }
  .products-block .product-thumb {
    margin-bottom: 15px;
  }
  .flex-row.category-pagination {
    padding: 15px 0;
  }
  .popup-alert {
    padding: 15px;
    width: 90%;
  }
}
.carousel-wrapper .slick-dots {
  position: absolute;
  left: 50%;
  bottom: -10px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.carousel-wrapper .slick-dots li {
  display: inline-block;
  margin: 0 10px;
}

.carousel-wrapper .slick-dots li button {
  font-size: 0;
  width: 10px;
  height: 10px;
  display: inline-block;
  padding: 0;
  background-color: #c8c8c9;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: none;
}

.carousel-wrapper .slick-dots li.slick-active button {
  background-color: #000;
}

.btn-candles-a:hover {
  cursor: pointer;
  color: #fff !important;
}

#birthday-candles {
  width: 18px !important;
  padding: 10px !important;
}

.btn-candles-a.btn-primary:hover {
  color: #fff !important;
}

.btn-candles-a.btn-primary {
  color: var(--white);
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: var(--button-border-radius);
  padding: 10px;
}

.product-thumb:hover .btn-candles-a.btn-primary {
  color: #fff !important;
}

.contact-form-container {
  background-color: var(--light-brown);
}
.contact-form-container .contact-form-wrapper {
  max-width: 970px;
  width: 100%;
  margin: 0 auto;
  padding: 90px 30px 0;
}
@media (max-width: 991px) {
  .contact-form-container .contact-form-wrapper {
    padding: 60px 30px 0;
  }
}
@media (max-width: 660px) {
  .contact-form-container .contact-form-wrapper {
    padding: 40px 10px 0;
  }
}
.contact-form-container .contact-form {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}
@media (max-width: 1440px) {
  .contact-form-container .contact-form {
    max-width: 550px;
  }
}
.contact-form-container .title-wrapper {
  margin-bottom: 60px;
}
@media (max-width: 660px) {
  .contact-form-container .title-wrapper {
    margin-bottom: 20px;
  }
}
.contact-form-container .title-wrapper h2 {
  margin: 0;
}
.contact-form-container .contact-description {
  max-width: 900px;
  width: 100%;
  margin: 0 auto clamp(35px, 3.6458vw, 60px);
  line-height: 1.3;
}
.contact-form-container .contact-description p {
  display: inline;
}
@media (max-width: 767px) {
  .contact-form-container .contact-description {
    margin: 0 auto 35px;
    padding: 0 10px;
  }
  .contact-form-container .contact-description .contact-moto {
    margin: 0 auto 25px;
  }
  .contact-form-container .contact-description .contact-moto,
  .contact-form-container .contact-description .contact-info {
    display: block;
  }
}
.contact-form-container .contact-form {
  margin-bottom: clamp(75px, 11.7188vw, 225px);
}
@media (max-width: 767px) {
  .contact-form-container .contact-form {
    margin-bottom: 75px;
  }
}
.contact-form-container .contact-form .form-group {
  margin: 0 0 45px;
}
@media (max-width: 767px) {
  .contact-form-container .contact-form .form-group {
    margin: 0 0 20px;
  }
}
.contact-form-container .contact-form .form-group .col-sm-12 {
  padding-left: 0;
  padding-right: 0;
}
.contact-form-container .contact-form .form-group input,
.contact-form-container .contact-form .form-group textarea {
  margin-bottom: 5px;
}
.contact-form-container .contact-form .form-group textarea {
  resize: vertical;
  min-height: 227px;
  padding: 30px 25px;
}
.contact-form-container .contact-form .btn-default {
  margin: 0;
  letter-spacing: 0.22em;
}
.contact-form-container .contact-form .text-danger {
  text-align: center;
}
.contact-form-container .contact-footer picture,
.contact-form-container .contact-footer img {
  width: 100%;
}
.contact-form-container .text-danger {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 12px;
  font-size: 1.2rem;
  /* font-weight */
  font-weight: 500;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 12px;
  line-height: 1.2rem;
  /* letter-spacing */
  padding: 5px 25px 0 25px;
}

/*========================================================*/
/*** SEARCH RESULTS ***/
/*========================================================*/
#result_block p.pull-right.block-text.text-left,
#result_block p.block-text.text-left {
  display: none;
}

.search-container {
  display: block;
  width: 100%;
  padding: 80px 35px 100px 35px;
}

.search-container .breadcrumb {
  display: none;
}

.flex-row.search-checkboxes {
  margin-top: 20px;
}

.search-checkbox-item:first-child {
  margin-right: 20px;
}

.search-container input#button-search {
  margin: 35px 0;
}

@media (max-width: 767px) {
  /*search results*/
  .search-container {
    padding: 50px 15px 0;
  }
  #input-search {
    margin-bottom: 15px;
  }
  .flex-row.search-checkboxes {
    flex-direction: column;
  }
  #button-search {
    max-width: unset;
  }
}
/*========================================================*/
/*** INFORMATION PAGES ***/
/*========================================================*/
.info-page {
  background-color: var(--light-brown);
}
.info-page .section-wrap {
  display: flex;
  flex-direction: column;
  padding-top: max(30px, 2.8645833333vw);
  padding-bottom: 9.1145833333vw;
  padding-left: 19.2708333333vw;
  padding-right: 19.2708333333vw;
  text-align: left;
}
@media screen and (max-width: 991px) {
  .info-page .section-wrap {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.info-page .section-wrap__modal {
  padding: 30px;
}
@media screen and (max-width: 991px) {
  .info-page .section-wrap__modal {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.info-page .section-wrap__title, .info-page .section-wrap h1 {
  /* font-family */
  font-family: var(--primary-font), sans-serif;
  /* font-size */
  font-size: 60px;
  font-size: 6rem;
  /* font-weight */
  font-weight: 500;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 74px;
  line-height: 7.4rem;
  /* letter-spacing */
  display: flex;
  flex-direction: column;
  letter-spacing: 0.22em;
  text-align: center;
  text-transform: uppercase;
  color: var(--gold);
  margin-bottom: max(30px, 3.125vw);
}
@media screen and (max-width: 991px) {
  .info-page .section-wrap__title, .info-page .section-wrap h1 {
    /* font-family */
    /* font-size */
    font-size: 36px;
    font-size: 3.6rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    line-height: 45px;
    line-height: 4.5rem;
    /* letter-spacing */
  }
}
.info-page .section-wrap__sub-title {
  /* font-family */
  font-family: var(--primary-font), sans-serif;
  /* font-size */
  font-size: 30px;
  font-size: 3rem;
  /* font-weight */
  font-weight: 500;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 37px;
  line-height: 3.7rem;
  /* letter-spacing */
  letter-spacing: 0.22em;
}
.info-page .section-wrap h2 {
  /* font-family */
  font-family: var(--primary-font), sans-serif;
  /* font-size */
  font-size: 34px;
  font-size: 3.4rem;
  /* font-weight */
  font-weight: 500;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 42px;
  line-height: 4.2rem;
  /* letter-spacing */
  letter-spacing: 0.04em;
  color: var(--gold);
  margin-bottom: 18px;
}
.info-page .section-wrap h2:not(:first-of-type) {
  margin-top: 40px;
}
@media screen and (max-width: 991px) {
  .info-page .section-wrap h2 {
    /* font-family */
    /* font-size */
    font-size: 24px;
    font-size: 2.4rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    line-height: 30px;
    line-height: 3rem;
    /* letter-spacing */
    letter-spacing: 0.01em;
    margin-bottom: 21px;
  }
  .info-page .section-wrap h2:not(:first-of-type) {
    margin-top: 10px;
  }
}
.info-page .section-wrap ul,
.info-page .section-wrap ol,
.info-page .section-wrap p {
  /* font-family */
  font-family: var(--secondary-font), sans-serif;
  /* font-size */
  font-size: 24px;
  font-size: 2.4rem;
  /* font-weight */
  font-weight: 400;
  /* font-style */
  font-style: normal;
  /* line-height */
  line-height: 30px;
  line-height: 3rem;
  /* letter-spacing */
  letter-spacing: 0.01em;
  color: var(--dark-blue);
  margin-bottom: 40px;
}
@media screen and (max-width: 991px) {
  .info-page .section-wrap ul,
  .info-page .section-wrap ol,
  .info-page .section-wrap p {
    /* font-family */
    /* font-size */
    font-size: 16px;
    font-size: 1.6rem;
    /* font-weight */
    /* font-style */
    /* line-height */
    line-height: 22px;
    line-height: 2.2rem;
    /* letter-spacing */
    margin-bottom: 30px;
  }
}
.info-page .section-wrap ul a,
.info-page .section-wrap ol a,
.info-page .section-wrap p a {
  text-decoration: underline;
}
.info-page .section-wrap ul img,
.info-page .section-wrap ol img,
.info-page .section-wrap p img {
  max-width: 100%;
}

.megamenu-style-dev {
  margin-bottom: 0 !important;
}
.megamenu-style-dev .navbar-default {
  background-color: transparent;
  border-color: transparent;
}
.megamenu-style-dev .megamenu-wrapper {
  background-color: transparent;
  position: initial !important;
  border-radius: 0 !important;
}
@media (max-width: 1280px) {
  .megamenu-style-dev .megamenu-wrapper {
    background: var(--white);
    border: none;
    width: 100%;
    padding: 20px 40px;
    height: 100%;
    max-height: unset;
  }
}
@media (max-width: 1280px) and (max-width: 767px) {
  .megamenu-style-dev .megamenu-wrapper {
    padding: 20px 10px;
  }
}
.megamenu-style-dev .megamenu-wrapper ul.megamenu {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
}
@media (max-width: 1280px) {
  .megamenu-style-dev .megamenu-wrapper ul.megamenu {
    flex-wrap: wrap;
  }
}
@media (max-width: 1280px) {
  .megamenu-style-dev .megamenu-wrapper ul.megamenu li {
    flex-basis: 100%;
  }
}
.megamenu-style-dev .megamenu-wrapper ul.megamenu li:not(:last-child) {
  margin-right: 20px;
}
@media (max-width: 1280px) {
  .megamenu-style-dev .megamenu-wrapper ul.megamenu li:not(:last-child) {
    margin-right: 0;
  }
}
.megamenu-style-dev .megamenu-wrapper ul.megamenu li a {
  font-family: var(--primary-font), sans-serif;
  font-size: 2.6rem;
  letter-spacing: 0.22em;
  text-transform: uppercase;
  color: var(--secondary-color);
  transition: color 0.3s ease;
  background-color: transparent;
  border: none;
  padding: 0;
}
@media (max-width: 1600px) {
  .megamenu-style-dev .megamenu-wrapper ul.megamenu li a {
    font-size: 2.2rem;
  }
}
@media (max-width: 1280px) {
  .megamenu-style-dev .megamenu-wrapper ul.megamenu li a {
    font-size: 3rem;
    padding: 15px 0;
  }
}
@media (min-width: 1281px) {
  .megamenu-style-dev .megamenu-wrapper ul.megamenu li .sub-menu {
    visibility: hidden;
    opacity: 0;
    height: -100%;
    -webkit-transition: height 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
    -moz-transition: height 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
    -o-transition: height 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
    transition: height 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
  }
}
.megamenu-style-dev .megamenu-wrapper ul.megamenu li .sub-menu .content {
  padding: 65px var(--side-padding);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
@media (max-width: 1280px) {
  .megamenu-style-dev .megamenu-wrapper ul.megamenu li .sub-menu .content {
    padding: 0;
  }
}
.megamenu-style-dev .megamenu-wrapper ul.megamenu li .sub-menu .content .categories .menu ul li a {
  display: inline-block;
  font-family: var(--secondary-font), sans-serif;
  font-size: 2.2rem;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: var(--secondary-color);
  text-transform: capitalize;
  padding: 0;
  border: none;
  margin-bottom: 40px;
  position: relative;
  -webkit-transition: transform 0.3s ease;
  -moz-transition: transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
}
@media (max-width: 1280px) {
  .megamenu-style-dev .megamenu-wrapper ul.megamenu li .sub-menu .content .categories .menu ul li a {
    font-size: 2rem;
    margin-bottom: 15px;
  }
}
.megamenu-style-dev .megamenu-wrapper ul.megamenu li .sub-menu .content .categories .menu ul li a::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 50%;
  height: 1px;
  background-color: #7d6100;
  transition: transform 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform: scale(1) translateZ(0);
  opacity: 0;
}
.megamenu-style-dev .megamenu-wrapper ul.megamenu li .sub-menu .content .categories .menu ul li a::after {
  display: inline-block !important;
  content: "";
  position: absolute;
  bottom: -1px;
  right: 0;
  width: 50%;
  height: 1px;
  background-color: #7d6100;
  transition: transform 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform: scale(1) translateZ(0);
  opacity: 0;
}
.megamenu-style-dev .megamenu-wrapper ul.megamenu li .sub-menu .content .categories .menu ul li a:hover::before {
  transform: scaleX(0) translateZ(0);
  transform-origin: left;
  background-color: #7d6100;
  transition: transform 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86), background-color 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  will-change: background-color;
  opacity: 1;
}
.megamenu-style-dev .megamenu-wrapper ul.megamenu li .sub-menu .content .categories .menu ul li a:hover::after {
  transform: scaleX(0) translateZ(0);
  transform-origin: right;
  background-color: #7d6100;
  transition: transform 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86), background-color 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  will-change: background-color;
  opacity: 1;
}
.megamenu-style-dev .megamenu-wrapper ul.megamenu li .sub-menu .content .menu-banner {
  position: relative;
  width: 100%;
  max-width: 400px;
}
@media (max-width: 1280px) {
  .megamenu-style-dev .megamenu-wrapper ul.megamenu li .sub-menu .content .menu-banner {
    display: none;
  }
}
.megamenu-style-dev .megamenu-wrapper ul.megamenu li .sub-menu .content .menu-banner img {
  position: relative;
}
.megamenu-style-dev .megamenu-wrapper ul.megamenu li .sub-menu .content .menu-banner .menu-banner-link {
  position: absolute;
  width: 95%;
  bottom: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  font-family: var(--secondary-font), sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.01em;
  padding: 20px;
  text-transform: capitalize;
  background-color: rgba(255, 255, 255, 0.8);
  color: var(--primary-color);
  transition: color 0.3s;
}
.megamenu-style-dev .megamenu-wrapper ul.megamenu li .sub-menu .content .menu-banner .menu-banner-link:hover {
  transition: color 0.3s;
  background-color: rgba(255, 255, 255, 0.9);
}
.megamenu-style-dev .megamenu-wrapper ul.megamenu li.with-sub-menu a {
  font-family: var(--primary-font), sans-serif;
  font-size: 2.6rem;
  letter-spacing: 0.22em;
  text-transform: uppercase;
  color: var(--secondary-color);
  padding: 0;
  position: relative;
}
.megamenu-style-dev .megamenu-wrapper ul.megamenu li.with-sub-menu a::before {
  background: transparent;
}
@media (max-width: 1600px) {
  .megamenu-style-dev .megamenu-wrapper ul.megamenu li.with-sub-menu a {
    font-size: 2.2rem;
  }
}
@media (max-width: 1280px) {
  .megamenu-style-dev .megamenu-wrapper ul.megamenu li.with-sub-menu a {
    font-size: 3rem;
    padding: 15px 0;
  }
}
.megamenu-style-dev .megamenu-wrapper ul.megamenu li.with-sub-menu a::after {
  display: none;
}
.megamenu-style-dev .megamenu-wrapper ul.megamenu li.with-sub-menu a .caret {
  display: none;
}
@media (max-width: 1280px) {
  .megamenu-style-dev .megamenu-wrapper ul.megamenu li.with-sub-menu a .caret {
    display: inline-block;
    content: "" !important;
    width: 16px;
    height: 9px;
    background-image: url("../../../icons/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px 9px;
    border: 0;
    position: absolute;
    top: 50%;
    right: 15px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
  }
}
.megamenu-style-dev .megamenu-wrapper ul.megamenu li.with-sub-menu.hover .sub-menu {
  left: 0;
  top: calc(var(--headerHeight) - 51px);
  padding-top: 50px;
}
@media (min-height: 1281px) {
  .megamenu-style-dev .megamenu-wrapper ul.megamenu li.with-sub-menu.hover .sub-menu {
    height: 100%;
    -webkit-transition: height 0.3s ease, opacity 0.4s ease 0.4s, visibility 0.4s ease 0.4s;
    -moz-transition: height 0.3s ease, opacity 0.4s ease 0.4s, visibility 0.4s ease 0.4s;
    -o-transition: height 0.3s ease, opacity 0.4s ease 0.4s, visibility 0.4s ease 0.4s;
    transition: height 0.3s ease, opacity 0.4s ease 0.4s, visibility 0.4s ease 0.4s;
  }
}
@media (max-width: 1440px) {
  .megamenu-style-dev .megamenu-wrapper ul.megamenu li.with-sub-menu.sub-menu-is-open a .caret {
    -moz-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
}
.megamenu-style-dev .megamenu-wrapper ul.megamenu li.with-sub-menu.sub-menu-is-open .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 0;
  -webkit-transition: bottom 0.3s ease, opacity 0.4s ease 0.4s, visibility 0.4s ease 0.4s;
  -moz-transition: bottom 0.3s ease, opacity 0.4s ease 0.4s, visibility 0.4s ease 0.4s;
  -o-transition: bottom 0.3s ease, opacity 0.4s ease 0.4s, visibility 0.4s ease 0.4s;
  transition: bottom 0.3s ease, opacity 0.4s ease 0.4s, visibility 0.4s ease 0.4s;
}
.megamenu-style-dev .megamenu-wrapper ul.megamenu li:hover {
  background-color: transparent;
}
.megamenu-style-dev .megamenu-wrapper ul.megamenu li:hover a {
  background-color: transparent;
  color: var(--primary-color);
}

@media (max-width: 1280px) {
  .megamenu-style-dev {
    position: fixed;
    top: 150px;
    top: var(--headerHeight);
    z-index: 999999;
    left: -100%;
    width: 40%;
    height: calc(100vh - var(--headerHeight));
    visibility: hidden;
    opacity: 0;
    -webkit-transition: left 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
    -moz-transition: left 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
    -o-transition: left 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
    transition: left 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
  }
  /*submenu*/
}
@media (max-width: 1280px) and (max-width: 1024px) {
  .megamenu-style-dev {
    width: 50%;
  }
}
@media (max-width: 1280px) and (max-width: 767px) {
  .megamenu-style-dev {
    width: 100%;
  }
}
@media (max-width: 1280px) {
  .megamenu-style-dev.active {
    left: 0;
    visibility: visible;
    opacity: 1;
    -webkit-transition: left 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
    -moz-transition: left 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
    -o-transition: left 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
    transition: left 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
  }
  .megamenu-style-dev .megamenu-pattern {
    width: 100%;
    height: calc(100vh - var(--headerHeight) - 40px);
    overflow-y: auto !important;
    background-color: var(--white);
    overflow-x: hidden;
  }
}
@media (max-width: 1280px) and (max-width: 767px) {
  .megamenu-style-dev .megamenu-pattern {
    width: 100%;
  }
}
@media (max-width: 1280px) {
  .megamenu-style-dev .navbar-default .navbar-toggle {
    display: none;
    background-color: transparent;
    border: none;
    border-radius: 0;
    width: 30px;
    padding: 0;
    height: 34px;
    padding-top: 5px;
  }
  .megamenu-style-dev .navbar-default .navbar-toggle span {
    position: relative;
    display: block;
    width: 100%;
    height: 2px;
    margin-bottom: 5px;
    background-color: var(--black);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .megamenu-style-dev .navbar-default .navbar-toggle.active span:nth-child(2) {
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .megamenu-style-dev .navbar-default .navbar-toggle.active span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    top: 10px;
  }
  .megamenu-style-dev .navbar-default .navbar-toggle.active span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    bottom: 4px;
  }
  .megamenu-wrapper {
    display: block;
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    height: calc(100vh - var(--headerHeight));
    max-height: unset;
    background-color: #fff;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  .megamenu-wrapper ul.megamenu > li > .sub-menu {
    padding-top: 0;
  }
  .megamenu-wrapper.so-megamenu-active ul.megamenu .title-submenu,
  .megamenu-wrapper.so-vertical-active ul.megamenu .title-submenu {
    display: inline-block;
    max-width: unset;
    margin-top: 0;
    margin-bottom: 5px;
  }
  .megamenu-wrapper ul.megamenu .subcategory {
    margin-top: 10px;
  }
  .megamenu-wrapper .sub-menu {
    padding-bottom: 20px;
  }
  .megamenu-wrapper .sub-menu .content .static-menu .menu ul li {
    padding-top: 5px;
  }
  .megamenu-wrapper ul.megamenu li .sub-menu .content .static-menu .menu ul {
    margin-top: 0;
  }
  .container-megamenu.horizontal ul.megamenu > li,
  .container-megamenu.vertical ul.megamenu > li {
    border-bottom: 1px solid var(--secondary-color);
  }
}
.mobile-account-items-wrapper {
  display: none;
}
@media (max-width: 1280px) {
  .mobile-account-items-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--secondary-color);
  }
  .mobile-account-items-wrapper .mobile-account-item a {
    font-size: 2.4rem;
  }
  .mobile-account-items-wrapper .language-switcher {
    width: 100%;
  }
  .mobile-account-items-wrapper .language-switcher .btn-group .dropdown-menu button {
    font-size: 2.4rem;
    padding: 5px 15px;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
  }
  .mobile-account-items-wrapper .language-switcher button {
    font-size: 2.4rem;
  }
}

.mobile-menu-footer {
  display: none;
}
@media (max-width: 1280px) {
  .mobile-menu-footer {
    display: flex;
    padding-top: 38px;
    align-items: center;
  }
  .mobile-menu-footer .header-2 {
    font-size: 2.4rem;
    color: var(--secondary-color);
    margin-bottom: 17px;
  }
  .mobile-menu-footer .menu-social {
    align-items: center;
  }
  .mobile-menu-footer .menu-social a:first-child {
    margin-right: 20px;
  }
  .mobile-menu-footer .menu-social .social-icon {
    display: inline-block;
  }
  .mobile-menu-footer .menu-social .social-icon svg {
    display: inline-block;
  }
}

.body-cover, .body-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--black);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
}

body.menu-is-open {
  overflow: hidden;
}
body.menu-is-open .body-cover {
  top: var(--headerHeight);
  z-index: 99;
  opacity: 0.7;
  visibility: visible;
  transition: opacity 0.3s ease;
}
@media (max-width: 767px) {
  body.menu-is-open #product .button-container {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}
body.cart-is-open {
  overflow: hidden;
}
body.cart-is-open .body-cover {
  z-index: 99;
  opacity: 0.7;
  visibility: visible;
  transition: opacity 0.3s ease;
}
@media (max-width: 767px) {
  body.cart-is-open #product .button-container {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}

.megamenu-style-dev .megamenu-wrapper ul.megamenu li.with-sub-menu.click.active-show a .caret {
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  width: 16px;
  height: 9px;
}

.hero-slider {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
.hero-slider .slide-info {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.hero-slider .slide-info .header-1 {
  color: var(--white);
}
.hero-slider .slide-info .header-1:first-child {
  margin-bottom: 0;
}
.hero-slider .slide-info .default-cta {
  color: var(--white);
}
.hero-slider .slide-info .default-cta:after {
  background-color: var(--white);
}
.hero-slider .slide-info .hero-title {
  margin: 25px 0 40px;
}
.hero-slider .swiper-slide img {
  margin: 0 auto;
}
@media (max-width: 580px) {
  .hero-slider .swiper-slide img {
    object-fit: cover;
    width: 100%;
    height: calc(100vh - var(--headerHeight));
  }
}
.hero-slider .swiper-slide video {
  object-fit: cover;
  height: calc(100vh - var(--headerHeight));
}