body {
  padding-top: var(--headerHeight);
}
#main-header {
  .header-wrapper {
    justify-content: space-between;
    align-items: center;
    padding: clamp(30px, 37px, 1.927vw) $side_padding;
    @media (max-width: $laptop-lg) {
      padding: clamp(30px, 37px, 1.927vw) 15px;
    }
  }

  background-color: $white;
  z-index: 9;
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  &.hidden-top {
    top: calc(-1 * (var(--headerHeight)));
    -webkit-transition: top 0.7s ease;
    -moz-transition: top 0.7s ease;
    -o-transition: top 0.7s ease;
    transition: top 0.7s ease;
  }
  &.sticky-menu {
    top: 0;
    position: fixed;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
    width: 100%;
    left:0;
    border-bottom: 1px solid $primary_color;
  }
  .header-col {
    &:first-child, &:last-child {
      flex-basis: 40%;
      @media (max-width: $tablet) {
        flex-basis: 25%;
      }
    }
    flex-basis: 20%;
    align-items: center;
    @media (max-width: $tablet) {
      flex-basis: 50%;
    }
    &.logo {
      justify-content: center;
    }
    &.right-col {
      display: flex;
      justify-content: flex-end;
    }
  }
}
.menu-is-open {
  .hidden-top {
    top: 0!important;
  }
  .header-item {
    @media (max-width: $laptop-sm) {
      &.laptop-visible {
        z-index: 99;
      }
    }
  }
}
.header-item {
  &:not(:last-child) {
    margin-right: 27px;
    @media (max-width: $laptop-m) {
      margin-right: 20px;
    }
  }
  &.laptop-visible {
    display: none;
  }
  @media (max-width: $laptop-sm) {
    &.header-cart {
      margin-right: 0;
    }
    &.laptop-hidden {
      display: none;
    }
    &.laptop-visible {
      display: block;
      position: relative;
      z-index: 9999;
      .burger-menu {
        display: flex;
        width: 43px;
        flex-direction: column;
        .icon-bar {
          display: block;
          content: "";
          height: 2px;
          width: 100%;
          background-color: $secondary_color;
          -webkit-transition: transform 0.3s ease;
          -moz-transition: transform 0.3s ease;
          -o-transition: transform 0.3s ease;
          transition: transform 0.3s ease;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
          &.top, &.bottom {
            max-width: 26px;
            margin-left: auto;
            margin-right: auto;

          }
        }
        &.active {
          .icon-bar {
            &.top {
              max-width: 100%;
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg);
              margin: 0;
              position: relative;
              top: 5px;
            }
            &.middle {
              opacity: 0;
            }
            &.bottom {
              max-width: 100%;
              -webkit-transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              transform: rotate(-45deg);
              margin: 0;
              position: relative;
              top: -9px;
            }
          }
        }
      }
    }
  }

  a, button {
    font-family: $primary_font;
    font-size: 2.6rem;
    letter-spacing: 0.22em;
    text-transform: uppercase;
    color: $secondary_color;
    transition: color .3s ease;
    background-color: transparent;
    border: none;
    @media (max-width: $laptop-lg) {
      font-size: 2.2rem;
    }
    @media (max-width: $tablet-lg) {
      font-size: 2rem;
    }
    @media (max-width: $mobile-sm) {
      font-size: 1.6rem;
    }
    &:hover {
      color: $primary_color;
      transition: color .3s ease;
    }
  }
}

.logo-icon {
  display: block;
  width: 100%;
  max-width: 206px;
  svg {
    display: block;
    width: 100%;
    max-width: 206px;
  }
  @media (max-width: $laptop-lg) {
    max-width: 180px;
    svg {
      max-width: 180px;
    }
  }
  @media (max-width: $tablet-lg) {
    max-width: 160px;
    svg {
      max-width: 160px;
    }
  }
  @media (max-width: $tablet-sm) {
    max-width: 140px;
    svg {
      max-width: 140px;
    }
  }
  @media (max-width: 350px) {
    max-width: 120px;
    svg {
      max-width: 120px;
    }
  }
}

.language-switcher {
  .btn-group {
    position: relative;
    button {
      background-color: transparent;
      border: none;
      text-transform: uppercase;
      padding-right: 30px;
      .fa {
          position: absolute;
          top: 50%;
          right: 0;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%);
        &::before {
            display: flex;
            content: '';
            width: 16px;
            height: 9px;
            background-image: url('../../../icons/arrow-down.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 16px 9px;
        }
      }
    }
    .dropdown-menu {
      min-width: 35px;
      left: unset;
      right: 0;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      border: none;
      border-radius: 0;
      background-color: transparent;
      @media (max-width: $laptop-sm) {
        background-color: $white;
        right: unset;
      }
      button {
        color: $primary_color;
        font-size: 3rem;
        letter-spacing: 0.22em;
        &:hover {
          text-decoration: none;
        }
      }
    }
    &.open {
      .dropdown-toggle {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }
  }
}