.products-block {
    display: inline-flex;
    flex-wrap: wrap;
    min-width: 100%;

    @media screen and (min-width: ($tablet + 1)) {
        &__category {
            background-image: var(--graphic);
            background-repeat: no-repeat;
            background-position: 50% 40px;
            background-size: vw(1459px);
            min-height: calc(vw(787px) + 40px);
        }
    }

}

.product-col {
    width: calc(33.333% - ((vw(93px) / 3) * 2));
    margin-left: vw(93px);
    margin-bottom: max(40px, vw(70px));

    @media screen and (max-width: $tablet) {
        margin-left: 20px;        
        width: calc(50% - 10px);

        &:nth-child(2n+1) {
            margin-left: 0;
        }
    }

    @media screen and (max-width: $tablet-sm) {
        margin-left: 20px;
        width: calc(50% - 10px);
        max-width: calc(50% - 10px);

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: ($tablet + 1)) {
        &:nth-child(3n+1) {
            margin-left: 0;
        }
    }

    @media screen and (max-width: $tablet-sm) {

        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(11),
        &:nth-child(12) {
            min-width: 100%;
            margin-left: 0;

            .caption {
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }

            .price,
            .header-3 {
                order: unset !important;
            }
        }
    }

}

.product-thumb {
    &:hover {
        .caption {
            * {
                color: $gold !important;
            }
        }

        .image {
            &:after {
                background-color: rgba(34, 34, 34, 0.25);
            }
        }
    }

    .sale_badge {
        display: none;
    }

    .image {
        position: relative;

        &:after {
            content: "";
            background-color: transparent;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: background-color .3s ease-in-out;
            pointer-events: none;
        }

        img {
            min-width: 100%;
        }

        .add-to-cart {
            .cart-icon {}
        }

        .add-to-wishlist {
            display: none !important;

            .add-to-wishlist-icon {}
        }
    }

    .caption {
        display: flex;
        justify-content: space-between;
        padding-top: 30px;
        padding-bottom: 3px;

        @media screen and (max-width: $tablet) {
            padding-top: 11px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: unset;
        }

        .header-3 {
            margin-bottom: 0;            
            line-height: 20px;

            @media screen and (max-width: $tablet) {
                order: 2;        
                line-height: 14px;
            }

            a {
                @include font($secondary_font, 22, 400, normal, 22);
                letter-spacing: 0.01em;
                display: inline-flex;
                text-align: left;
                padding-right: 15px;
                color: $dark_blue;

                @media screen and (max-width: $tablet) {
                    @include font($font-size: 16);
                }
            }
        }

        .product-description {
            display: none;
        }

        .price {
            @include font($secondary_font, 22, 400, normal, 22);
            letter-spacing: 0.01em;
            color: $dark_blue;

            @media screen and (max-width: $tablet) {
                @include font($font-size: 16);
                order: 1;
            }


            &>div {
                display: inline-flex;

                * {
                    color: $dark_blue;
                }
            }

            .price-new {
                color: $dark_blue;
                order: 2;
                font-weight: initial;
            }

            .price-old {
                order: 1;
                text-decoration: line-through;
                font-size: 75%;
            }

            .discount-percentage {
                display: none;
            }

            &>* {
                * {
                    color: $dark_blue;
                }
            }

            .price-tax {}
        }
    }
}