.counter-pagination {
    justify-content: center;
    align-items: center;
    padding-top: max(calc(77px - 40px), calc(vw(150px) - 70px));
    padding-right: 0;
    padding-bottom: max(71px, vw(200px));
    padding-left: 0;

    .pagination {

        &._front {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-top: 0;
            margin-bottom: 0;

            li,
            a {
                &:hover {
                    color: $gold;
                    background-color: unset;
                    border-color: unset;
                }
            }

            li {
                display: none;
                // padding: 12px 0;

                span,
                a {
                    padding: 0;
                    @include font($secondary_font, 24, 400, normal, 30);
                    letter-spacing: 0.01em;
                    text-align: center;
                    color: $dark_blue;

                    @media screen and (max-width: $tablet) {
                        @include font($font-size: 16);
                    }
                }

                span {
                    padding: 0;

                    &:hover {
                        @include font($secondary_font, 24, 400, normal, 30);
                        letter-spacing: 0.01em;
                        color: $dark_blue;
                        background-color: transparent;
                        border-color: transparent;

                        @media screen and (max-width: $tablet) {
                            @include font($font-size: 16);
                        }
                    }
                }

                &.active {
                    &:not(:last-child) {
                        span {
                            &:after {
                                padding: 0 5px;
                                content: "/";
                                display: inline-flex;
                                color: $dark_blue !important;
                            }
                        }
                    }
                }

                &.active,
                &._total,
                &._prev-container,
                &._next-container {
                    display: inline-flex;
                }

                &.active+._dummy {
                    display: inline-flex;
                }

                &._prev-container {
                    padding-right: vw(70px);

                    a {
                        content: url('../../../icons/pagination-arrow-left.svg');

                        @media screen and (max-width: $tablet) {
                            max-width: 41px;
                        }
                    }
                }

                &._next-container {
                    padding-left: vw(70px);

                    a {
                        content: url('../../../icons/pagination-arrow-right.svg');

                        @media screen and (max-width: $tablet) {
                            max-width: 41px;
                        }
                    }
                }
            }

        }
    }
}