input[type='text'],
input[type='password'],
input[type='tel'],
input[type='email'],
textarea,
select,
label.control-label,
input[type='text'].form-control,
input[type='password'].form-control,
input[type='tel'].form-control,
input[type='email'].form-control,
textarea.form-control,
select.form-control,
.radio label,
.checkbox label,
label.control-label {
    font-size: $input_fs;
    font-weight: 400;
    color: $primary_color;
}

input[type='text'],
input[type='password'],
input[type='tel'],
input[type='email'],
textarea,
input[type='text'].form-control,
input[type='password'].form-control,
input[type='tel'].form-control,
input[type='email'].form-control,
textarea.form-control,
select.form-control {
    border: 1px solid $border_color;
    padding: 17px 22px;
    height: unset;
    line-height: 1.5;
    background-color: transparent;
    box-shadow: none;
    border-radius: $border_radius_input;

    &::placeholder {
        color: $placeholder_color;
        font-weight: 300;
    }

    &:focus {
        border: 1px solid $border_color_focus;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        border: 1px solid $primary_color;
        -webkit-text-fill-color: $primary_color;
    }
}
select.form-control {
    background-color: transparent;
    color: $secondary_color;
    -webkit-appearance: none;
    cursor: pointer;
    background-image: url('../../../icons/arrow-down.svg');
    background-repeat: no-repeat;
    background-position: 95% 50%;
    background-color: transparent;
    padding: 17px 40px 17px 22px;
}
// Bootstrap default
.form-group.required .control-label:after {
    content: ' *';
    color: red;
}

/* CHECKBOX STRACTURE */
$checkbox-size: 18px;
$checkbox-border-color: $gold;
$checkbox-border-width: 1px;
$checkbox-border-radius: 2px;
$checkbox-background: transparent;
$checkbox-check-color: $gold;

/* CHECKBOX */
input[type='checkbox'] {
    display: inline-grid !important;
    place-content: center;
    cursor: pointer;
    width: $checkbox-size;
    height: $checkbox-size;
    border: $checkbox-border-width solid $checkbox-border-color;
    -webkit-appearance: none;
    appearance: none;
    background-color: $checkbox-background;
    margin: 0;
    margin-right: 9px;
    border-radius: $checkbox-border-radius;
    top: -1px;
    position: relative;

    &:disabled {
        cursor: not-allowed;
    }

    &:before {
        content: '';
        width: 8px;
        height: 8px;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        transform: scale(0);
        transform-origin: bottom left;
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em $checkbox-check-color;
    }

    &:checked {
        &:before {
            transform: scale(1);
        }
    }
}

/* RADIO STRACTURE */
$radio-size: 18px;
$radio-inner-size: 12px;
$radio-border-color: $gold;
$radio-border-width: 1px;
$radio-border-radius: 50%;
$radio-background: transparent;
$radio-check-color: $dark_blue;

/* RADIO */
input[type="radio"] {
    &:not(#guest):not(#register) {
        position: relative !important;
        display: inline-grid !important;
        place-content: center;
        cursor: pointer;
        width: $radio-size;
        height: $radio-size;
        border: $radio-border-width solid $radio-border-color;
        -webkit-appearance: none;
        appearance: none;
        background-color: $radio-background;
        margin: 0;
        margin-right: 9px;
        border-radius: $radio-border-radius;
    
        &:before {
            content: "";
            width: $radio-inner-size;
            height: $radio-inner-size;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em $radio-check-color;
        }
    
        &:checked {
            &:before {
                transform: scale(1);
            }
        }
    }
}

.radio-input {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    label {
        margin-bottom: 0;
    }
}

input:focus::-webkit-input-placeholder { color:transparent !important; }
input:focus:-moz-placeholder { color:transparent !important; }
input:focus::-moz-placeholder { color:transparent !important; }
input:focus:-ms-input-placeholder { color:transparent !important; }