.hero-slider {
    position: relative;
    width: 100%;
    margin: 0 auto;
    .slide-info {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        .header-1 {
            color: $white;
            &:first-child {
                margin-bottom: 0;
            }
        }
        .default-cta {
            color: $white;
            &:after {
                background-color: $white;
            }
        }
        .hero-title {
            margin: 25px 0 40px;
        }
    }
    .swiper-slide {
        img {
            margin: 0 auto;
            @media (max-width: $mobile-m) {
                object-fit: cover;
                width: 100%;
                height: calc(100vh - var(--headerHeight));
            }
        }
        video {
            object-fit: cover;
            height: calc(100vh - var(--headerHeight));
        }
    }
}
