$footer-bp: $tablet;
footer {
    .footer-wrap {
        display: flex;
        flex-direction: column;
        background-color: $footer_background_color;
        color: $white;

        .footer-logo {
            display: flex;
            justify-content: center;
            margin-top: vw(92px);
            margin-bottom: vw(137px);

            @media screen and (max-width: $footer-bp) {
                margin-top: 66px;
                margin-bottom: 47px;
            }
            .footer-logo-icon {
                display: block;
                width: 246px;
                svg {
                    display: block;
                    max-width: 246px;
                }

                @media screen and (max-width: $footer-bp) {
                    width: 160px;
                    svg {
                        width: 160px;
                    }
                }
            }

        }

        .footer-column-wrap {
            display: flex;
            padding: 0 var(--side-padding);
            padding-bottom: 55px;

            @media screen and (max-width: $footer-bp) {
                flex-direction: column;
                align-items: center;
                padding-left: 10px;
                padding-right: 10px;
            }

            .footer-column {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 33.333%;

                @media screen and (max-width: $footer-bp) {
                    width: 100%;
                    order: 2;
                    align-items: center;
                    justify-content: center;
                }

                &--mob {
                    order: 4;

                    @media screen and (min-width: ($footer-bp + 1)) {
                        display: none;
                    }
                }

                &:nth-child(2) {
                    align-items: center;

                    @media screen and (max-width: $footer-bp) {
                        order: 1;
                    }
                }

                &:nth-child(3) {
                    align-items: flex-end;

                    @media screen and (max-width: $footer-bp) {
                        order: 3;
                        align-items: center;
                    }
                }

                .column-block {

                    @media screen and (max-width: $footer-bp) {
                        display: flex;
                        flex-direction: column;

                        &--dsk {
                            display: none;
                        }
                    }
                    &.mobile-toggle {
                        &.active {
                            .open-footer-data {
                                -moz-transform: rotate(180deg);
                                -o-transform: rotate(180deg);
                                transform: rotate(180deg);
                                -webkit-transition: 0.3s ease;
                                -moz-transition: 0.3s ease;
                                -o-transition: 0.3s ease;
                                transition: 0.3s ease;
                            }
                        }
                    }

                    .column-head {
                        display: flex;
                        @include font($primary_font, 30, 500, normal, 37);
                        letter-spacing: 0.22em;
                        color: $white;
                        margin-bottom: 28px;
                        @media (max-width: $tablet) {
                            align-items: center;
                            justify-content: center;
                        }

                        .open-footer-data {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 16px;
                            height: 9px;
                            svg {
                                display: flex;
                                width: 16px;
                                height: 9px;
                                .st0 {
                                    fill: #fff;
                                }
                            }
                        }


                        @media screen and (max-width: $footer-bp) {
                            @include font($font-size: 24, $line-height: 30);
                            text-align: center;
                            margin: 15px 0;
                        }
                    }

                    .column-data {
                        display: flex;
                        flex-direction: column;

                        &>* {
                            &:not(:last-child) {
                                margin-bottom: 17px;
                                @media (max-width: $laptop-sm) {
                                    margin-bottom: 7px;
                                }
                            }
                        }

                        a {
                            @include font($secondary_font, 24, 400, normal, 30);
                            letter-spacing: 0.01em;
                            color: $white;

                            @media screen and (max-width: 1280px) {
                                @include font($font-size: 20);
                                margin-bottom: 10px;
                            }
                            
                            @media screen and (max-width: $footer-bp) {
                                text-align: center;
                            }
                        }

                        .social {
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            margin-bottom: 78px;
                            @media (max-width: $tablet) {
                                margin-bottom: 30px;
                            }
                            a {
                                &:first-child {
                                    margin-right: 30px
                                }
                            }
                        }

                        .payment-types {
                            display: flex;
                            flex-direction: column;
                            min-width: 211px;

                            @media screen and (max-width: $footer-bp) {
                                display: inline-flex;
                                flex-direction: row;
                                margin-bottom: 55px;
                            }

                            span {
                                &:not(:last-child) {
                                    margin-bottom: 30px;
                                }

                                @media screen and (max-width: $footer-bp) {
                                    margin-bottom: 0!important;
                                    width: 35%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                            }
                        }
                    }

                    .moto {
                        @include font($primary_font, 150, 500, normal, 186);
                        letter-spacing: 0.22em;
                        text-transform: uppercase;
                        text-align: center;
                        display: inline-flex;
                        flex-direction: column;
                        margin-bottom: 52px;

                        @media screen and (max-width: 1536px) {
                            @include font($font-size: 120, $line-height: 144);
                        }

                        @media screen and (max-width: 1280px) {
                            @include font($font-size: 100, $line-height: 122);
                        }
                        
                        @media screen and (max-width: $footer-bp) {
                            @include font($font-size: 80, $line-height: 99);
                        }

                        @media screen and (min-width: ($footer-bp + 1)) {
                            span {
                                &:last-child {
                                    margin-top: -30px;
                                }
                            }
                        }
                    }

                    &.footer-newsletter-wrap {
                        width: 100%;

                        form {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            max-width: 420px;
                            margin-bottom: 87px;
                            margin-left: auto;
                            margin-right: auto;

                            @media screen and (max-width: $footer-bp) {
                                margin-bottom: 22px;
                            }

                            input {
                                @include font($secondary_font, 24, 400, normal, 30);
                                letter-spacing: 0.01em;
                                padding: 14px 5px;
                                margin-bottom: 40px;
                                background-color: transparent;
                                text-align: center;
                                color: $white;
                                border-color: $white !important;

                                @media screen and (max-width: $footer-bp) {
                                    @include font($font-size: 20);
                                    margin-bottom: 20px;
                                }

                                &::placeholder {
                                    color: $white;
                                    opacity: 1;
                                }

                                &:-ms-input-placeholder {
                                    color: $white;

                                }

                                &::-ms-input-placeholder {
                                    color: $white;
                                }
                            }

                            button {
                                @include font($secondary_font, 24, 400, normal, 30);
                                letter-spacing: 0.01em;
                                border: none;
                                background-color: $white;
                                color: $gold;
                                border-radius: 40px;
                                padding: 14px 5px;

                                @media screen and (max-width: $footer-bp) {
                                    @include font($font-size: 20);
                                }
                            }

                            #subscribe_result {
                                .alert-success {
                                    @include font($secondary_font, 16, 400, normal, 30);
                                    letter-spacing: 0.01em;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    margin-top: 10px;
                                    border-radius: 40px;
                                    padding: 14px 5px;
                                    background: $white;
                                    color: $white;
                                    background-color: $black;
                                }
                                .alert-danger {
                                    @include font($secondary_font, 16, 400, normal, 30);
                                    letter-spacing: 0.01em;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    margin-top: 10px;
                                    border-radius: 40px;
                                    padding: 14px 5px;
                                    background: $white;
                                    color: $white;
                                    background-color: #a94442;
                                }
                                .alert-danger {
                                    @include font($secondary_font, 16, 400, normal, 30);
                                    letter-spacing: 0.01em;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    margin-top: 10px;
                                    border-radius: 40px;
                                    padding: 14px 5px;
                                    background: $white;
                                    color: $white;
                                    background-color: #a94442;
                                }
                            }
                        }
                    }

                    .copyright {
                        @include font($secondary_font, 20, 400, normal, 20);
                        letter-spacing: 0.01em;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        color: $white;
                        @media (max-width: $laptop-sm) {
                            font-size: 1.6rem;
                        }
                        .credits {
                            display: flex;
                            align-items: center;
                        }
                        @media screen and (max-width:$footer-bp) {
                            @include font($font-size: 16, $line-height:22);
                        }

                        &>span {
                            &:first-child {
                                margin-bottom: 14px;
                            }
                        }

                        svg {
                            max-height: 20px;
                            min-width: 61px;
                            display: inline-flex;
                            fill: $white;

                            @media screen and (max-width:$footer-bp) {
                                max-height: 18px;
                                min-width: 49px;
                            }
                        }
                    }
                }
            }
        }
    }

    .footer-sub-image {
        position: sticky;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: -1;
        background-color: $white;
        height: vw(840px);
        background-image: var(--footer-graphic);
        background-size: vw(662px);
        background-repeat: no-repeat;
        background-position: 50% 50%;

        @media screen and (max-width: $footer-bp) {
            min-height: 400px;
            background-size: 340px;
        }

        @media screen and (max-width: $footer-bp) and (min-height: 550px) {
            min-height: calc(100vh - 170px);
        }

        @media screen and (max-width: 360px) {
            background-size: vw-rs(360, 340px);
        }
    }
}


@media (min-width: 992px) {
    .open-footer-data {
        display: none!important;
    }
}
.company_name {
    display: inline-block;
    max-height: 18px;
    .eight-logo-icon {
        display: inline-block;
        width: 49px;
        height: 18px;
        svg {
            display: inline-block;
            width: 49px;
            height: 18px;
        }
    }
}
