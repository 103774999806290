/*========================================================*/
/* Product Page */
/*========================================================*/
.product-container {
    background-color: $light_brown;
    padding: clamp(80px, 6.5104vw, 125px) var(--side-padding) 70px;
}
.product-row {
    max-width: 1540px;
    width: 100%;
    margin: 0 auto 70px;
    justify-content: space-between;
    @media (max-width: $tablet) {
        flex-direction: column;
        margin: 0 auto 35px;
    }
    .right-col {
        width: 45%;
        margin: 0 0 0 15px;
        @media (max-width: $tablet) {
            width: 100%;
            margin: 0 0 25px;
        }
        @media (max-width: $tablet-sm) {
            margin: 0;
        }
    }
    .left-col {
        width: 55%;
        margin-right: 15px;
        @media (max-width: 1540px) {
            width: 52%;
        }
        @media (max-width: $laptop) {
            width: 50%;
        }
        @media (max-width: $tablet) {
            width: 100%;
            margin: 0 0 25px;
        }
        .product-thumbnails {
            width: 100%;
            max-width: 760px;
            @media (max-width: $tablet) {
                margin: 0 auto;
                .thumb {
                    margin: 0 auto;
                }
            }
        }
    }
}

/*Product images*/
.product-thumbnails-slider {
    padding-bottom: 40px !important;
    .product-thumbs-pagination {
        bottom: 0 !important;
        .swiper-pagination-bullet-active {
            background-color: $dark_blue;
        }
        .swiper-pagination-bullet {
            width: 14px;
            height: 14px;
            margin: 0 7px !important;
        }
    }
}
.product-related-pagination {
    bottom: -50px !important;
    @media (max-width: $tablet-sm) {
        display: none;
    }
    .swiper-pagination-bullet-active {
        background-color: $dark_blue;
    }
    .swiper-pagination-bullet {
        width: 14px;
        height: 14px;
        margin: 0 7px !important;
    }
}

/*Product details*/
.product-header {
    .header-1,
    .header-2 {
        margin: 0;
    }
    @media (max-width: $tablet) {
        max-width: unset;
        text-align: center;
        margin: 0 auto;
    }
}
.product-description {
    margin: 0 0 35px;
}

.price-list {
    margin: 30px auto;
    li {
        display: inline-block;
        padding-right: 15px;
    }
    @media (max-width: $tablet) {
        text-align: center;
        margin: 25px auto;
    }
}
.product-price {
    font-size: 5.8rem;
    font-weight: 500;
    &.old {
        font-size: 4.8rem;
        font-weight: 400;
        text-decoration: line-through;
        color: $primary_color;
        opacity: 0.5;
    }
    @media (max-width: 1540px) {
        font-size: 5rem;
        &.old {
            font-size: 4rem;
        }
    }
    @media (max-width: $laptop) {
        font-size: 4.5rem;
        &.old {
            font-size: 3.5rem;
        }
    }
    @media (max-width: $tablet-sm) {
        font-size: 2.4rem;
        &.old {
            font-size: 2rem;
        }
    }
}

.product-badges-container {
    border-top: 1px solid $primary_color;
    border-bottom: 1px solid $primary_color;
    padding: 20px;
    margin: 35px 0;
    .product-badges-wrapper {
        justify-content: center;
    }
    .product-badge {
        display: flex;
        flex-direction: column;
        align-items: center;
        &:not(:last-child) {
            margin:  0 45px 0 0;
            @media (max-width: $tablet-sm) {
                margin:  0 20px 0 0;
            }
        }
        span {
            margin: 0 auto 3px;
        }
        p {
            font-size: 2rem;
            font-family: $primary_font;
            color: $primary_color;
            margin: 0;
            text-align: center;
        }
    }
}

.product-available-colors {
    display: none;
    .color {
        display: block;
        margin-bottom: 10px;
        font-size: 1.1rem;
        font-weight: 600;
    }
    .flex-row {
        flex-wrap: wrap;
    }
    .flex-row li {
        max-width: 60px;
        width: 100%;
        margin-right: 10px;
        margin-bottom: 10px;
    }
    .flex-row li.selected {
        border: 1px solid #000;
    }
}

.select-options .control-label {
    font-size: 1.2rem;
    font-weight: 600;
}
.quantity-input-field {
    position: relative;
    .number-input {
        align-items: center;
        p {
            margin: 0 20px 0 0;
            @media (max-width: $laptop-m) {
                margin: 0 0 10px 0;
            }
        }
        @media (max-width: $laptop-m) {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    .quantity {
        position: relative;
        input[type='text'].form-control {
            max-width: 120px;
            width: 100%;
            border: 1px solid $secondary_color;
            border-radius: $border_radius_input;
            margin: 0;
            font-size: 1.6rem;
            color: $secondary_color;
            text-align: center;
            font-weight: 400;
            padding: 17px 5px;
            background: transparent;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            outline: none;
        }
        .quantity-nav {
            position: absolute;
            top: 50%;
            left: 0;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            width: 100%;
            justify-content: space-between;
            align-items: center;
            padding: 0 15px;
        }
    }
}
#product {
    .btn-default {
        width: 100%;
        letter-spacing: 0.22em;
    }
}
.quantity-options-container {
    justify-content: space-between;
    align-items: center;
    margin: 35px 0;
    > div:first-child {
        margin: 0 15px 0 0;
    }
    select {
        max-width: 250px;
    }
    @media (max-width: $laptop-m) {
        justify-content: unset;
        > div:first-child {
            margin: 0 30px 0 0;
            @media (max-width: $mobile-sm) {
                margin: 0 15px 0 0;
            }
        }
    }
    @media (max-width: $tablet-sm) {
        margin: 0 0 35px 0;
    }
}

.single-options {
    align-items: center;
    p {
        margin: 0 20px 0 0;
        @media (max-width: $laptop-m) {
            margin: 0 0 10px 0;
        }
    }
    @media (max-width: $laptop-m) {
        flex-direction: column;
        align-items: flex-start;
    }
}
.single-options-wrapper {
    .form-group {
        margin-bottom: 0;
    }
}
.select-options {
    .quantity {
        position: relative;
    }
    .quantity-nav {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 25px;
        span {
            display: inline-block;
            svg {
                display: inline-block;
                width: 14px;
                height: 8px;
            }
        }
        .quantity-button {
            padding: 5px 7px;
            cursor: pointer;
        }
    }
}
.custom-cake-option {
    margin: 0 0 20px;
    label {
        font-size: 2.4rem;
        color: $primary_color;
        margin: 0 0 15px;
        font-weight: 500;
        font-family: $primary_font;
        letter-spacing: 0.02em;
        width: 100%;
        @media (max-width: $tablet) {
            font-size: 2.4rem;
        }
    }
    select,
    input {
        max-width: unset;
        width: 100%;
        background-position: 95% 50%;
        font-size: 1.6rem;
        @media (max-width: $mobile) {
            padding: 17px 40px 17px 15px;
        }
    }
    textarea {
        font-size: 1.6rem;
    }
    .date {
        margin: 0 0 20px;
        input {
            font-size: 1.6rem;
            border-right: none;
            color: $secondary_color;

        }
        .btn-default {
            background-color: transparent;
            border-left: none;
            padding: 17px 20px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 60px;
        }
        + p {
            font-size: 1.6rem;
            margin: 0;
        }
    }
    &.file {
        .btn-default {
            font-size: 1.6rem;
            padding: 17px 22px;
            background-color: transparent;
            border-color: var(--secondary-color);
            color: var(--secondary-color);
            max-height: 60px;
            line-height: 1.2;
        }
    }
}

.free-delivery-msg {
    font-size: 1.2rem;
    display: block;
    margin: 40px 0;
}

/*related products*/
.related-products {
    margin: 0 auto clamp(120px, 11.1979vw, 215px);
    position: relative;
    @media (max-width: $tablet-sm) {
        margin: 0 auto;
        padding-bottom: 45px;
        .related-products-slider {
            .swiper-wrapper {
                justify-content: space-between;
                display: flex;
                flex-wrap: wrap;
            }
            .swiper-slide {
                flex-basis: calc(50% - 10px);
                margin-bottom: 40px;
            }
        }
    }
    > .header-1,
    > .header-2 {
        color: $primary_color;
    }
    > .header-1 {
        margin: 0 auto clamp(45px, 4.4271vw, 85px);
    }
}

.share-product-container {
    margin: 0 auto clamp(50px, 7.5521vw, 145px);
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    .share-icon {
        padding: 6px;
        margin-left: 10px;
    }
}

/*add to cart message*/
@keyframes note {
    0% {
        margin-top: 25px;
        opacity: 0;
    }
    10% {
        margin-top: 0;
        opacity: 1;
    }
    90% {
        margin-top: 0;
        opacity: 1;
    }
    100% {
        margin-top: 25px;
        opacity: 0;
    }
}
.popup-alert {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 50px;
    margin-bottom: 0;
    background-color: #fff;
    color: #000;
    border: none;
    border-radius: 0;
    z-index: 9999;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    animation: note 6500ms forwards;
}
.popup-alert a,
.popup-alert a:hover {
    color: #000;
    font-weight: 500;
}
.share-fb {
    display: inline-block;
    width: 11px;
    height: 20px;
    svg {
        display: inline-block;
        width: 11px;
        height: 20px;
    }
}

@media (max-width: $laptop-sm) {
    /*checkout page*/
    .checkout-container.container {
        width: 100%;
    }
    .navbar {
        padding: 15px;
    }
    .checkout-logo span.logo-icon svg,
    .checkout-logo span.logo-icon {
        width: 220px;
        height: auto;
    }
}

@media (max-width: $tablet-sm) {
    /*product*/
    .product-container {
        padding: 20px 10px;
    }

    .product-thumbnails .thumb {
        margin-bottom: 0;
    }
    .product-thumbnails .thumb:nth-child(even),
    .product-thumbnails .thumb:nth-child(odd) {
        padding: 0;
    }
    .product-thumbnails .slick-slide img {
        margin: 0 auto;
    }
    .product-row .slider-arrows {
        display: block;
        top: 0;
        height: 100%;
        -webkit-transform: unset;
        -moz-transform: unset;
        -o-transform: unset;
        transform: unset;
    }
    .product-row .slider-arrows .arrow {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 70px;
    }
    .product-row .slider-arrows .arrow.arrow-prev {
        left: 0;
    }
    .product-row .slider-arrows .arrow.arrow-next {
        right: 0;
    }
    .product-row .slider-arrows .arrow svg {
        fill: #000;
    }
    .products-block .product-thumb {
        margin-bottom: 15px;
    }
    .flex-row.category-pagination {
        padding: 15px 0;
    }
    .popup-alert {
        padding: 15px;
        width: 90%;
    }
}

.carousel-wrapper .slick-dots {
    position: absolute;
    left: 50%;
    bottom: -10px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}
.carousel-wrapper .slick-dots li {
    display: inline-block;
    margin: 0 10px;
}
.carousel-wrapper .slick-dots li button {
    font-size: 0;
    width: 10px;
    height: 10px;
    display: inline-block;
    padding: 0;
    background-color: #c8c8c9;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: none;
}
.carousel-wrapper .slick-dots li.slick-active button {
    background-color: #000;
}
.btn-candles-a:hover{
    cursor: pointer;
    color: #fff !important;
}
#birthday-candles{
    width: 18px !important;
    padding: 10px !important;
}

.btn-candles-a.btn-primary:hover{
    color: #fff !important;
}

.btn-candles-a.btn-primary{
    color: var(--white);
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: var(--button-border-radius);
    padding: 10px;
}

.product-thumb:hover .btn-candles-a.btn-primary {
    color: #fff !important;
}