/*========================================================*/
/* Reset */
/*========================================================*/
*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
*:focus {
    outline: none !important;
}

::selection {
    background: $primary_color;
    color: $light_blue;
}

html {
    font-size: 62.5%;
    line-height: 1.5;
    scroll-behavior: smooth;
}

body {
    font-family: $secondary_font;
    font-size: $text_fs;
    color: $dark_blue;
    overflow-x: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    line-height: 1.5;
    @media (max-width: $tablet) {
        &.cart-is-open {
            overflow: hidden;
        }
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: unset;
    color: $black;
}

a,
a:hover,
a:focus {
    text-decoration: none;
    color: inherit;
}

b,
strong {
    font-weight: 600;
}

ul {
    margin: 0;
    padding: 0;
    li {
        list-style-type: none;
    }
}

img,
picture {
    display: block;
    max-width: 100%;
    border: none;
}

svg {
    display: inline-block;
}
.desktop-view {
    display: block;
    @media (max-width: $mobile) {
       display: none;
    }
}
.mobile-view {
    display: none;
    @media (max-width: $mobile) {
        display: block;
    }
}