$checkout-bp: 991px;
$inBetween-bp: 1370px;

.checkout-container {

    .container {
        padding-left: 0;
        padding-right: 0;

        .row {
            margin-left: 0;
            margin-right: 0;

            &>* {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    #main-header,
    #search {
        display: none;
    }

    #d_logo {
        display: none;
        padding: clamp(15px, 2.6042vw, 50px) $side_padding_header;

        a {
            display: inline-block;
            margin: 0 auto;
        }
    }

    a {
        color: $secondary_color;
    }

    .checkout-left {
        padding-right: $side_padding_checkout;

        @media (max-width: $checkout-bp) {
            border-right: unset;
            padding-right: 0;
            padding-left: 0;
        }
    }

    .checkout-right {
        padding-left: $side_padding_checkout;

        @media (max-width: $checkout-bp) {
            padding-right: 0;
            padding-left: 0;
        }
    }

    @media screen and (min-width: $checkout-bp) {

        .checkout-left,
        .checkout-right {
            margin: 0 !important;
            padding: 0 !important;
        }

        .checkout-left {
            max-width: vw(719px);
            width: 100%;
            border-right: unset;

            @media screen and (max-width: 1440px) {
                max-width: 50%;
            }
        }

        .checkout-right {
            max-width: vw(830px);
            width: 100%;
            right: -40px;

            @media screen and (max-width: 1440px) {
                right: -20px;
                max-width: calc(50% - 20px);
            }
        }
    }

    #d_quickcheckout {

        padding-left: vw(166px);
        padding-right: vw(166px);
        width: 100%;

        @media screen and (max-width: 1440px) {
            padding-left: var(--side-padding);
            padding-right: var(--side-padding);
        }

        @media screen and (max-width: $inBetween-bp) {
            padding-left: 15px;
            padding-right: 15px;
        }

        @media screen and (max-width: $mobile) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    // Bootstrap fixes
    .form-horizontal {

        .radio,
        .checkbox,
        .radio-inline,
        .checkbox-inline {
            padding-top: 0;
        }

        @media (max-width: $checkout-bp) {
            .form-group {
                margin-right: 0;
                margin-left: 0;
            }
        }
    }

    .well {
        box-shadow: none;

        @media screen and (max-width: $checkout-bp) {
            text-align: center;
        }
    }

    #qc_confirm_order {
        padding: 15px;
        margin-bottom: 50px;
        @include font($primary_font, 24, 500, normal, 30);
        letter-spacing: 0.16em;

        @media screen and (max-width: $checkout-bp) {
            letter-spacing: 0.22em;
        }


        &:disabled {
            color: $border_color;
            background-color: $white;
            border-color: $border_color;
            transition: all linear 0.3s;
        }

        @media (max-width: $checkout-bp) {
            padding: 15px;
        }
    }

    .text-danger {
        margin: 0 !important;
    }

    button {

        &.delete {
            background-color: unset;
            background-image: none;
            background-repeat: no-repeat;
            border: none;
            border-color: unset;
        }
    }

    .panel {
        border-radius: 0;
        border: none;
        background: none;
        box-shadow: none;

        @media (max-width: $checkout-bp) {
            margin-bottom: 0;
        }

        .panel-body {
            padding: 15px 0;

            @media (max-width: $checkout-bp) {
                padding: 0;
            }
        }

        .panel-heading {
            background-color: unset;
            border-radius: 0;
            border: none;
            padding: 10px 0;

            @media (max-width: $checkout-bp) {
                padding: 0;
            }
        }
    }

    #payment_address_heading_heading>i {
        display: none;
    }

    .btn {
        box-shadow: none !important;
        text-shadow: none !important;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    // Guest / Login / Register
    #login_view .guest-login-register {
        display: flex;
        flex-wrap: wrap;
    }

    #cart_view {
        .qc-name a {
            text-decoration: none;
        }

        .qc-coupon {
            .input-group {
                display: inline-flex;
                width: 100%;
                padding-top: 20px;
                padding-bottom: 40px;
                border-bottom: 1px solid $light-blue;

                @media screen and (max-width: $checkout-bp) {
                    flex-direction: column;
                }

                * {
                    border-radius: 40px !important;

                    @media screen and (max-width: $checkout-bp) {
                        min-width: 100%;
                    }
                }

                input {
                    width: 100% !important;
                    height: unset !important;
                    display: inline-flex !important;
                    justify-content: center;
                    color: $secondary_color;
                    margin-right: vw(23px);
                }

                span {
                    width: auto;
                    @include font($primary_font, 24, 500, normal, 30);
                    letter-spacing: 0.16em;
                    max-width: vw(257px) !important;
                    width: 100% !important;
                    height: unset !important;
                    display: inline-flex !important;
                    justify-content: center;
                    color: $white;

                    button {
                        display: flex !important;
                        align-items: center;
                        justify-content: center;
                        max-width: unset !important;
                        width: 100% !important;
                        padding: unset !important;
                        height: 100% !important;

                        &:hover {
                            background-color: $dark-blue;
                            border-color: $dark-blue;
                            color: $white !important;
                        }

                        @media screen and (max-width: $checkout-bp) {
                            margin-top: 16px;
                            min-height: 60px;
                        }
                    }
                }
            }
        }

        .table,
        .table td {
            border: none;
        }

        .table thead {
            border-bottom: 1px solid $border_color;
        }

        .qc-coupon {
            margin: 0 0 30px;

            .col-sm-12 {
                padding: 0;
            }

            #coupon,
            #confirm_coupon {
                height: 40px;
            }

            #coupon {
                width: 100%;
            }

            #confirm_coupon {
                display: block;
                max-width: 135px;
                width: 100%;
                padding: 5px 20px;
                height: 40px;
            }
        }

        .qc-totals {
            padding-left: 15px;
            padding-right: 15px;

            .row {

                padding-top: 25px !important;
                padding-bottom: 25px !important;

                div,
                label {
                    @include font($secondary_font, 16, 400, normal, 16);
                    color: $dark-blue;
                    letter-spacing: 0.01em;
                }

                label {
                    text-align: left !important;
                }

                &:last-child {
                    border-bottom: 1px solid $light-blue;
                    text-transform: uppercase;

                    div,
                    label {
                        @include font($secondary_font, 20, 500, normal, 30);
                        color: $gold;

                        @media screen and (max-width: $checkout-bp) {
                            @include font($font-weight: 400);
                            letter-spacing: 0.01em;
                        }
                    }
                }
            }
        }

        .qc-checkout-product table.table tbody td {
            vertical-align: middle;
        }
    }

    #payment_address_form,
    #shipping_address_form {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
    }

    #payment_address_form>div,
    #shipping_address_form>div {
        flex-basis: calc(50% + 15px);
    }

    #payment_address_newsletter_input,
    // #payment_address_email_input,
    // #payment_address_email_confirm_input,
    // #payment_address_company_input,
    // #payment_address_address_1_input,
    // #payment_address_city_input,
    // #payment_address_postcode_input,
    #payment_address_customer_group_id_input,
    #payment_address_custom_field\.account\.1_input,
    #payment_address_shipping_address_input,
    #payment_address_agree_input,
    #payment_address_heading_heading {
        flex-basis: calc(100% + 30px) !important;
    }

    #payment_address_customer_group_id_input .col-xs-12:first-child {
        display: none;
    }

    #confirm_form {
        margin-bottom: clamp(30px, 3.125vw, 60px);
    }

    #confirm_comment {
        min-height: 140px;
        resize: vertical;
    }

    #payment_address_newsletter_input,
    #payment_address_shipping_address_input,
    #payment_address_agree_input,
    #payment_address_heading_heading {
        label {
            text-align: left;
        }
    }

    #payment_address_heading_heading {
        margin-bottom: 30px;

        @media (max-width: $checkout-bp) {
            margin-bottom: 10px;
        }
    }

    @media (max-width: $checkout-bp) {

        #payment_address_form>div,
        #shipping_address_form>div {
            flex-basis: calc(100% + 30px) !important;
        }

        #payment_address_form>div>div,
        #shipping_address_form>div>div,
        #confirm_comment_input>div,
        #confirm_agree_input>div {
            padding: 0;
        }
    }
}

// CHECKOUT - OVERRULED
.page_checkout.page_cart {
    .info-row {
        .alert {
            border-radius: 40px;
            background: transparent;
            color: $gold;
            border-color: $gold;

            button {
                font-size: inherit;
                line-height: 1;
            }
        }
    }
}

body.page_checkout.checkout-container {
    background-color: $light-blue;
    display: flex;
    flex-direction: column;

    div[id*="error"] {
        @include font($secondary_font, 12, 500, normal, 12);
        padding: 5px 25px 0 25px;
    }

    @media (max-width: $checkout-bp) {
        .radio {
            label {
                @include font($font-weight: 500);
            }
        }
    }
    select {
        background-color: transparent;
        color: $secondary_color;
        -webkit-appearance: none;
        cursor: pointer;
        background-image: url('../../../icons/arrow-down.svg');
        background-repeat: no-repeat;
        background-position: 90% 50%;
        background-color: transparent;
        padding-left: 30px;
        padding-right: 70px;
    }

    #d_quickcheckout {
        margin-bottom: vw(40px);
        order: 3;
    }

    header {
        order: 1;

    }

    #search {
        order: 2;
    }

    footer {
        margin-top: vw(272px);
        order: 4;
    }
}

.top-line {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: vw(640px);
        height: 1px;
        background-color: $light-blue;

        @media screen and (max-width: $checkout-bp) {
            width: calc(100% - 10px);
        }
    }
}

// left-side
#login_view,
#payment_address,
#shipping_address,
#shipping_method,
#payment_method,
#payment_view {
    background-color: $white;
    padding-top: 33px;
    padding-left: 41px;
    padding-right: 41px;
    width: 100%;
    float: left;

    @media screen and (max-width: 1440px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

#payment_address {
    padding-top: 0;
}

//right side
#cart_view,
#confirm_view {
    background-color: $white;
    padding-top: 33px;
    padding-left: 34px;
    padding-right: 34px;
    width: 100%;
    float: left;

    @media screen and (max-width: 1440px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

#cart_view,
#login_view {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

#confirm_view,
#payment_view {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

#payment_view {
    .header-2 {
        @include font($secondary_font, 22, 700, normal, 22);
        letter-spacing: 0.01em;
        text-transform: initial;
    }

    &>p {
        @include font($secondary_font, 16, 400, normal, 16);
        letter-spacing: 0.01em;
        color: $gold;
        @media screen and (max-width: $checkout-bp) {
            margin-bottom: 30px;
        }
    }

    &>.well {
        background-color: $light_brown;
        border-color: $light_brown;
        border-radius: unset;

        @media screen and (max-width: $checkout-bp) {
            background-color: #F1F1F1;
            border-color: #F1F1F1;
        }

        &>p {
            &:first-child {
                @include font($secondary_font, 22, 700, normal, 22);
                letter-spacing: 0.01em;
                @media screen and (max-width: $checkout-bp) {
                    @include font($font-size: 14, $line-height: 17);
                }
            }

            &:last-child {
                @include font($secondary_font, 16, 400, normal, 16);
                letter-spacing: 0.01em;
                @media screen and (max-width: $checkout-bp) {
                    @include font($font-size: 14, $line-height: 22);
                }
            }
        }
    }
}

@media screen and (max-width: $checkout-bp) {
    #cart_view {
        margin-top: -1px;
        border-top-left-radius: unset;
        border-top-right-radius: unset;
    }

    #payment_view {
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;
    }
}

#shipping_method,
#payment_method,
#payment_view {
    @extend .top-line;
}

#login_view {
    .login-btn-group {
        margin-bottom: unset !important;
    }
}

.guest-login-register {
    &>* {
        flex: 1;
        flex-basis: unset !important;

        &:not(:last-child) {
            margin-right: 19px;

            @media screen and (max-width: $inBetween-bp) {
                margin-right: 5px;
            }
        }

        button,
        label {
            @include font($primary_font, 24, 500, normal, 30);
            letter-spacing: 0.16em;
            background-color: transparent;
            color: $dark-blue;
            border-color: $dark-blue;

            @media screen and (max-width: $inBetween-bp) {
                @include font($font-size: 20, $line-height: 22);
                letter-spacing: 0.01em;
            }

            &:hover {
                background-color: $dark-blue !important;
                color: $white !important;
                border-color: $dark-blue !important;
            }
        }

    }
}

#payment_address_heading_heading,
.panel-title {
    @include font($secondary_font, 22, 700, normal, 22);
    letter-spacing: 0.01em;
    color: $dark-blue;
    text-transform: unset;

    @media screen and (max-width: $checkout-bp) {
        @include font($font-size: 20, $line-height: 30);
        padding-bottom: 8px;
    }
}

#payment_address_heading_heading {
    margin-top: 50px;
}

.qc-cart {

    thead,
    tbody {
        tr {
            display: inline-flex;
            justify-content: space-between;
            width: 100%;

            @media screen and (max-width: $checkout-bp) {
                flex-wrap: wrap;
            }

            td {
                @media screen and (max-width: $checkout-bp) {

                    &.qc-quantity {
                        order: 5;
                        min-width: 100%;
                        margin-left: 90px;
                    }
                }

                &.qc-name {
                    flex: 1;

                    @media screen and (max-width: $checkout-bp) {
                        min-width: vw-rs(990, 600px);
                    }

                    @media screen and (max-width: 590px) {
                        min-width: vw-rs(500, 250px);
                    }

                    @media screen and (max-width: 460px) {
                        min-width: vw-rs(400, 100px);
                    }
                    @media screen and (max-width: 350px) {
                        min-width: vw-rs(350, 100px);
                    }
                }

                &:not(.qc-name):not(.qc-quantity):not(.qc-total) {
                    display: none;
                }
            }
        }
    }

    thead {
        border-bottom: unset !important;

        tr {
            border-radius: 40px;
            padding: 22px 31px;
            background-color: $dark-blue;
            color: $white;

            @media screen and (max-width: 1025px) and (min-width: 992px) {
                max-width: vw-rs(1025, 450px);
            }

            td {
                @include font($secondary_font, 16, 400, normal, 0);
                letter-spacing: 0.01em;
                text-transform: uppercase;

                &.qc-quantity {
                    margin-right: vw(50px);

                    @media screen and (max-width: $checkout-bp) {
                        display: none;
                    }
                }

                &.qc-name {

                    @media screen and (max-width: $inBetween-bp) and (min-width: ($checkout-bp + 1)) {
                        white-space: nowrap;
                    }
                }
            }
        }
    }

    tbody {
        tr {
            border-bottom: 1px solid $light-blue;
            padding: 30px 0;

            td {
                &.qc-image {
                    padding: 0;
                    display: unset !important;
                    max-width: 78px;

                    a {
                        text-decoration: none !important;
                    }

                    img {
                        display: inline-flex;
                        min-width: 80px;
                    }
                }

                &.qc-total,
                &.qc-name {
                    @include font($secondary_font, 16, 400, normal, 16);
                    letter-spacing: 0.01em;
                    padding-left: vw(58px);

                    @media screen and (max-width: 1536px) {
                        padding-left: 10px;
                    }

                    @media screen and (max-width: $checkout-bp) {
                        padding-left: 10px;
                    }
                }

                &.qc-name {
                    max-width: 450px;
                    margin-right: auto;
                }
                &.qc-name,
                &.qc-quantity,
                &.qc-total {
                    display: inline-flex;
                    flex-direction: column;
                    justify-content: center;

                }

                &.qc-total {
                    padding-left: 25px;
                    padding-right: 45px;
                    text-align: right;

                    @media screen and (min-width: ($checkout-bp + 1)) {
                        min-width: 130px;
                    }

                    @media screen and (max-width: $checkout-bp) {
                        @include font($secondary_font, 16, 500, normal, 22);
                        letter-spacing: 0.01em;
                        padding-left: 10px;
                        padding-right: 40px;
                    }
                }

                &.qc-quantity {
                    border: 1px solid $dark-blue !important;
                    border-radius: 100px;
                    width: unset !important;
                    max-width: 15%;
                    margin-right: vw(45px);

                    max-width: 68px;
                    height: 40px;
                    align-self: center;

                    .input-group {
                        position: relative;
                        min-width: 50px;

                        .input-group-btn {
                            button {
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                &:not(.delete) {
                                    padding: 0;

                                    svg {
                                        height: 7px;
                                        width: 10px;
                                    }

                                }
                            }

                            .increase,
                            .decrease {
                                background: transparent;
                                border: none;
                                color: $dark-blue;
                            }

                            .delete {
                                position: absolute;
                                @media screen and (min-width: ($checkout-bp + 1)) {
                                    bottom: -35px;
                                    right: -20px;
                                }

                                &:active,
                                &:focus,
                                &:hover {
                                    background-color:transparent !important;
                                    border-color:transparent !important;
                                }
                                
                                @media screen and (max-width: $checkout-bp) {                                    
                                    top: 0;
                                    right: -112px;
                                }
                            }
                        }

                        input {
                            padding: 0;
                            border: none;
                            min-width: 10px !important;
                            color: $dark-blue;
                        }
                    }

                    .delete {
                        @include font($secondary_font, 16, 400, normal, 16);
                        @extend .delete-context;
                        letter-spacing: 0.01em;
                        color: $dark-blue;

                        svg {
                            max-width: 8px;
                        }
                    }
                }
            }
        }
    }
}

.delete-context {
    &:after {
        content: "Delete";
        padding-left: 5px;
        color: #898989;

        @media screen and (max-width: $checkout-bp) {
            color: $gold;
        }
    }

    svg {
        line {
            stroke: #898989;

            @media screen and (max-width: $checkout-bp) {
                stroke: $gold;
            }
        }
    }
}

body.lang_el {
    .delete-context {
        &:after {
            content: "Διαγραφή";
        }
    }
}

._page-title {
    @include font($primary_font, 60, 500, normal, 74);
    letter-spacing: 0.01em;
    color: $dark-blue;

    @media screen and (max-width: $checkout-bp) {
        @include font($font-size: 24, $line-height: 30);
        letter-spacing: 0.22em;
        text-align: center;
        margin-bottom: 25px;
    }
}

.header-replacement {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 34px;
    padding-bottom: 65px;

    @media screen and (max-width: $checkout-bp) {
        flex-direction: column;
        justify-content: center;
        padding-top: 30px;
        padding-bottom: 28px;
    }

    &>* {
        width: 33.333%;
        display: flex;

        &._back {
            a {
                @include font($secondary_font, 20, 400, normal, 20);
                letter-spacing: 0.01em;
            }
        }

        &._help {
            a {
                @include font($secondary_font, 20, 400, normal, 20);
                letter-spacing: 0.01em;
    
                @media screen and (max-width: $checkout-bp) {
                    @include font($font-size: 16);
                }
            }
        }

        @media screen and (max-width: $checkout-bp) {
            width: auto;

            &._back {
                order: 2;
                margin-top: 17px;
            }

            &._logo {
                order: 1;
            }

            &._help {
                order: 3;
                margin-top: 24px;
            }
        }

        &._logo {
            justify-content: center;
        }

        &._help {
            justify-content: flex-end;
        }
    }
}

[class*="col-"] {
    min-height: 0 !important;
}

@media screen and (max-width: $checkout-bp) {
    #payment_address {
        .panel-title {
            border-bottom: 1px solid $light-blue;
            padding-top: 30px;
        }
    }
    #payment_address_zone_id_input {
        padding-bottom: 40px;
    }

    #payment_view,
    #payment_method_form {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    #payment_view {
        padding-left: 10px;
        padding-right: 10px;
    }
}
#account-order {
    a.btn-default {
        display: block;
        max-width: 400px;
        margin: 0 auto;
        width: 100%;
    }
}