/* cart page */
#checkout-cart {
    padding: 15px 0;
    background-color: $light_brown;
    .mini-cart-total {
        font-size: 1.4rem;
        text-align: right;
    }
    .mini-cart-checkout {
        background-color: transparent;
        justify-content: flex-end;
        a {
            border-radius: 40px;
            font-size: 1.3rem;
            line-height: 2.4rem;
            font-weight: 600;
            letter-spacing: 0.075em;
            text-transform: uppercase;
            text-align: center;
            padding: 15px;
            align-self: flex-end;
            background-color: $primary_color;
            max-width: 250px;
            color: $btn_font_color;
            border: 1px solid $primary_color;
            width: 100%;
            -webkit-transition: all .3s ease;
            -moz-transition: all .3s ease;
            -o-transition: all .3s ease;
            transition: all .3s ease;
            @media (max-width: $mobile) {
                max-width: 100%;
            }
            &:hover {
                background-color: transparent;
                color: $primary_color;
                -webkit-transition: all .3s ease;
                -moz-transition: all .3s ease;
                -o-transition: all .3s ease;
                transition: all .3s ease;
            }
        }
    }
    .cart-page {
        .mini-cart-total {
            display: flex;
            flex-basis: 100%;
            margin-top: 30px;
            justify-content: flex-end;
            .col-total {
                width: 100%;
                max-width: 250px;
                font-size: 1.3rem;
                line-height: 2.4rem;
                font-weight: 600;
                letter-spacing: .022em;
                background-color: #e4e4e4;
                color: $primary_color;
                text-align: center;
                text-transform: uppercase;
                padding: 25px;
                margin-bottom: 15px;
                border-radius: 40px;
                @media (max-width: $mobile) {
                    max-width: 100%;
                }

                &:last-child {
                    display: none;
                }
            }
            .cart-breakdown {
                width: 100%;
                margin-bottom: 10px;
            }
        }
        ul {
            li {
                border-bottom: 1px solid #ddd;
                & + li {
                    margin-top: 10px;
                }
                .mini-cart-products {
                    padding: 20px 0;
                    align-items: center;
                    justify-content: space-between;
                    .product-img {
                        flex-basis: 15%;

                        @media (max-width: $tablet) {
                            flex-basis: 25%;
                        }

                        a {
                            display: inline-block;
                            .img-thumbnail {
                                border: none;
                                background-color: transparent;
                            }
                        }
                    }
                    .prd-item {
                        flex-basis: 55%;
                        @media (max-width: $mobile) {
                            flex-basis: 70%;
                        }
                        a {
                            display: inline-block;
                            margin-bottom: 0;
                            @media (max-width: $mobile) {
                               font-size: 1.4rem;
                            }
                        }
                        .prd-description, .prd-price {
                            margin-bottom: 0;
                        }
                        .product-quantity {
                            &.mobile-view {
                                display: none;
                                @media (max-width: $mobile) {
                                   display: block;
                                }
                            }
                        }
                    }
                    .product-remove {
                        align-self: start;
                        .remove-cart-item {
                            background: transparent;
                            border: none;
                            .delete-icon {
                                display: block;
                                width: 17px;
                                height: 17px;
                                @media (max-width: $tablet) {
                                    width: 12px;
                                    height: 12px;
                                }
                                svg {
                                    display: block;
                                    width: 17px;
                                    height: 17px;
                                    @media (max-width: $tablet) {
                                        width: 12px;
                                        height: 12px;
                                    }
                                    .st0 {
                                        fill:none;
                                        stroke:#8c8c8c;
                                        stroke-width:3;
                                        stroke-linecap:round;
                                        stroke-miterlimit:10;
                                    }
                                }
                            }
                        }
                    }
                    .product-quantity {
                        flex-basis: 20%;
                        margin-right: 15px;
                        @media (max-width: $mobile) {
                            &.desktop-view {
                                display: none;
                            }
                        }
                        .quantity-input-field{
                            display: block;
                            .quantity {
                                display: flex;
                                justify-content: space-between;
                                flex-basis: 60%;
                                @media (max-width: $tablet) {
                                    flex-basis: 100%;
                                }
                                @media (max-width: $mobile) {
                                    max-width: 165px;
                                    margin-top: 20px;
                                }
                                button.btn.btn-primary {
                                    background: no-repeat;
                                    color: $primary_color;
                                    border: 0;
                                    text-shadow: none;
                                    box-shadow: none;
                                    padding: 0;
                                    text-decoration: underline;
                                    display: inline-block;
                                    position: relative;
                                    margin-left: 15px;
                                }

                                .quantity-wrapper {
                                    position: relative;
                                    width: 100%;
                                    input.form-control {
                                        border: 0;
                                        box-shadow: none;
                                        border-top: 1px solid $primary_color;
                                        border-bottom: 1px solid $primary_color;
                                        text-align: center;
                                    }
                                    .quantity-nav{
                                        position: absolute;
                                        top: 50%;
                                        -webkit-transform: translateY(-50%);
                                        -moz-transform: translateY(-50%);
                                        -o-transform: translateY(-50%);
                                        transform: translateY(-50%);
                                        width: 100%;
                                        justify-content: space-between;
                                        .quantity-button {
                                            cursor: pointer;
                                            padding: 8px 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }
    }
    .product-grid, .choosegift, .giftavailable {
        display: none;
    }
}

