/*========================================================*/
/* Layout*/
/*========================================================*/
.flex-row {
    display: flex;
}
.flex-column {
    display: flex;
    flex-direction: column;
}

.inline-block {
    display: inline-block;
}

.block {
    display: block;
}

.overflow-hidden {
    overflow: hidden;
}

.float-right {
    float: right;
}

.side-padding {
    @include sidePadding($side_padding);
}

// reset helping classes
.m0 {
    margin: 0;
}

.mt0 {
    margin-top: 0;
}

.mb0 {
    margin-bottom: 0;
}

.p0 {
    padding: 0;
}

// Megamenu helping class
.mobile-account-items {
    display: none;
}

// animations
.fade-in {
    opacity: 0;
}
.fade-in-headers {
    opacity: 0;
    position: relative;
    top: 20px;
}

.fade-children {
    > * {
        opacity: 0;
    }
}

.typing-text {
    opacity: 0;
    animation: fadeIn 0.01s 0.3s forwards;
    .letter {
        opacity: 0;
    }
}

/*========================================================*/
/*** Default Elements ***/
/*========================================================*/
.btn-default {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 10px 15px;
    font-size: $btn_fs;
    font-weight: 500;
    letter-spacing: 0.05em;
    color: $btn_font_color;
    background-color: $btn_background_color;
    border: 1px solid $btn_background_color;
    border-radius: $border_radius_button;
    background-image: none;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 1.8;
    transition: ease 0.3s;
    min-height: unset;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: $primary_font;
    &:hover {
        color: $btn_font_color;
        background-color: $secondary_color;
        border-color: $btn_background_color;
    }
    &.reverse {
        color: $btn_font_color;
        background-color: $btn_background_color;
        &:hover {
            background-color: transparent;
            color: $btn_background_color;
        }
    }
    &.active {
        color: $white;
        background-color: $secondary_color;
        border-color: $secondary_color;
        &:hover {
            background-color: transparent;
            color: $btn_background_color;
        }
    }
    &.medium-button {
        max-width: 250px;
    }
}

// Bootstrap defaults
.alert {
    margin: 15px;
    font-size: 1.6rem;
}
.alert-success {
    color: $white;
    background-color: $black;
    border-color: $black;
    font-size: $text_fs;
    border-radius: 0;
    text-align: center;
}
.alert {
    margin: 15px;
}
.btn-primary,
.btn-info {
    color: $white;
    background-color: $black;
    border-color: $black;
    &:hover {
        color: $white;
        background-color: $black;
        border-color: $black;
    }
}
.default-cta {
    display: inline-block;
    color: $secondary_color;
    position: relative;
    font-weight: 400;
    font-size: var(--text);
    text-align: center;
    letter-spacing: 0.01em;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    line-height: 30px;
    @media (max-width: $tablet-sm) {
        font-size: 2rem;
        &.small-btn {
            font-size: 1.6rem;
        }
    }
    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: $secondary_color;
    }
    &:hover {
        color: $primary_color;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        &:after {
            background-color: $primary_color;
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.modal-open {
    max-width: 100%;
    overflow-y: hidden !important;
    overflow-x: auto !important;
    padding: 0 !important;
}

.modal {
    background-color: rgba(#365563, .75) !important;

    .modal-title {
        color: $dark-blue;
    }

    .modal-content {
        background-color: $light-brown;
    }
}

._underline {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $dark_blue;
    }
}