/*========================================================*/
/*** LOGIN ***/
/*========================================================*/
#account-login {
    .breadcrumb {
        li {
            display: none;
        }
    }
    @media (max-width: $tablet) {
        width: 100%;
    }

    .well {
        // bootstrap reset
        padding: 20px;
        background-color: transparent;
        border: none;
        border-radius: 0;
        -webkit-box-shadow: none;
        box-shadow: none;

        &.new-customer-form:before {
            content: '';
            width: 1px;
            height: 100%;
            background: #000;
            position: absolute;
            top: 0;
            left: 0;

            @media (max-width: $tablet-sm) {
                content: none;
            }
        }

        @media (max-width: $tablet-sm) {
            padding: 10px;
        }
    }

    .register-btn {
        max-width: 200px;
        margin: 25px auto 0;
    }

    .forgotten-pass {
        margin-top: 5px;
    }
}

/*========================================================*/
/*** SOCIAL LOGIN ***/
/*========================================================*/
#d_social_login {
    display: block;
    width: 100%;
    max-width: 200px;
    margin: 0 auto clamp(30px, 2.6042vw, 50px) auto;

    .d-social-login__button {
        display: block;
        width: 100%;
        color: $white;
        text-decoration: none;

        .d-social-login__button__icon {
            max-width: 100%;
            text-align: left;
            width: 100%;
            display: block;
            position: relative;
            padding-left: 45px;

            img {
                max-width: 30px;
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translate(0, -50%);
            }
        }

        &.d-social-login__button--facebook .d-social-login__button__icon img {
            max-width: 10px;
        }

        &.d-social-login__button--google span.d-social-login__button__icon img {
            max-width: 25px;
        }
    }
}

/*========================================================*/
/*** REST ACCOUNT PAGES ***/
/*========================================================*/
.common-account {
    padding: clamp(30px, 5.2083vw, 100px) 15px;
    float: none;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    min-height: 85vh;

    &.large-container {
        max-width: 900px;
    }

    legend {
        font-size: $text_fs;
        font-weight: 500;
        border-bottom: none;
        margin: 0 0 15px;
        text-transform: uppercase;
    }

    .terms {
        margin: 0 0 15px;
    }

    ul>li>a {
        line-height: 2.5;
        text-decoration: none;
    }

    .btn-default {
        text-decoration: none !important;
    }

    .col-sm-2.control-label {
        display: none;
    }

    .default-address,
    .is-opened {
        display: block !important;
        text-align: left;
        width: 100%;
    }

    .buttons {
        margin-top: 15px;
    }

    .col-sm-10 {
        display: block;
        width: 100%;
    }

    .table-bordered {

        >tbody>tr>td,
        >tbody>tr>th,
        >tfoot>tr>td,
        >tfoot>tr>th,
        >thead>tr>td,
        >thead>tr>th {
            border: none;
            border-bottom: 1px solid $border_color;
            padding: 20px 15px;
        }
    }

    td.text-left a {
        color: #000;
        text-decoration: none;
    }

    td.text-right>a {
        text-decoration: none;
    }

    .add-to-cart-icon svg,
    .add-to-cart-icon {
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-right: 5px;
    }

    .remove-item-icon svg .st0 {
        fill: none;
        stroke: #000000;
        stroke-width: 3;
        stroke-linecap: round;
        stroke-miterlimit: 10;
    }

    .remove-item-icon svg,
    .remove-item-icon {
        display: inline-block;
        width: 15px;
        height: 15px;
    }

    thead>tr>td {
        font-size: 1.4rem;
        letter-spacing: 0.025em;
        font-weight: 600;
        color: #000;
    }

    tbody>tr>td {
        font-size: 1.4rem;
        font-weight: 400;
        color: #000;
    }

    .table-hover>tbody>tr {
        opacity: 0.7;
        background-color: transparent;
    }

    .table-hover>tbody>tr:hover {
        opacity: 1;
    }

    td.text-right>a.edit-address-btn,
    td.text-right>a.remove-address-btn,
    td.text-right>a.btn-view-item {
        font-size: 1.1rem;
        line-height: initial;
        font-weight: 600;
        letter-spacing: 0.025em;
        color: #000;
        border: none;
        border-radius: 0;
        background-image: none;
        text-transform: uppercase;
        text-decoration: underline;
    }

    &.account-voucher {
        .col-sm-2.control-label {
            display: block;
            width: 100%;
            text-align: left;
        }
    }
}

// NO IDEA FROM DOWN HERE
.container.common-account.small-container {
    max-width: 500px;
}

.input-group.date button.btn.btn-default {
    padding: 9px 20px;
}

label.col-sm-3.control-label.newsletter-text,
label.col-sm-4.control-label.newsletter-text {
    text-align: left;
}

.height-balancer {
    @media screen and (min-width: ($tablet + 1)) {
        min-height: 200px;
    }
}

// ._account class-centric
$account-main-color: $gold;
$account-main-color-interaction: $dark-blue;

body.page_contact,
body.page_account {
    background-color: $light_brown;
}

.account-title {
    @include font($primary_font, 34, 500, normal, 42);
    letter-spacing: 0.04em;
    text-transform: uppercase;
    margin-bottom: 37px;
    color: $account-main-color;
    color: $gold;

    @media screen and (max-width: $tablet) {
        @include font($font-size: 30, $line-height: 37);
        margin-bottom: 19px;
    }
}

.account-page-heading {
    padding-top: vw(124px);
    padding-bottom: vw(86px);
    color: $gold;

    &>span {
        @include font($primary_font, 30, 500, normal, 37);
        letter-spacing: 0.22em;
        color: inherit;
        margin-bottom: 0;
        display: flex;
        justify-content: center;

        @media screen and (max-width: $tablet) {
            @include font($font-size: 24, $line-height: 30);
        }
    }

    h2 {
        @include font($primary_font, 60, 500, normal, 74);
        letter-spacing: 0.22em;
        text-align: center;
        color: inherit;
        margin-bottom: 0;

        @media screen and (max-width: $tablet) {
            @include font($font-size: 36, $line-height: 45);
        }
    }
}

.account-paragraph {
    @include font($secondary_font, 24, 400, normal, 30);
    letter-spacing: 0.01em;
    text-align: left !important;
    color: $dark-blue;
    margin-bottom: 40px;

    @media screen and (max-width: $tablet) {
        @include font($font-size: 16, $line-height: 22);
        margin-bottom: 20px;
    }
}

.account-inner-link {
    line-height: 1.5;
    margin-bottom: 5px;
    display: inline-flex;
    border-left: 1px solid transparent;
    transition: all .3s ease-in-out;

    &:hover {
        color: $account-main-color-interaction;
        border-color: $account-main-color-interaction;
        padding-left: 10px;
    }
}

.account-btn {
    @include font($primary_font, 24, 400, normal, 30);
    letter-spacing: 0.01em;
    border: none;
    background-color: $account-main-color;
    color: $white !important;
    border: 1px solid $account-main-color;
    border-radius: 40px;
    padding: 14px 5px;
    line-height: 1.5;

    @media screen and (max-width: $footer-bp) {
        @include font($font-size: 20);
    }

    &:hover {
        background-color: $dark-blue;
        border-color: $dark-blue;
    }
}

.account-block {

    &:not(:last-child) {
        margin-bottom: 40px
    }
    h2 {
        @extend .account-title;
        margin-bottom: 15px;
    }

    ul {
        li {
            a {
                @extend .account-inner-link;
            }
        }
    }
}

._account {

    h1,
    h2 {
        @extend .account-title;
    }

    p {
        a {
            text-decoration: underline;
        }
    }

    .alert {
        margin: unset;
        margin-bottom: 30px;
        background-color: transparent;
        border-radius: 40px;
        border-color: $account-main-color;
        text-align: center;
        font-size: 16px;

        &.alert-success {
            color: $gold;
        }

        &.alert-warning {
            color: $dark-blue;
            border-color: $dark-blue;
        }
    }

    select {
        border-color: $account-main-color;
        color: $account-main-color;
    }

    .text-danger {
        font-size: 1.4rem;
        text-align: center;
    }

    .has-error {
        .control-label {
            color: #a94442;
        }
    }


    input {
        @include font($secondary_font, 24, 400, normal, 30);
        letter-spacing: 0.01em;
        background-color: transparent;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            box-shadow: unset !important;
        }

        @media screen and (max-width: $tablet) {
            @include font($font-size: 20);
        }

        &[type=submit] {
            @extend .account-btn;
        }
    }

    .btn-default {
        @extend .account-btn;

        .fa-calendar {
            line-height: 36px;
        }
    }

    .radio-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 75px;

        .radio-inline {
            padding: 0;

            label {
                align-items: center;
                top: 0 !important;
                left: 0 !important;
            }
        }
    }

    .radio-inline {
        input {
            position: relative;
            margin: unset;
            margin-right: 5px;
            margin-top: -2px;
        }
    }

    label:not(.radio-inline) {
        @include font($primary_font, 24, 500, normal, 30);
        display: inline-flex !important;
        color: $gold;
        padding-left: 15px;
        padding-top: 16px;
        padding-bottom: 16px;
        width: auto;
        align-items: center;

        @media screen and (max-width: $tablet) {
            padding-top: 8px;
            padding-bottom: 14px;
        }

        &:after {
            content: unset !important;
        }

        span {
            color: $dark-blue;
        }
    }

    .inline-option,
    ._newsletter {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 0;

        &>* {
            text-align: center;
        }

        &--not-centered {
            align-items: initial;

            &>* {
                text-align: left;
            }
        }

        label {
            left: 0;
            top: 0;

            &.radio-inline {
                @media screen and (min-width: ($tablet + 1)) {
                    font-size: 75%;
                }
                padding: 5px;
            }

        }
    }

    .terms {
        font-size: 75%;

        &--v-align {
            display: flex;
            align-items: center;
        }
    }

    .form-group {
        margin-bottom: unset !important;
    }

    ._align {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        text-align: center;

        @media screen and (min-width: ($tablet + 1)) {
            font-size: 75%;
        }

        &>span {
            text-align: center;
        }

        .radio-inline {
            display: inline-flex;
            align-items: center;
            padding-left: 0;

            span {
                position: relative;
                line-height: 18px;
            }
        }
    }

    #d_social_login {
        font-size: 12px;
    }

    .order-pag {
        font-size: 14px;
    }

    .buttons {
        &--max-600 {
            max-width: 600px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    thead {
        tr {
            td {
                color: $dark-blue;
            }
        }
    }

    tfoot {
        font-size: 14px;

        tr {
            &:last-of-type {
                text-transform: uppercase;
            }
        }
    }
}

.account-dual-block {
    display: flex;
    justify-content: space-between;
    padding-bottom: 89px;
    padding-top: 89px;
    border-bottom: 1px solid $dark-blue;

    @media screen and (max-width:$tablet) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 0;
        padding-bottom: 42px;
    }

    ._block {
        display: flex;
        flex-direction: column;
        width: 50%;
        max-width: 512px;

        @media screen and (max-width:$tablet) {
            width: 100%;
            text-align: center;

            &:first-child {
                margin-top: 25px;
                margin-bottom: 64px;
            }
        }

        @media screen and (min-width: ($tablet + 1)) {
            &:first-child {
                margin-right: 58px;
            }

            &:last-child {
                margin-left: 58px;
            }

        }

        input {
            margin-bottom: 40px;

            &:last-of-type {
                margin-bottom: 20px;
            }

            @media screen and (max-width: $tablet) {
                margin-bottom: 20px;
            }
        }

        p {
            @extend .account-paragraph;

            @media screen and (min-width: ($tablet + 1)) {
                margin-bottom: 0;
            }

            @media screen and (max-width: $tablet) {

                text-align: center !important;
            }
        }

        .forgotten-pass {
            @include font($secondary_font, 16, 400, normal, 16);
            letter-spacing: 0.01em;
            color: $dark-blue;
            background-color: $light-blue;
            padding: 13px 38px;
            border-radius: 30px;
            transition: all .3s ease-in-out;
            text-decoration: underline;

            &:hover {
                background-color: white;
            }
        }

    }
}

#d_social_login {
    display: flex;
    justify-content: center;
    margin: 70px auto;
    max-width: 719px;

    @media screen and (max-width: $tablet) {
        max-width: 479px;
        margin: 42px auto 78px;
    }

    .d-social-login__button-list {
        width: 100%;

        .d-social-login__button {
            display: none;

            &.facebook--login {
                @include font($secondary_font, 16, 400, normal, 16);
                letter-spacing: 0.01em;
                background-color: #527BB9;
                color: $white;
                padding: 22px;
                border-radius: 40px;
                display: flex;
                width: 100%;

                &:hover {
                    box-shadow: unset !important;
                    background-color: #6B96D9;
                }

                span {
                    padding: 0;
                }

                .d-social-login__button__icon {
                    text-align: center;

                    img {
                        display: none;
                    }



                    .provider-name {
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}

body.page_success .common-account #page-title {
    display: none;
}