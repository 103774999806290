/*========================================================*/
/*** INFORMATION PAGES ***/
/*========================================================*/
.info-page {
    background-color: $light_brown;

    .section-wrap {
        display: flex;
        flex-direction: column;
        padding-top: max(30px, vw(55px));
        padding-bottom: vw(175px);
        padding-left: vw(370px);
        padding-right: vw(370px);
        text-align: left;

        @media screen and (max-width: $tablet) {
            padding-left: 10px;
            padding-right: 10px;
        }

        &__modal {
            padding: 30px;
            
            @media screen and (max-width: $tablet) {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        &__title {
            @include font($primary_font, 60, 500, normal, 74);
            display: flex;
            flex-direction: column;
            letter-spacing: 0.22em;
            text-align: center;
            text-transform: uppercase;
            color: $gold;
            margin-bottom: max(30px, vw(60px));

            @media screen and (max-width: $tablet) {
                @include font($font-size: 36, $line-height: 45);
            }
        }

        &__sub-title {
            @include font($primary_font, 30, 500, normal, 37);
            letter-spacing: 0.22em;
        }

        h1 {
            @extend .section-wrap__title;
        }

        h2 {
            @include font($primary_font, 34, 500, normal, 42);
            letter-spacing: 0.04em;
            color: $gold;
            margin-bottom: 18px;

            &:not(:first-of-type) {
                margin-top: 40px;
            }

            @media screen and (max-width: $tablet) {
                @include font($font-size: 24, $line-height: 30);
                letter-spacing: 0.01em;
                margin-bottom: 21px;

                &:not(:first-of-type) {
                    margin-top: 10px;
                }
            }
        }

        ul,
        ol,
        p {
            @include font($secondary_font, 24, 400, normal, 30);
            letter-spacing: 0.01em;
            color: $dark_blue;
            margin-bottom: 40px;

            @media screen and (max-width: $tablet) {
                @include font($font-size: 16, $line-height: 22);
                margin-bottom: 30px;
            }

            a {
                text-decoration: underline;
            }

            img {
                max-width: 100%;
            }
        }
    }
}