.contact-form-container {
    background-color: $light_brown;
    .contact-form-wrapper {
        max-width: 970px;
        width: 100%;
        margin: 0 auto;
        padding: 90px 30px 0;
        @media (max-width: $tablet) {
            padding: 60px 30px 0;
        }
        @media (max-width: $mobile) {
            padding: 40px 10px 0;
        }
    }
    .contact-form {
        max-width: 600px;
        width: 100%;
        margin: 0 auto;
        @media (max-width: $laptop) {
            max-width: 550px;
        }
    }
    .title-wrapper {
        margin-bottom: 60px;
        @media (max-width: $mobile) {
            margin-bottom: 20px;
        }
        h2 {
            margin: 0;
        }
    }
    .contact-description {
        max-width: 900px;
        width: 100%;
        margin: 0 auto clamp(35px, 3.6458vw, 60px);
        line-height: 1.3;
        p {
            display: inline;
        }
        @media (max-width: $tablet-sm) {
            margin: 0 auto 35px;
            padding: 0 10px;
            .contact-moto {
                margin: 0 auto 25px;
            }
            .contact-moto,
            .contact-info {
                display: block;
            }
        }
    }
    .contact-form {
        margin-bottom: clamp(75px, 11.7188vw, 225px);
        @media (max-width: $tablet-sm) {
            margin-bottom: 75px;
        }
        .form-group {
            margin: 0 0 45px;
            @media (max-width: $tablet-sm) {
                margin: 0 0 20px;
            }
            .col-sm-12 {
                padding-left: 0;
                padding-right: 0;
            }
            input,
            textarea {
                margin-bottom: 5px;
            }
            textarea {
                resize: vertical;
                min-height: 227px;
                padding: 30px 25px;
            }
        }

        .btn-default {
            margin: 0;
            letter-spacing: 0.22em;
        }
        .text-danger {
            text-align: center;
        }
    }
    .contact-footer {
        picture,
        img {
            width: 100%;
        }
    }
    .text-danger {
        @include font($secondary_font, 12, 500, normal, 12);
        padding: 5px 25px 0 25px;
    }
}
