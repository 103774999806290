/*========================================================*/
/*** CATEGORY ***/
/*========================================================*/
.breadcrumb {
    text-align: center;
    margin: 0;
    padding: 30px 10px 15px 10px;
    border: none;
    background-color: transparent;

    li {
        padding: 0;

        a {
            font-family: var(--secondary-font), sans-serif;
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 1.2;
            letter-spacing: 0.01em;
            margin: 0 0 10px;
        }

        &::after {
            content: '/';
            display: inline-block;
            margin: 0 7px 0 10px;
            font-family: var(--primary-font), sans-serif;
            font-size: 2rem;
            font-weight: 500;
            font-style: normal;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: var(--gold);
        }

        &:last-child {
            &::after {
                display: none;
            }
        }

        &+li {
            &:before {
                display: none;
            }
        }
    }
}

.flex-row.categories-choices {
    justify-content: space-between;
    align-items: center;
    padding: 5px 0 max(40px, vw(105px));
}

.product-col:nth-child(4n + 1) {
    clear: both;
}

.products-block .product-thumb {
    cursor: pointer;
    position: relative;
    overflow: initial;
}

.product-thumb {
    border: none;
}

.category-info h2 {
    text-align: center;
    font-size: 2.4rem;
    line-height: 3rem;
    font-weight: 600;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    margin: 0 0 25px;
}

.category-info .category-description {
    width: 100%;
    max-width: 980px;
    margin: 0 auto;
    text-align: center;
    font-size: 1.3rem;
    line-height: 2.3rem;
    font-weight: 400;
    letter-spacing: 0.025em;
}

.category-info p {
    text-align: center;
    font-size: 1.3rem;
    line-height: 2.3rem;
    font-weight: 400;
    letter-spacing: 0.025em;
}

.pagination>li>a,
.pagination>li>span {
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: 400;
    color: #000000;
    background-color: transparent;
    border: none;
    padding: 12px;
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
    color: #000000;
    background-color: #eaeaea;
    border-color: #eaeaea;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    font-size: 1.4rem;
    line-height: 1.2rem;
    font-weight: 800;
    letter-spacing: 0.025em;
    color: #000;
    background-color: transparent;
    border-color: transparent;
}

.category-pagination span.arrow-left-start {
    display: block;
    content: '';
    background-image: url('../../../icons/arrow-left-first.svg');
    width: 12px;
    height: 12px;
    background-position: center;
}

.category-pagination span.arrow-right-end {
    display: block;
    content: '';
    background-image: url('../../../icons/arrow-right-last.svg');
    width: 12px;
    height: 12px;
    background-position: center;
}

.category-pagination span.arrow-next {
    display: block;
    content: '';
    background-image: url('../../../icons/arrow-next.svg');
    width: 12px;
    height: 12px;
    background-position: center;
}

.category-pagination span.arrow-prev {
    display: block;
    content: '';
    background-image: url('../../../icons/arrow-prev.svg');
    width: 12px;
    height: 12px;
    background-position: center;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.product-thumb .price-old {
    font-weight: 400;
    color: #000000;
    margin-left: 0;
}

.price-tax {
    display: none;
}

.product-thumb .price-new {
    font-weight: 800;
    color: #de0000;
    margin-left: 5px;
}

.product-thumb span.discount-percentage {
    font-weight: 400;
    color: #747474;
    margin-left: 5px;
}

.product-thumb .image a.has-thumb {
    position: absolute;
    top: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    height: 100%;
}

.product-thumb:hover .image a.has-thumb {
    visibility: visible;
    opacity: 1;
}

.sub-categories {
    width: 100%;
    max-width: 1530px;
    display: inline-block;
    margin: 0 -15px;
}

.sub-categories .sub-category-column {
    width: 20%;
    display: inline-block;
    float: left;
    padding: 0 10px;
    position: relative;
    margin-bottom: 10px;
}

h4.sub-category-name {
    display: inline-block;
    position: relative;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 3.6rem;
}

h4.sub-category-name:after {
    display: inline-block;
    content: '';
    width: 0;
    height: 1px;
    background-color: #000;
    position: absolute;
    bottom: 8px;
    left: 0;

    @media (max-width: $tablet-sm) {
        display: none;
    }
}

.sub-categories .sub-category-item {
    width: 100%;
    max-width: 255px;
    text-align: center;
}

.sub-categories .sub-category-item:hover h4.sub-category-name:after {
    width: 100%;
    -webkit-transition: width 0.3s ease;
    -moz-transition: width 0.3s ease;
    -o-transition: width 0.3s ease;
    transition: width 0.3s ease;
}

.sub-categories .sub-category-item:hover img {
    opacity: 0.8;
}


.category-grid {
    width: 100%;
}


@media (max-width: $laptop-xs) {
    .sub-categories .sub-category-column {
        width: 25%;
    }
}

@media (max-width: $tablet-sm) {

    /*category*/


    .products-block {
        margin: 0 auto;
    }

    .caption>h4 {
        font-size: 1.4rem;
    }

    .category-grid {
        margin-left: 0;
    }

    .sub-categories {
        margin: 0 auto;
    }

    .sub-categories .sub-category-column:nth-child(4n + 1) {
        clear: both;
    }

    h4.sub-category-name {
        font-size: 1.2rem;
    }

    span.category-separator {
        margin: 15px 0;
    }

    /*filters*/
    .filters-btn {
        color: #000;
        font-size: 1.2rem;
        letter-spacing: 0.025em;
        width: calc(50% - 15px);
        border-bottom: 1px solid #000;
        padding-bottom: 9px;
        max-height: 35px;
    }

    .filters-btn:hover,
    .filters-btn:active,
    .filters-btn:focus {
        color: #000;
    }

    .filter-icon,
    .filter-icon svg {
        display: inline-block;
        width: 12px;
        height: 13px;
    }

    .filter-icon svg .st0 {
        fill: #000;
    }

    .filters-btn .text {
        display: inline-block;
        padding-left: 5px;
    }

    .close-filters {
        position: fixed;
        top: 0;
        right: 0;
        height: 22px;
        z-index: 99;
        background-color: #fff;
        width: 100%;
    }

    .close-filters-icon,
    .close-filters-icon svg {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin: 5px;
        float: right;
    }

    .close-filters .filters-icon svg .st0 {
        fill: #000;
    }

    .category-short-by {
        width: calc(50% - 15px);
        max-width: unset;
    }

    .category-short-by .form-group.input-group {
        max-width: unset;
    }

    body.category-filters-open {
        overflow: hidden;
    }

    .products-filters.leftSidebar {
        position: fixed;
        top: 0;
        left: -100%;
        background: rgba(0, 0, 0, 0);
        width: 100%;
        max-width: unset;
        opacity: 0;
        visibility: hidden;
        z-index: 99;
        -webkit-transition: left 0.4s ease, background-color 0.1s ease, visibility 0s ease 0.4s, opacity 0s ease 0.4s;
        -moz-transition: left 0.4s ease, background-color 0.1s ease, visibility 0s ease 0.4s, opacity 0s ease 0.4s;
        -o-transition: left 0.4s ease, background-color 0.1s ease, visibility 0s ease 0.4s, opacity 0s ease 0.4s;
        transition: left 0.4s ease, background-color 0.1s ease, visibility 0s ease 0.4s, opacity 0s ease 0.4s;
    }

    .products-filters.leftSidebar.active {
        left: 0;
        opacity: 1;
        visibility: visible;
        background: rgba(0, 0, 0, 0.7);
        -webkit-transition: left 0.3s ease, background-color 0.3s ease 0.2s;
        -moz-transition: left 0.3s ease, background-color 0.3s ease 0.2s;
        -o-transition: left 0.3s ease, background-color 0.3s ease 0.2s;
        transition: left 0.3s ease, background-color 0.3s ease 0.2s;
    }

    #column-left {
        max-width: 50%;
        padding: 0 20px;
        height: 100vh;
        background-color: #fff;
        -webkit-transform: translate(0, 0);
        -moz-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    .products-filters .bf-panel-wrapper {
        height: calc(100vh - 50px);
        overflow-y: auto;
        padding-top: 10px;
    }

    .products-filters .bf-buttonclear-box {
        position: fixed;
        bottom: 15px;
        left: 15px;
        width: calc(100% - 30px);
        z-index: 9;
    }

    .products-filters .bf-form>.bf-attr-block:first-of-type .bf-attr-header,
    .bf-attr-block .bf-attr-header.bf-clickable {
        font-size: 1.4rem;
        line-height: 2;
    }

    .products-filters .bf-cross,
    .products-filters .bf-close-btn,
    .products-filters .bf-arrow {
        background-image: url(../../../image/brainyfilter/sprite.png) !important;
    }
}

@media (max-width: $mobile) {

    #column-left {
        max-width: unset;
    }

    .product-thumb .image {
        padding: 0;
    }
}

#product-category {
    background-color: $light_blue;
    padding: 0 vw(118px);

    @media (max-width: $tablet-sm) {
        padding: 0 10px;
    }

    .category-info {
        padding-top: max(43px, vw(92px));
        padding-bottom: vw(50px);
        color: $gold;

        .category-description {
            p {
                @include font($primary_font, 30, 500, normal, 37);
                letter-spacing: 0.22em;
                margin-bottom: 0;

                @media screen and (max-width: $tablet) {
                    @include font($font-size: 24, $line-height: 30);                    
                }
            }

            br {
                display: none;
            }
        }

        h2 {
            @include font($primary_font, 60, 500, normal, 74);
            letter-spacing: 0.22em;
            color: inherit;
            margin-bottom: 0;

            @media screen and (max-width: $tablet) {
                @include font($font-size: 36, $line-height: 45);
            }
        }
    }

    .category-wrapper {
        .categories-choices {
            transition: all .3s ease-in-out;
            @media screen and (max-width: $tablet) {
                &._open {
                    padding-top: 30px;
                    display: flex;
                    flex-direction: column;
                    position: fixed;
                    z-index: 10000;
                    width: 100%;
                    height: 100vh;
                    left: 0;
                    top: 0;
                    overscroll-behavior-y: contain;
                    overflow-y: auto;
                    background-color: $light_blue;

                    .inline-button-wrap {
                        display: inline-flex;
                    }
                    #_plus {
                        display: none;
                    }
                }
                
                &:not(._open) {                    
                    #_minus {
                        display: none;
                    }
                }
            }

            .choice-toggle {
                @include font($primary_font, 16, 500, normal, 20);
                letter-spacing: 0.22em;
                margin-left: auto;
                margin-right: auto;
                align-items: center;
                color: $dark_blue;
                padding: 15px 33px;
                background-color: transparent;
                color: $dark_blue;
                border: 1px solid $dark_blue;
                border-radius: 3px;
                cursor: pointer;
                display: none;

                //@media screen and (max-width: $tablet) {
                //    display: inline-flex;
                //}

                span {
                    white-space: nowrap;
                    margin-right: 20px;
                }
            }

            .inline-button-wrap {
                margin: auto;
                display: inline-flex;
                justify-content: center;
                flex-wrap: wrap;

                @media screen and (max-width: $tablet) {
                    flex-direction: column;
                    justify-content: flex-start;
                    margin-top: 30px;
                    display: none;
                }

                .filterBy {
                    @include font($primary_font, 20, 500, normal, 30);
                    letter-spacing: 0.16em;
                    padding: 15px 17px;
                    background-color: transparent;
                    color: $dark_blue;
                    border: 1px solid $dark_blue;
                    border-radius: 3px;
                    margin: 5px;
                    text-transform: uppercase;

                    @media screen and (max-width: $tablet) {
                        @include font($font-size: 24);
                        letter-spacing: 0.22em;
                    }

                    &._active,
                    &:hover {
                        color: $gold;
                        border-color: $gold;

                        @media screen and (max-width: $tablet) {
                            color: $dark_blue;
                        }
                    }

                    @media screen and (max-width: $tablet) {
                        letter-spacing: 0.22em;
                        padding: unset;
                        margin-bottom: 20px;
                        margin-left: auto;
                        margin-right: auto;
                        color: $dark_blue;
                        background: transparent;
                        border: none;

                        &._active,
                        &:hover {
                            position: relative;

                            &:after {
                                content: "";
                                position: absolute;
                                bottom: -5px;
                                left: 0;
                                width: 100%;
                                height: 1px;
                                background-color: $dark_blue;
                            }
                        }

                        &:not(:last-child) {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }

    }

    .sub-footer-wall {
        margin-left: vw(-118px);
        margin-right: vw(-118px);

        @media (max-width: $tablet-sm) {
            margin-left: -10px;
            margin-right: -10px;

            img {
                object-fit: cover;
                min-height: 340px;
            }
        }
    }
}