//Padding mixin
@mixin padding($top, $right, $bottom, $left) {
    padding-top: $top;
    padding-right: $right;
    padding-bottom: $bottom;
    padding-left: $left;
}

// Side Padding mixin
@mixin sidePadding($side_padding) {
    padding-right: $side_padding;
    padding-left: $side_padding;
}

//Margin mixin
@mixin margin($top, $right, $bottom, $left) {
    margin-top: $top;
    margin-right: $right;
    margin-bottom: $bottom;
    margin-left: $left;
}

// Prefix mixin
@mixin prefix($prop, $value) {
    -webkit-#{$prop}: #{$value};
    -moz-#{$prop}: #{$value};
    -ms-#{$prop}: #{$value};
    -o-#{$prop}: #{$value};
    #{$prop}: #{$value};
}

// Font face mixin
@mixin font-face($name, $file) {
    @font-face {
        font-family: '#{$name}';
        src: url('../fonts/#{$file}.eot');
        src: url('../fonts/#{$file}.eot?#iefix') format('embedded-opentype'), url('../fonts/#{$file}.woff') format('woff'),
            url('../fonts/#{$file}.ttf') format('truetype'), url('../fonts/#{$file}.svg?#webfont') format('svg');
    }
}

// Show n lines of text (...)
@mixin shortText($lines) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: #{$lines}; /* number of lines to show */
    line-clamp: #{$lines};
    -webkit-box-orient: vertical;
}

/* 
* One Line font set
* $font-family, $font-size, $font-weight, $font-style, $line-height, $letter-spacing
* usage:
* @include font($primary_font, 24, 500, normal, 30, 2.56); px-based($font-size, $line-height, $letter-spacing)
* To ignore order use declaration of any attribute
* @include font($font-family:val, $font-size:val, $font-weight:val, $font-style:val, $line-height:val, $letter-spacing:val);
*/
@mixin font($font-family: null,
    $font-size: null,
    $font-weight: null,
    $font-style: null,
    $line-height: null,
    $letter-spacing: null) {

    /* font-family */
    @if type-of($font-family) !=null and type-of($font-family)==string {
        font-family: $font-family;
    }

    @else {
        $font-family: $primary_font;
    }

    /* font-size */
    @if type-of($font-size) !=null and type-of($font-size) !=number {
        @if type-of($font-size)==string {
            font-size: $font-size;
        }
    }

    @else {
        font-size: $font-size + px;
        font-size: calc($font-size / 10) + rem;
    }

    /* font-weight */
    @if type-of($font-weight) !=null {
        font-weight: $font-weight;
    }

    /* font-style */
    @if type-of($font-style) !=null {
        font-style: $font-style;
    }

    /* line-height */
    @if type-of($line-height) !=null and type-of($line-height) !=number {
        @if type-of($line-height)==string {
            line-height: $line-height;
        }
    }

    @else {
        line-height: $line-height + px;
        line-height: calc($line-height / 10) + rem;
    }

    /* letter-spacing */
    @if type-of($letter-spacing) !=null and type-of($letter-spacing) !=number {
        @if type-of($letter-spacing)==string {
            letter-spacing: $letter-spacing;
        }
    }

    @else {
        letter-spacing: $letter-spacing + px;
        letter-spacing: calc($letter-spacing / 10) + rem;
    }
}

// Get vieport width from given pixels
@function vw($target) {
    $vw-context: (1920 * 0.01) * 1px;
    @return calc(($target / $vw-context) * 1vw);
}

@function vw-rs($screen, $target) {
    $vw-context: ($screen * 0.01) * 1px;
    @return calc(($target / $vw-context) * 1vw);
}